// =========
//  FLEXBOX
// =========


@include silent-class(flex) {
  display: flex;
}


// direction properties:
// ---------------------
$flex-properties: flex-direction;
$flex-keywords:   row, row-reverse, column, column-reverse;

@each $property in $flex-properties {
  @each $keyword in $flex-keywords {
    @include silent-class(#{$property}-#{$keyword}) {
      #{$property}: #{$keyword};
    }
  }
}


// alignment properties:
// ---------------------
$flex-properties: align-items, justify-content, align-self, align-content;
$flex-keywords:   flex-start, flex-end, center, baseline, stretch, auto, baseline, space-between, space-around;

@each $property in $flex-properties {
  @each $keyword in $flex-keywords {
    @include silent-class(#{$property}-#{$keyword}) {
      #{$property}: #{$keyword};
    }
  }
}


// grow and shrink:
// ----------------
$flex-properties: flex-grow, flex-shrink;
$flex-keywords:   0, 1, 2, 3, 4, 5;

@each $property in $flex-properties {
  @each $keyword in $flex-keywords {
    @include silent-class(#{$property}-#{$keyword}) {
      #{$property}: #{$keyword};
    }
  }
}


// wrapping properties:
// --------------------
$flex-properties: flex-wrap;
$flex-keywords:   wrap, nowrap, wrap-reverse;

@each $property in $flex-properties {
  @each $keyword in $flex-keywords {
    @include silent-class(flex-#{$keyword}) {
      #{$property}: #{$keyword};
    }
  }
}
