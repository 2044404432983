// =======
//  FORMS
// =======

/*
  [*] Markup structure should be as follows:
      form>ul>li>input

  [1] Paragraphs following labels inside of forms are most likely
      descriptions to the following input.

  [2] Remove margins and use padding instead, since we
      occasionally remove paragraph margins per-view, which would
      override these rules.

  [3] Styles for "embedding" a form into the background.

  [4] Generic column for forms. Can't really go semantic on
      something like form columns.

  [5] Time and date inputs should always be small and next to each
      other, proably inside of a small container or an
      form__column. We also need the date popup to be able to
      escape its parent.
*/

form {
  display: block;

  label,
  .form__header {
    font-weight: body("bold");
    margin-bottom: gr(-2);

    + p { /* [1] */
      color: site("text-color-light");

      margin: 0; /* [2] */
      padding-bottom: gr(-2);
    }
  }
}

.form {
  &__wrap {
    &--embed { /* [3] */
      input,
      textarea,
      select {
        background: #fff;
      }
    }
  }

  &__column { /* [4] */
    @include mq(small) {
      @include columns(2);
    }

    @include mq(small, max-width) {
      & + & {
        margin-top: site("default-margins");
      }
    }

    &--date { /* [5] */
      position: static;

      @include span(6 of 12 .4);
    }

    &--time {
      @include span(6 of 12 last .4);
    }
  }
}

.input-group {
  display: flex;
  align-items: stretch;
  width: 100%;
  @include mq(small) {
    max-width: 25em;
  }
  .form-group {
    width: 100%;
  }
  .form-control {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    border-radius: site("br") 0 0 site("br");
    border-right: none;
  }
  .button-tab {
    border-radius: 0 !important;
    border-right: none;
    &.active {
      background: $button-color--active;
    }
  }
  .input-group-addon {
    display: flex;
    white-space: nowrap;
    border-radius: 0 site("br") site("br") 0;
    margin: 0;
  }
}