// ======================
//  NOTIFICATION BANNERS
// ======================

/*
  [1] This offsets the notification wrap further.

  [2] This is a map of banner types. To add a new banner type,
      just add a new type name and color value to the list, then
      use it by applying class name "notification__banner--name"
      to the "notification__banner".

  [3] This allows banners to have different widths. In unsupported
      browsers, all banners are as wide as the widest visible
      banner.
*/


// local variables:
// ----------------

  $banner-padding: 1em;
  $banner-border:  1px solid rgba(#000, .2);
  $banner-offset:  36px; /* [1] */

  $banner-types: ( /* [2] */
    "success": color($dokiv-green),
    "warning": color($dokiv-red),
    "system":  site("color")
  );

  %banner-styles {
    color:         #fff;
    border:        1px solid transparent;
    border-bottom: $banner-border;
  }

// ----------------


.notification-banner {
  &__wrap {
    @include position(fixed, null, 0);
    margin: 0 auto;
    max-width: 40em;
    color: site("text-color-light");
    z-index: 1000;
    text-align: center;

    @extend %flex; /* [3] */

    @include mq(tiny, max-width) {
      bottom: 1em;
      flex-direction: column-reverse;
    }

    @include mq(tiny) {
      top: 1em;
      padding: 0 1em;
      margin-bottom: 1em;
      align-items: center;
      flex-direction: column;
    }
  }

  &__banner {
    overflow: hidden;

    @include mq(tiny) {
      border-radius: site("br");

      & + & {
        margin-top: site("default-margins");
      }
    }

    &.ng-enter {
      animation: flyUp--enter transition("long") transition("swift-in2");

      @include mq(tiny) {
        animation-name: fallDown;
      }
    }

    &.ng-leave {
      animation: growOut transition("long") transition("easing");
    }

    @each $type, $value in $banner-types {
      &--#{$type} {
        @extend %banner-styles;
        background: $value;
      }
    }
  }

  &__icon {
    padding: $banner-padding;
    @include position(absolute, 0, null, 0, 0);
  }

  &__message {
    padding: $banner-padding $banner-padding * 4 $banner-padding $banner-padding * 3;
  }

  &__close {
    padding: $banner-padding;
    border-left: $banner-border;
    background: transparent;
    @include position(absolute, 0, 0, 0, null);

    &:hover {
      background: rgba(#fff, .1);
    }

    &:active {
      background: rgba(#000, .1);
    }
  }
}
