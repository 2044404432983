$block: keypass;
.#{$block} {
  &__items {
    @extend %flex;
    @extend %flex-wrap;
    margin-right: -#{gr(0)};
  }
  &__item {
    width: 100%; /* [2] */
    padding: gr(0) gr(0) gr(0) 0;
    cursor: pointer;
    &:last-child {
      padding-bottom: 0;
    }
    float: left;
    @include mq(medium) {
        width: 25%;
    }
    @include mq(small, to, medium) {
        width: 33.3333%;
    }
    @include mq(tiny, to, small) {
        width: 50%;
    }

    li {
      margin-bottom: gr(0);
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.ng-enter { animation: fadeIn  transition("duration") transition("easing"); }
    &.ng-leave { animation: fadeOut transition("duration") transition("easing"); }
  }

  &__icon-copy {
    &.icon-only {
      padding: 0;
    }
    &.icon-url {
      padding: 0.5em;
    }
    border-radius: 0;
    width: 2em;
    @include position(absolute, 0, 0, 0, null);
    & + & {
      @include position(absolute, 0, 2em, 0, null);
      border-right: none;
    }
    &.active,
    &:hover {
      background: site("color");
      color: #FFF;
    }
  }
  /*&__icon-password {
    @include position(absolute, 0, 2em, 0, null);
    border-right: none;
  }*/

  &__input {
    &[disabled] {
      cursor: pointer;
    }
  }
  &__title {
    h3 {
      @extend %ellipsis;
      margin-right: 1em;
    }
  }

  &__remove {
    @include position(absolute, -1.6em, -1.9em, null, null);
    margin-left: gr(-2);
    &:hover {
      color: color($dokiv-red);
    }
    &:active {
      color: darken(color($dokiv-red), $button-color--saturation);
    }
  }

  &__icon {
    &[disabled] {
      opacity: 0.5;
    }
    &:hover:not([disabled]),
    &:active:not([disabled]) {
      color: darken(color($dokiv-green), $button-color--saturation);
    }
    .fa {
      margin: 0;
    }
    & + & {
      margin-left: gr(-1);
    }
  }


  &__icon--wrap {
    @include position(absolute, 0, 0, null, null);
  }


  &__wrap-password {
    button.button--positive {
      margin-top: gr(-2);
      padding: gr(-2);
      float: right;
    }
  }
  &__tooltip {
    .tooltip__wrap {
      max-width: inherit;
    }
  }
}