// ===============
//  NOTIFICATIONS
// ===============

/*
  [*] Styles for the notification icon and dropdown.

  [1] The avatar is placed absolutely, so we inset the content by
      the avatar width plus gr(-1) expressed in px.

  [2] We want to inherit all generic styles from the dropdown,
      except for the padding.

  [3] Check for hover on parent instead of the actual body so that
      it persists even on hover on the remove button.

  [4] Ellipsis adds .1em top and bottom padding to an element to
      avoid characters from being cut off om the y axis. We add
      the same amount to the position to the image to align it.
*/


// local variables:
// ----------------

  $block: notification;

  $counter-color:      color($dokiv-red);
  $item-color--hover:  color($grey, 100);
  $item-color--active: color($grey, 200);
  $item-border-color:  color($grey, 200);

  $item-padding:       gr(-1);

  $user-avatar-width:  35px;
  $user-avatar-push:   $user-avatar-width + floor(site("font-size") * gr(-1, null)); /* [1] */

// ----------------


.#{$block} {
  &__wrap {
    @include mq(tiny, max-width) {
      position: static;
    }
  }

  &__aside {
    background: #fff;
    z-index: 1;
    @include position(fixed, 0);

    @include mq(tiny) {
      width: 24em;
      max-height: calc(100vh - #{site("bar-height")});
      box-shadow: site("box-shadow");
      top: site("bar-height");
      left: auto;
    }

    &.#{$block}-enter {
      animation: flyLeft transition("duration") transition("swift-in") forwards;
    }

    &.#{$block}-exit {
      animation: flyRight transition("duration") transition("swift-in") forwards;
    }
  }

  &__header {
    color: #fff;
    padding: gr(-1);
    border-bottom: 1px solid site("border-color");
    background: site("color");
  }

  &__list {
    @extend %momentum-scroll;
    max-height: calc(100% - #{gr(2)});
    padding: 0; /* [2] */
  }

  &__counter {
    background: $counter-color;
    color: #fff;
    font-size: .8em;
    border-radius: site("br");
    padding: gr(-3) gr(-2);
    margin:  gr(-3) gr(-3) 0 0;

    display: block;
    @include position(absolute, -10px, -10px, null, null);

    .header__user-dropdown > .dropdown__wrap & {
      margin: gr(-1) gr(-1) 0 0;
    }

    .header__user-dropdown > .dropdown__toggle & {
      margin: 1.45em 1em 0 0;
    }
  }

  &__item {
    display: block;

    & + & {
      .#{$block}__link {
        border-top: 1px solid $item-border-color;
      }
    }
  }

  &__link {
    display: block;
    color: site("text-color");
    background: #fff;
    padding: $item-padding;

    .#{$block}__item:hover & { /* [3] */
      background: $item-color--hover;
    }

    .#{$block}__item:hover &:active {
      background: $item-color--active;
    }
  }

  &__body {
    padding-left: $user-avatar-push;
  }

  &__heading {
    @extend %ellipsis;
    font-size: 1.1em;
  }

  &__publication-type {
    color: site("text-color-light");
    font-size: .8em;
    top: -.1em;
    font-weight: header("thin");
  }

  &__remove {
    display: block;
    padding: $item-padding;
    @include position(absolute, 0, 0, null, null);

    &:hover {
      &::before {
        background: transparent;
      }
    }

    &::before {
      @extend %transition--paint;

      content: "";
      display: block;
      background: site("color");
      border-radius: 50%;
      width:  1em;
      height: 1em;
      border: 2px solid site("color");
    }
  }

  &__text {
    margin: gr(-4) 0 0;
    line-height: 1.3;
    color: site("text-color-light");
  }

  &__user {
    width: $user-avatar-width;
    border-radius: site("br");
    @include position(absolute, .1em, null, null, 0); /* [4] */
  }

  &__company {
    @extend %ellipsis;
    color: site("text-color-light");
    font-size: .9em;
    font-weight: body("thin");
    margin-bottom: gr(-2, 1rem);
  }

  &__footer {
    border-top: 1px solid $item-border-color;

    button {
      display: block;
      padding: 1em $item-padding;
      width: 100%;

      &:not(:hover) {
        color: site("text-color-light");
      }
    }
  }

  &__empty {
    padding: $item-padding;
    font-style: italic;
    color: site("text-color-light");
  }

  &__close {
    padding: .75em;
    @include position(absolute, 0, 0, null, null);
  }

  &__message {
    @extend %ellipsis;
    font-style: italic;
    padding: gr(-1);
    background: color($grey, 100);
    border-radius: site("br");
  }

  &__loading {
    text-align: center;
    color: site("color");
  }

  &__time {
    font-size: 0.9em;
  }
}
