// ========
//  TABLES
// ========

/*
  [*] Yay tables!

  [1] These are styles for breaking down a table into a list. It
      displays the content in data-header="" as pseudo headers.
      We must nest the selectors to make use of the direct child
      selector, or break styles will inherit down to nested tables
      which isn't correct.

  [2] Spans in tables are typically styled text blocks such as
      ellipsis. These need inline-block to be aligned vertically
      when the table breaks.

  [3] Wrap tables in an element with class name .table__wrap to
      get theming, margins etc.

  [4] Wrap tables in .table--theme to get theming and row
      highlighting.

  [5] Moving some of the "broken table" properties here to reduce
      output and specificity.

  [6] Every second row in .table--theme should be white, since the
      .table__wrap background is grey.

  [7] "Embedded" input fields in tables. Looks like regular text
      at first, then – on focus and dirty respectively – apply
      different styles. Perhaps this should be moved to global
      input styling?
*/


// local variables:
// ----------------

$block: table;
$broken-table-gutter:  gr(-1);
$broken-table-header:  6.5em;
$broken-table-padding: gr(-4);

@mixin table-break { /* [1] */
  display: block;
  font-size: .9em;

  > tbody {
    display: block;

    > tr {
      display: block;
      //padding: $broken-table-gutter 1em;
      > td {
        display: block;

        line-height: 1.2;
        padding:     $broken-table-gutter 1em !important;
        text-align:  left !important;

        &[data-header]::before {
          content: attr(data-header) ":";
          display: inline-block;
          margin-bottom: gr(-3);
        }

        &.actions__wrap[data-header]::before {
          display: none;
        }
      }
    }
  }
  td, th {
    border: none;
  }

  .actions {
    &__wrap {
      z-index: 1;
      @include position(absolute, -$broken-table-padding, 0, null, null);

      &::before {
        display: none;
      }
    }

    &__button {
      float: none;
      font-size: 1.1em;
      padding: .75em;
    }
  }

  > thead {
    display: none;
  }

  span { /* [2] */
    display: inline-block;
  }

  .table-popover {
    &__wrap {
      top: 0;
      right: 0;
    }
  }
}


// ----------------


table {
  text-align: left;
}

td, th {
  padding: gr(-1, 1em, 2) .5em;

  &:first-child { padding-left:  0; }
  &:last-child  { padding-right: 0; }
}

.#{$block} {
  &__wrap { /* [3] */
    .user-avatar {
      width: 30px;
    }
  }

  &--theme { /* [4] */
    @extend %skin;
    border: none;
    table {
      border: 1px solid site("border-color");
      width: 100%;
      border-radius: site("br");
    }

    td {
      word-break: break-all;
    }

    th {
      white-space: nowrap;
    }

    td, th {
      padding: .7em;
      border: 1px solid site("border-color");

      &.expand {
        width: 100%;
      }

      &.slim {
        padding: 0 gr(-2);
      }

      &::before { /* [5] */
        display: none;

        margin-right: $broken-table-gutter;
        min-width:    $broken-table-header;

        color: site("text-color-light");
      }
    }

    thead {
      font-weight: body("bold");
      th {
        background: darken(site("bg-color"), 4%);
        background-clip: padding-box;

      }
    }

    tbody {
      tr {
        &:nth-child(odd) { /* [6] */
          td {
            background: #fff;
            background-clip: padding-box;
          }
        }
      }
    }

    .#{$block}__input { /* [7] */
      &--embed {
        &:not(:focus) {
          background:   none;
          border-color: transparent;
          cursor:       pointer;
        }

        &.ng-dirty {
          border-color: site("border-color-dark");
          border-style: dashed;
        }
      }
    }

    @include mq(tiny, max-width) {
      font-size: .9em;
    }
  }

  &--break-tiny {
    @include mq(tiny, max-width) {
      @include table-break();
    }
  }

  &--break-small {
    @include mq(small, max-width) {
      @include table-break();
    }
  }

  &--break-medium {
    @include mq(medium, max-width) {
      @include table-break();
    }
  }

  &--break-large {
    @include mq(large, max-width) {
      @include table-break();
    }
  }

  &__ellipsis {
    @extend %ellipsis;

    @include mq(tiny, max-width) {
      max-width: 10em !important;
    }

    &:hover {
      color: color($grey, 600);
    }
  }

  &__divider {
    td, th {
      padding-top: gr(1) !important;
    }
  }
}

.footable {
  &.breakpoint {
    > tbody > tr {
      &.footable-row-detail {
        background: none;
      }
    }
    .footable-detail-show {
      .footable-first-column {
        .footable-toggle:before {
          content: "\f056";
          color: color($dokiv-orange);
        }
      }
    }

    .footable-first-column {
      padding-left: 3em;
      .footable-toggle {
        cursor: pointer;
        padding: gr(-2);
        @include position(absolute, 50%, null, 0, 0.3em);
        margin-top: -18px;
        &:before {
          font-size: 2em;
          font-family: "Font Awesome 5 Free";
          content: "\f055";
          color: color($dokiv-green);
        }
      }
    }
  }
  .footable-row-detail {
    .footable-row-detail-row {
      padding: gr(-2) 0;
    }
    .footable-row-detail-name {
      font-weight: bold;
      line-height: site("line-height");
    }
  }
  .footable-color {
    @include mq(medium, max-width) {
      padding: 0.3em;
    }
  }
}


