// =======
//  INDEX
// =======

/*
  [*] These are rules for a generic index/sitemap-type of list.
*/

.sitemap {
  &__header {
    padding-bottom: gr(-2);
    font-size: 1em;
    font-weight: header("bold");
  }

  &__wrap {
    @extend %skin;
    padding: 1em;
    margin-top: gr(1);

    width: 300px;
    max-width: 100%;
  }

  &__list {
    margin: 0;

    a {
      &:hover {
        color: site("color");
      }
    }
  }
}
