// ==========
//  ACTIVITY
// ==========

/*
  [1] Ideally this should be 50% of the actual image width.

  [2] Using inset box-shadow instead of regular border because of
      the thick, left border which would otherwise be skewed.

  [3] The meta is positioned absolute so that the body can have a
      flexible width without floating issues, so we need to give
      the comment wrapper a minimum height of 2 rows plus vertical
      paddings.
*/


// local variables:
// ----------------

  $activity-padding: 1em;
  $activity-meta-width: 20em;
  $avatar-width: 30px; /* [1] */

// ----------------



.activity {
  &__wrap {
    padding: gr(1);
    @include border(1px solid site("border-color"), right bottom left);

    @include mq(tiny, max-width) {
      @include stretch(site("default-margins"));

      &, & > .skin {
        border-radius: 0;
        @include border(0, left right);
      }
    }
  }

  &__toggle {
    float: left;

    &:first-child { border-radius: site("br") 0 0 site("br"); }
    &:last-child  { border-radius: 0 site("br") site("br") 0; }
    & + &         { border-left:   0; margin-left: 0; }

    &.active {
      background: color($grey, 200);
    }
  }
}


$block: comment;

.#{$block} {
  &__wrap {
    box-shadow: inset 0 0 0 1px site("border-color"); /* [2] */
    border-radius: 0 site("br") site("br") 0;
    border-left: $activity-padding / 2 solid site("color");
    min-height: ($activity-padding * 2) + 2em; /* [3] */

    overflow: hidden;
    padding: $activity-padding;

    @include mq(small) {
      padding-left: $activity-meta-width
    }

    & + & {
      margin-top: $activity-padding;
    }

    &.history { border-left-color: color($green,  400); }
    &.upload  { border-left-color: color($orange, 400); }
  }

  &__meta {
    white-space: nowrap;
    overflow: hidden;

    @include mq(small, max-width) {
      margin-bottom: site("default-margins");
    }

    @include mq(small) {
      width: $activity-meta-width;
      padding: $activity-padding;
      @include position(absolute, 0, null, 0, 0);
    }

    .user {
      font: body("bold") 1em/1 body("family"), body("fallback");
      margin: 0;
    }

    time {
      font-size: .85em;
      color: site("text-color-light");
    }
  }

  &__avatar {
    @extend %align-self-center;
    float: left;
    margin-right: $activity-padding;
    width: $avatar-width;
  }

  &__add {
    padding: $activity-padding 0;

    form {
      @extend %flex;
    }

    input {
      @extend %flex-grow-1;
      @include border-radius(0, top-right, bottom-right);
    }

    button {
      border-left: 0;
      @include border-radius(0, top-left, bottom-left);
    }
  }
}
