// ========================
//  EMPTY PUBLICATION LIST
// ========================


// local variables:
// ----------------

  $logo-width:        180px;
  $logo-width--small: 150px;

  $counter-name: steps;

// ----------------


.publication-empty {
  &__wrap {
    @include mq(medium, max-width) {
      margin-top: gr(1);
    }

    p {
      line-height: 1;
    }
  }

  &__background {
    @include mq(medium, max-width) {
      display: none;
    }
  }

  &__logo {
    max-width: $logo-width--small;

    @include mq(medium) {
      max-width: $logo-width;
    }
  }

  &__box {
    text-align: center;

    @include mq(medium) {
      background: #fff;
      box-shadow: site("box-shadow");
      border-radius: site("br");
      padding: gr(2);

      margin: gr(1) gr(1) 0;
      @include position(absolute, 0, 0, null);
    }

    @include mq(large) {
      margin: gr(3) gr(3) 0;
    }

    a {
      display: inline-block;
      padding: .2em 0;

      &:focus,
      &:hover {
        color: site("text-color");
      }
    }
  }

  &__header {
    font-size: gr(1);
    margin: 1em 0 0;
  }

  &__info {
    margin: gr(3) 0;

    h5 {
      line-height: 1;
    }

    p {
      margin: gr(-2) 0 0;
      color: site("text-color-light");
    }
  }
}
