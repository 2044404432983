// ===============
//  MEDIA EXTENDS
// ===============

// [1] @include this mixin anywhere to create a silent class inside
//     every media query breakpoint found in the breakpoint map.

// [2] Create a silent class inside of every media query. We
//     currently only allow extends in one direction because stuff
//     and reasons.

// [3] Also allow regular @extends for the created silent class.

// [4] Include this inside of our mq() mixin instead of using
//     regular extends.

$directions: min-width, max-width;

@mixin silent-class($name) { // [1]
  @at-root {
    @each $direction in $directions {
      @each $breakpoint, $value in $breakpoints { // [2]
        @if $direction == max-width {
          $value: $value - 1px;
        }

        @media ($direction: $value) {
          %#{$name}-#{$direction}-#{$breakpoint} { @content; }
        }
      }
    }

    %#{$name},
    %#{$name}-min-width-#{$default-size},
    %#{$name}-max-width-#{$default-size} { @content; }
  }
}

@mixin extend($name, $direction: $current-direction) { // [4]
  @extend %#{$name}-#{$direction}-#{$current-size};
}
