// ======================
//  FILE UPLOAD PROGRESS
// ======================

/*
  [1] Even without the holder styling, this element is needed to
      contain the overflowing pseudo "gleam" element.

  [2] Allow the gleam to escape from the bar, so that the current
      progress doesn't affect the animation/position of it.

  [3] This creates the moving gleam. If you change the width of
      the gleam, make sure to recalculate the transform distance
      in keyframes.scss.

  [4] Must use #fff or else overflow is visual.
*/


// local variables:
// ----------------

$block: progress-bar;

$bar-color:        site("color");
$bar-color-light:  lighten($bar-color, 7.5%);
$bar-color--dark:   darken($bar-color, 1%);
$bar-color--glow:  lighten($bar-color, 30%);

$error-color--dark:    darken(color($dokiv-red), 5%);
$complete-color--dark: darken(color($dokiv-green), 5%);

$bar-width:        25em;
$bar-height:       1.3em;
$bar-gleam-width:  50%;

// ----------------


.#{$block} {
  &__wrap {
    width: $bar-width;
    z-index: z("high");
    @include position(fixed, null, null, site("default-margins"), site("default-margins"));
  }

  &__file {
    @extend %skin;
    background: #fff;
    margin-top: gr(-1);
    padding:    1em 0 0;
    box-shadow: site("box-shadow");

    &.ng-enter { animation: fadeIn transition("duration"); }
    &.ng-leave { animation: flyLeft--leave 1s transition("swift-in") 1.5s; }
    &.ng-hide  { animation: fadeOut transition("duration"); }
  }

  &__info {
    margin: 0;
    font-size: .9em;
    padding: 0 1em;
  }

  &__holder {
    overflow: hidden; /* [1] */
    margin-top: gr(-1);
    background: color($grey, 200);
  }

  &__bar {
    @extend %transition;
    position: static; /* [2] */

    height: $bar-height;
    background: linear-gradient(to right, $bar-color, $bar-color-light);
    box-shadow: inset -#{gr(-4)} 0 gr(-1) $bar-color--glow,
                1px 0 gr(-1) #fff;

    &::before { /* [3] */
      content: "";
      display: block;
      width: $bar-gleam-width;

      background: linear-gradient(
                    to right,
                    rgba(#fff, 0)   0%,
                    rgba(#fff, .25) 45%,
                    rgba(#fff, .25) 55%,
                    rgba(#fff, 0)   100%
                  ); /* [4] */

      animation: uploadGlow 4s infinite;
      @include position(absolute, 0, null, 0, -$bar-gleam-width);
    }

    .#{$block}__file.error & {
      background: color($dokiv-red);
      box-shadow: inset 0 0 gr(-1) rgba(#000, .25);
      border-color: $error-color--dark;

      &::before {
        animation: none;
      }
    }

    .#{$block}__file.complete & {
      background: color($dokiv-green);
      box-shadow: inset 0 0 gr(-1) rgba(#000, .25);
      border-color: $complete-color--dark;

      &::before {
        animation: none;
      }
    }
  }
  &__percent {
    @include position(absolute, 0, 0, null, null);
    height: $bar-height;
    width: 100%;
    text-align: center;
    span {
      line-height: $bar-height;
    }
  }

  &__button {
    padding: gr(-2);

    &:hover {
      color: color($dokiv-red);
    }
  }

  &__close {
    @include position(absolute, 0, 0, null, null);
  }

  &__abort {
    @include position(absolute, 0, 0, null, null);
  }

  &__hide {
    @include position(absolute, 0, 14px, null, null);

    &:hover {
      color: color($dokiv-orange);
    }
  }

  &__file-name {
    display: inline-block;
    max-width: 16em;
    top: -.1em;
    @extend %ellipsis;
  }
}
