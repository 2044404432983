// ========
//  ALBUMS
// ========

/*
  [*] This is an image gallery. Please see the base styles in
      image-gallery.scss.

  [1] This image gallery displays information over the image on
      hover, but it also has a toggle to display information
      below in when activated.

  [2] This is the hover effect mentioned in [1]. Not using a
      transition because the toggle makes it wonky.
*/


// local variables:
// ----------------

$block:            album;
$text-shadow:      0 0 6px rgba(#000, .85);
$album-padding:    1em;
$thumbnail-height: 15em;

// ----------------


.#{$block} {
  &__wrap {
    @extend %skin;
    background: #fff;
    padding: $album-padding;
  }

  &__info {
    .#{$block}__wrap:not(.show-details) & { /* [1] */
      display: none;
      border: 1px solid site("border-color");
      border-radius: site("br");
      background: rgba(#fff, gr(-1, null));
      @include position(absolute, 0, null, null, 0);

      .button {
        background: none;
        border:     0;
        padding:    0;

        span {
          display: none;
        }

        .fa {
          margin: 0;
          font-size: gr(1);
        }
      }
    }

    .button {
      margin: gr(-2) 0 0;
    }
  }

  &__holder { /* [2] */
    &:hover {
      .#{$block}__info {
        // display: block;
      }
    }
  }

  &__thumbnail {
    height: $thumbnail-height;

    img {
      border-radius: site("br");
    }
  }

  &__view {
    margin-top: 7px;
    @include mq(small) {
      margin-right: 3em;
    }
    button {
      @extend %transition;
      vertical-align: middle;
      font-size: 2em;
      top: -5px;
      &.active,
      &:hover,
      &:active {
        color: color($dokiv-orange);
      }
      & + button {
        margin-left: gr(-1);
      }
    }
  }
}
