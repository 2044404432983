// ==========
//  DROPDOWN
// ==========

/*
  [*] This document contains styles for two types of dropdowns –
      one which is created through some module, and another that's
      ours. If possible I'd like to remove the former at some point
      in the future.

  [1] !important because we currently use some module that writes
      styles inline...

  [2] Does the module force cursor: default on the anchor links to
      make it more similar to a <select>? I think we should have a
      pointer cursor for them.

  [3] Padding to prevent pointer from interacting with elements
      beneath the dropdown.

  [4] This creates the triangle. We should probably figure out a
      way to dynamically position the triangle.

  [5] Wrap the dropdown in __full to make it fill the entire width
      of its parent.

  [6] Can't have transition on these links with the this styling,
      since the current ellipsis implementation uses a gradient.

  [7] This is a fairly ugly multi-row ellipsis implementation.
      It's always visible, even if there's no overflowing text,
      and it forces us to remove transitions etc.
*/


// local variables:
// ----------------

  $block:           dropdown;
  $list-padding:    1em;
  $triangle-length: 6px;

  $description-line-height: 1.2;
  $description-row-count:   2;
  $dropdown-link-color:     site("color");
  $dropdown-width:          16em;
  $dropdown-width--long:    24em;
  $dropdown-max-height:     70vh;

  %dropdown-anchor {
    display: block;
    padding: gr(-1, 1em, 2) 1em;
    cursor: pointer !important; /* [2] */
    line-height: site("line-height");

    &:focus,
    &:hover {
      color: site("text-color");
    }
  }

  %dropdown-anchor--focus {
    background: site("color");
    color: #fff;
  }

// ----------------


.dropdown-menu {
  position: absolute;
  top:      100% !important; /* [1] */
  right:    0    !important;
  left:     0    !important;
  transform: none !important;
  z-index:  z("low");
  background: #fff;
  color: site("text-color-light");
  border-radius: 0 0 site("br") site("br");
  box-shadow: site("box-shadow");
  display: none;

  a {
    @extend %dropdown-anchor;
  }

  li.active > a {
    @extend %dropdown-anchor--focus;
  }
  &.show {
    display: block;
  }
}

.autocomplete {
  li {
    @extend %dropdown-anchor;

    &.selected {
      @extend %dropdown-anchor--focus;
    }
  }
}


.#{$block} {
  &__wrap {
    @extend %swift-in;

    padding: .75em; /* [3] */
    z-index: z("low");
    color: site("text-color");
    @include position(absolute, 100%, 0, null);

    @include mq(tiny) {
      left: auto;
      width: $dropdown-width;
    }

    &.ng-hide {
      opacity: 0;
      transform: translateY(-#{gr(-1)});
    }

    &.ng-enter { animation: fallDown transition("duration") transition("swift-in"); }
    &.ng-leave { animation: fadeOut  transition("duration") transition("easing"); }

    &--info {
      @include mq(tiny) {
        padding-right: 0;
        width: $dropdown-width--long;
        left: -2em;
      }

      .#{$block}__header::before {
        @include mq(small) {
          left: 1.7em;
          right: initial;
        }

        @include mq(medium) {
          left: gr(1);
          right: initial;
        }
      }

    }
  }

  &__user-wrap {
    @extend %swift-in;
    @extend %momentum-scroll;
    @include position(absolute, 100%, 0, null);
  }

  &__background {
    background: #fff;
    box-shadow: 0 2px 6px rgba(#000, .25);
    border-radius: site("br");
  }

  &__toggle {
    &.active {
      transition: none;
    }

    .fa {
      @include mq(tiny, max-width) {
        margin: 0;
      }
    }
  }

  &__list {
    & + & {
      border-top: 1px solid site("border-color");
    }

    li:last-child a, button {
      border-radius: 0 0 site("br") site("br");
    }

    a, button {
      @extend %user-select-none;
      display: block;
      width: 100%;
      text-align: left;
      padding: .9em gr(-1);

      @include mq(tiny) {
        padding: gr(-1);
      }

      &:focus,
      &:hover {
        background: color($grey, 200);
      }

      &:active {
        background: color($grey, 300);
      }

      &.logout {
        &:focus,
        &:hover {
          color: #fff;
          background: color($red, 400);
        }

        &:active {
          color: #fff;
          background: color($red, 500);
        }
      }
    }

    .fa {
      margin-right: gr(-1);
      width: 1.3em;
      text-align: center;
    }
  }

  &__header {
    color: #fff;
    margin: 0 0 gr(-2);
    padding: gr(-1);
    font-weight: header("bold");
    background: $dropdown-link-color;
    border-radius: site("br") site("br") 0 0;

    &::before { /* [4] */
      content: "";
      display: block;
      @include position(absolute, null, 1em, 100%, null);

      border-bottom:  $triangle-length solid $dropdown-link-color;
      @include border($triangle-length solid transparent, left right);

      @include mq(medium) {
        right: gr(1);
      }
    }
  }

  &__sub-header {
    padding: gr(-3) gr(-1);
    font-weight: header("bold");

    & ~ & {
      @include separator(gr(-1));
    }
  }

  &__text {
    @extend %momentum-scroll;
    max-height: $dropdown-max-height;
    padding: $list-padding;
  }

  &__full { /* [5] */
    width: auto;
    right: 0;
    left:  0;

    .#{$block}__list {
      @extend %momentum-scroll;
      max-height: 25em;

      li + li a {
        border-top: 1px solid site("border-color");
      }
    }

    a, button {
      transition: none; /* [6] */
      font-size: .9em;
      line-height: $description-line-height;

      &:hover {
        background: $dropdown-link-color;
        color: #fff;
      }
    }

    h6 {
      @extend %ellipsis;
      font: header("bold") 1.2em/1 header("family"), header("fallback");
    }

    p {
      @extend %ellipsis;
      margin: 0;
    }
  }

  &__description {
    margin: gr(-2) 0;
    max-height: #{$description-row-count * $description-line-height}em;
    overflow: hidden;

    &::after { /* [7] */
      display: block;
      pointer-events: none;
      content: "...";

      background: linear-gradient(to left, rgba(#fff, 1) 60%, rgba(#fff, 0));
      padding: .1em .2em 0 .75em;

      @include position(absolute, null, 0, 0, null);

      .#{$block}__full a:hover & {
        background: linear-gradient(to left, rgba($dropdown-link-color, 1) 60%, rgba($dropdown-link-color, 0));
      }
    }
  }
}
