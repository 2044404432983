// ========
//  FILTER
// ========


// local variables:
// ----------------

  $block: filter;
  $filter-width: 20em;

// ----------------


.#{$block} {
  &__wrap {
    @include mq(tiny, max-width) {
      .line-component {
        float: none;

        + .line-component {
          margin: 1em 0 0;
        }
      }
    }
  }

  &__input {
    max-width: $filter-width;
  }

  &__description {
    display: block;
    margin: gr(-2) 0 0;
    color: site("text-color-light");

    font-size: .95em;
    font-style: italic;

    max-width: $filter-width;
  }
}
