// =========
//  PROFILE
// =========

/*
  [*] These are common styles for profile type pages.
*/


// local variables:
// ----------------

  $block:           profile;
  $profile-padding: gr(1);
  $avatar-width:    250px;

// ----------------


.#{$block} {
  &__wrap {
    &:not(.editing) {
      .#{$block}__edit {
        margin: 0;
        @include position(absolute, 0, 0, null, null);
      }

      p {
        margin: 0;
      }
    }

    @include mq(tiny, max-width) {
      @include stretch(site("default-margins"));

      &, & > .skin {
        border-radius: 0;
        @include border(0, left right);
      }
    }

    label {
      &:not(:first-child) {
        margin-top: site("default-margins");
      }
    }

    .select__wrap {
      width: auto;
      display: inline-block;
    }
    .user-initial {
      min-height: 250px;
      display: table;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      > span {
        display: table-cell;
        font-size: gr(2);
        vertical-align: middle;
        text-transform: uppercase;
      }
    }
  }

  &__profile--column {
    margin-bottom: gr(0);
    height: 95px;
    @include mq(medium, max-width) {
      @include columns(2);
    }

    @include mq(tiny, max-width) {
      @include columns(1);
    }

    @include mq(medium) {
      @include columns(4, .15);
    }

    table tbody tr td:first-child {
      min-width: 4.5rem;
    }
    padding: 10px;
    
    table {
      tr td:nth-child(2) {
        width: 100%;
      }
    }

    table tr td div.row--small {
      max-width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }

  &__info-column {
    @include mq(tiny, max-width) {
      & + & { margin-top: gr(1); }
    }

    @include mq(tiny) {
      &     { @include span(8 of 12 .5 last); }
      & + & { @include span(4 of 12 .5); }
    }
  }

  &__column {
    @include mq(small) {
      &     { @include span(8 of 12); }
      & + & { @include span(4 of 12 last); }
    }

    @include mq(small, max-width) {
      & + & {
        margin-top: site("default-margins");
      }
    }
  }

  &__avatar {
    @extend %skin;
    max-width: $avatar-width;
    margin: 0 auto;
    overflow: hidden;

    .user-avatar__initials {
      font-size: gr(2);
    }
  }

  &__name {
    font-size: gr(1);
    font-weight: header("bold");
  }

  &__title {
    font-style: italic;
    font-size: 1.2em;
  }

  &__radio {
    .radio__wrap {
      margin: 0;

      + .radio__wrap {
        margin-top: gr(-2);
      }

      label {
        margin-top: 0;
      }
    }
  }

  &__group-column {
    @include mq(tiny, to, small) {
      @include columns(2);
    }
  }

  &__groups {
    p {
      margin: 0;
    }
  }

  &__language {
    img {
      width: 23px;
      float: left;
      margin-right: gr(-2);
    }
  }

  &__split {
    @include mq(tiny, max-width) {
      & + & {
        @include separator;
      }
    }

    @include mq(tiny) {
      @include columns(2);
    }
  }

  &__company-name {
    font-size: 2em;
    font-weight: 300;
  }
  &__more-portal {
    margin-top: gr(-2);
  }
  &__password {
    .button--system {
      @include position(absolute, gr(-2), 0, null, null);
    }
    .button--positive {
      margin-top: gr(-2);
      padding: gr(-2);
      float: right;
    }
  }

  &__table-theme {
    width: 100%;
    .radio__wrap {
      display: inline-block;
      .radio__label {
        border: 1px solid color($grey, 400);
        padding: 3px;
        border-radius: 12px;
        background: #fff;
        &:before {
          width: 12px;
          height: 12px;
          margin-right: 0;
          border: 0;
        }
      }
    }
    .actions__button {
      bottom: 4px;
      width: inherit;
      &.activate-button:hover {
        color: inherit;
        background: 0;
        border-color: inherit;
      }
    }
  }

  &__table-required {
    width: 20px;
  }

  &__input-add {
    &, &:last-child {
      padding-right: 5.5em;
    }
    .button {
      @include position(absolute, 0, 0, null, null);
    }
  }

  &__unit-group {
    .select__wrap {
      padding-right: 4em;
    }
    .button--positive {
      @include position(absolute, 0, 0, null, null);
      padding: 0.6em 1em;
    }
  }

  &__select-wrap {
    &.select__wrap {
      padding-right: 5.5em;
    }
  }

  &__vertical-top {
    vertical-align: top;
    padding-top: 1.4em;
  }

  &__reset-pass-content {
    max-width: 20em;
    margin: 0 auto;
    margin-top: 3em;
  }
  &__nav-brand {
    display: block;
    img {
      max-width: 200px;
      @include mq(tiny, max-width) {
        max-width: 120px;
      }
    }
  }
  &__reset-pass-form {
    p{
      padding: 15px;
    }
    input[type=password]{
      width: 100%;
      border: 1px solid #bdbdbd;
      border-radius: 3px;
      line-height: 1.2;
      outline: none;
      padding: 0 0.45em;
      display: block;
      transition: border-color .25s ease;
      text-align: center;
      &:focus{
        border-color: #bdbdbd;
      }
    }
    .message-text {
      padding: 5px;
      a {
        color: #000;
        text-decoration: none;
        margin-bottom: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .button--positive {
      margin-bottom: 1em;
    }
    .btn {
      width: 100%;
      display: block;
      border-radius: 3px;
      cursor: pointer;
    }
    .btn-green {
      color: #fff;
      background: #009956;
      height: 40px;
      &:hover {
        background: #0DA663;
      }
    }
  }
}
