// ================
//  FONT "AWESOME"
// ================

/*
  [1] We mostly always want icons inside of buttons and anchor
      links to have a right margin to the value of the clickable.

  [2] I've seen instances where icons obscure javascript
      mouseenter events, such as our tooltip directive, so we
      remove the pointer events from them all just in case.

  [3] This improves the vertical alignment across the board.

  [4] When we don't want margins, give the parent the class name
      "icon-only".

  [5] And sometimes we want the icon to be aligned to the right.
*/


// local variables:
// ----------------

$icon-margin: .5em;

// ----------------


.svg-inline--fa, .fa {
  a &,
  button &,
  .button & {
    margin-right: $icon-margin; /* [1] */
    pointer-events: none;       /* [2] */
  }

  .icon-only & { /* [4] */
    margin: 0;
  }

  .icon-right & { /* [5] */
    margin-right: 0;
    margin-left: $icon-margin;
    float: right;
  }
}
