// ============
//  HELP BLOCK
// ============

.help-block {
  @extend %transition;

  margin: site("default-margins") 0 0;
  padding: gr(-1) 1em;

  color: #fff;
  background:    site("color");
  box-shadow:    site("box-shadow");
  border-radius: site("br");

  z-index: 1;
  @include position(absolute, 100%, 0, null, null);

  &.ng-hide {
    opacity:   0;
    transform: translateY(-1em);
  }

  &::before {
    content: "";
    display: block;

    border-bottom:  6px solid site("color");
    @include border(6px solid transparent, left right);

    @include position(absolute, null, 1em, 100%, null);
  }
}
