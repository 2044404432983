.notification {
  color: #FFF !important;
  font-size: inherit !important;
  padding-left: 2em !important;
  border-top: none !important;
  padding-right: 4em !important;
  &-title {
    color: #FFF !important;
  }
  &:before {
    @include position(absolute, 0, null, 0, 1em);
    font-family: "Font Awesome 5 Free";
    content: "\f129";
    display: flex;
    align-items: center;
  }
  &-success {
    background: color($dokiv-green) !important;
  }
  &-error {
    background: color($dokiv-red) !important;
  }
  &-dismiss {
    background: none !important;
    display: flex;
    align-items: center;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    height: auto !important;
    padding: 0 0.4em !important;
    border-left: 1px solid #7f7d7d;
    justify-content: center;
    font-size: 2em !important;
    font-weight: normal !important;
    border-radius: 0 !important;
    width: auto !important;
  }
}