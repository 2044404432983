// =========
//  EXTENDS
// =========

%clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

%cursor-default {
  cursor: default;
}

%vertical-margins {
  margin: site("default-margins") 0;

  &:first-child { margin-top:    0; }
  &:last-child  { margin-bottom: 0; }
}

%transition {
  transition: all transition("duration") transition("easing");
}

%transition--paint {
  transition: color      transition("duration"),
              background transition("duration"),
              border     transition("duration"),
              opacity    transition("duration"),
              box-shadow transition("duration");
}

%swift-in {
  transition: transform transition("duration") transition("swift-in"),
              opacity   transition("duration") transition("swift-in");
}

%basic-pseudo {
  content: "";
  display: block;
  pointer-events: none;
}

%user-select-none {
  user-select: none;
}

%momentum-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  html.no-touch & {
    overflow-y: auto;
  }
}

%ellipsis {
  display:        block;
  overflow:       hidden;
  white-space:    nowrap;
  text-overflow:  ellipsis;
  vertical-align: middle;

  padding-top:    .1em;
  padding-bottom: .1em;
}

%separator {
  @include separator(gr(1), top);
}

%separator--small {
  @include separator(1em, top);
}

%stretch {
  @include stretch(site("default-margins"));

  &, & > .skin {
    border-radius: 0;
    @include border(0, left right);
  }
}

// positioning
%cover-all       {  @include position(absolute, 0); }
%cover-all-fixed {  @include position(fixed,    0); }
