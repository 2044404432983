// ====================
//  PUBLICATION SEARCH
// ====================

/*
  [1] !important to override %vertical-margins' first-child margin
      reset.

  [2] Cover the header bar border below to make the button appear
      like it merges with the field it opens.

  [3] Need to specify input type because the way we apply global
      rules to inputs is fairly specific...

  [4] To save annoying tweaks to accomodate for different input
      sizes, let's always use 16px for this field.
*/


// local variables:
// ----------------

  $block:        publication-search;
  $search-width: 20em;
  $breakpoint:   small;
  $padding-x:    1em;
  $padding-y:    gr(-2);
  $focus-color:  lighten(site("color"), 40%);

// ----------------


.#{$block} {
  &__wrap {
    @extend %skin;
    margin: 0;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    padding: 0.0618em 0;

    @include mq($breakpoint, max-width) {
      height: site("bar-height");
      border-radius: 0;
      background: none;
      position: static;
      @include border(0, top bottom left);
    }

    @include mq($breakpoint) {
      margin: .5em !important; /* [1] */
      background: #fff;
      width: $search-width;
    }

    .fa {
      font-size: 1.2em;
      vertical-align: top;

      @include mq($breakpoint) {
        opacity: .5;
      }
    }
  }

  &--focus {
    overflow: visible;

    @include mq($breakpoint) {
      box-shadow: 0 0 gr(1) $focus-color;
      border-color: $focus-color;
      width: $search-width + 10em;
    }

    @include mq($breakpoint, max-width) {
      .#{$block}__popout {
        box-shadow: 0 0 gr(1) $focus-color;
        border-color: $focus-color;
      }
    }
  }

  &__label {
    @extend %transition;
    padding: $padding-y $padding-y $padding-y $padding-x;

    @include mq($breakpoint) {
      @include position(absolute, 2px, null, null, 0);
    }

    @include mq($breakpoint, max-width) {
      cursor: pointer;
      padding: 1.2em 1em;
      height: site("bar-height");
      z-index: 1;

      &:hover {
        color: site("text-color");
      }

      &:active,
      .#{$block}--focus & {
        background: #fff;
        box-shadow: 0 1px 0 #fff; /* [2] */
        color: site("text-color");
      }
    }

  }

  &__field {
    &[type="search"] { /* [3] */
      font-weight: body("thin");
      border: 0;
      border-radius: 0;
      font-size: 16px; /* [4] */

      @include mq($breakpoint) {
        padding: 0 $padding-y;
      }

      @include mq($breakpoint, max-width) {
        padding: gr(-1) gr(1, 1rem) gr(-1) gr(-1);
      }

      &:focus {
        color: site("text-color");
      }
    }
  }

  &__popout {
    @include mq($breakpoint, max-width) {
      background: #fff;
      @include position(absolute, 100%, 100%, null, null);

      .#{$block}--focus & {
        left:  0;
        right: 0;
      }
    }
  }

  &__holder {
    @include mq($breakpoint) {
      padding-left: gr(2);
    }
  }

  &__options {
    border-top: 1px dotted site("border-color");
    padding: gr(-1);

    h6 {
      font-weight: header("bold");
    }

    .radio__wrap {
      margin-top: gr(-1);
    }
  }

  &__option {
    float: left;
    margin: 0 gr(1) gr(-1) 0 !important;

    label {
      font-weight: body("regular");
    }

    input:checked + label {
      color: site("text-color");

      &::before {
        background: site("color");
      }
    }
  }

  &__current {
    font-size: 1rem;
    line-height: site("line-height");
    font-weight: body("thin");
  }

  &__reset {
    cursor: pointer;
    padding: .5em gr(-1);
    @include position(absolute, 0, 0, 0, null);

    &:hover {
      color: site("text-color-dark");
    }
  }

  &__more-result {
    button {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.9em;
      font-weight: 700;
    }
  }
}

.header__nav {
  &, ul, li {
    @include mq($breakpoint, max-width) {
      position: static;
    }
  }
}
