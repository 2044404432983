// ===============
//  MEDIA QUERIES
// ===============

// [1] List of breakpoints for media queries.

// [2] Get value of passed parameter from the map at [1]. If it
//     doesn't exist, use the passed value.

// [3] We create a "scope" per media query by changing a variable
//     before and after the query. This variable can then be used
//     to – for instance – extend inside of media queries.

// [4] Media query mixin. Accepts any integer with a unit, or a
//     keyword from the map at [1]. Optionally change the direction
//     or add a range.

// [6] Extend an element to a value from the map at [1] to hide it
//     at anything larger than that breakpoint.


// [1]
$breakpoints: (
  "tiny":   floor(site("width") * 0.45),
  "small":  floor(site("width") * 0.65),
  "medium": floor(site("width") * 0.80),
  "large":  floor(site("width") * 1.00)
);


// [2]
@function breakpoint($size, $direction) {
  $value: if(map-has-key($breakpoints, $size), map-get($breakpoints, $size), $size);

  @if $direction == max-width {
    $value: $value - 1px;
  }

  @return $value;
}


// [3]
$default-size: root;
$current-size: $default-size;

// [4]
@mixin mq($size, $direction: min-width, $size2: null, $direction2: max-width) {
  $current-size:      $size      !global;
  $current-direction: $direction !global;

  $break: breakpoint($size, $direction);

  @if $direction == to {
    $direction:  min-width;
    $direction2: max-width;
  }

  @if $size2 == null {
    @media ($direction: $break) {
      @content;
    }
  }

  @else {
    $break2: breakpoint($size2, $direction2);

    @media ($direction: $break) and ($direction2: $break2) {
      @content;
    }
  }

  $current-size: $default-size !global;
}


// [6]
@each $size, $breakpoint in $breakpoints {
  %hide-above-#{$size} {
    @include mq($size) {
      display: none !important;
    }
  }

  %hide-below-#{$size} {
    @include mq($size, max-width) {
      display: none !important;
    }
  }
}
