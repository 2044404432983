// =========
//  GLOBALS
// =========

/*
  [1] In my opinion, position: relative is a better default value
      than position: static, enabling top/right/bottom/left, and
      z-index values to work by default. Remember to set
      position: static to whatever element you want absolute
      elements to escape from.

  [2] Inherit box-sizing instead of setting the as the global
      default. This makes it easier to use 3rd party modules
      written with content-box. :-)

  [3] We don't want the cursor to jump to "text" whenever it
      hovers a text element. It feels wonky. This makes all
      elements inherit "cursor: default" from html, then we
      reapply the correct cursor for buttons, inputs and anchors
      later.

  [4] Reapply cursor: pointer to all anchor links. Should still be
      here even without [3] since not all anchor links seem to
      have href values. This makes sure those links get the
      cursor.

  [5] "Active" effects should be instant, else it makes the system
      feel slower/clunkier.

  [6] You can't toggle back and forth in a rapid succession
      without this since the browser treats it like a regular –
      selectable – element.
*/


html {
  height: 100%;
  cursor: default;
  box-sizing: border-box;

  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100%;
  min-width:  304px;
  font:       body("regular") #{site("font-size")}/1 body("family"), body("fallback");
  color:      site("text-color");
  overflow-y: scroll;

  @include mq(tiny) {
    padding-top: site("bar-height");
  }
}

*,
::before,
::after {
  position:   relative; /* [1] */
  box-sizing: inherit;  /* [2] */
  cursor:     inherit;  /* [3] */
}

::selection {
  background:  lighten(site("color"), 60%);
  color:       site("text-color");
  text-shadow: none;
}

a {
  @extend %transition;
  cursor: pointer; /* [4] */
  color:  inherit;
  text-decoration: none;

  &:active {
    transition: none; /* [5] */
  }

  &[href^="mailto:"] {
    border-bottom: 1px solid color($grey, 300);

    &:hover {
      border-bottom-color: color($grey, 500);
    }
  }
}

img {
  display:        inline-block;
  vertical-align: middle;
  max-width:      100%;
}

@if body("bold") != 700 {
  strong, b {
    font-weight: body("bold");
  }
}

ul {
  @extend %reset-list;
}

ol {
  @extend %basic-list;
  list-style-type: decimal;
  line-height: site("line-height");
}

label {
  display: block;

  &:active {
    @extend %user-select-none; /* [6] */
  }
}
