// ==========
//  NAV TABS
// ==========

/*
  [1] Place the tabs above the content field's top border.

  [2] Users typically "drag" while clicking after fast mouse
      movement, such as moving the mouse to a common navigation
      point. Add user-select: none to prevent selection when
      this happens.

  [3] The active tab isn't clickable, so the cursor shouldn't
      indicate that it is.
*/


// local variables:
// ----------------

  $block:        tab;
  $button-color: color($grey, 400);

// ----------------


.#{$block} {
  &__wrap {
    top: 1px; /* [1] */
    z-index: 1;

    .button {
      top: -1px;
    }
  }

  &__button {
    @extend %user-select-none; /* [2] */
    float: left;
    color: #fff;
    text-align: center;
    background: $button-color;
    padding: gr(-1);
    @include border(1px solid transparent, top right left);

    border-top-left-radius:  site("br");
    border-top-right-radius: site("br");

    @include mq(medium, max-width) {
      min-width: gr(2);

      > span {
        display: none;
      }

      > .fa {
        margin: 0;
      }
    }

    & + & {
      margin-left: site("br");
    }

    &.button-customer {
      margin-top: 0.5em;
      margin-left: 0;
      margin-right: site("br")
    }

    &.active {
      color:        site("text-color");
      background:   site("bg-color");
      border-color: site("border-color");
      cursor: default; /* [3] */
    }

    &:not(.active) {
      &:active {
        background: lighten($button-color, 5%);
      }
    }

    &:first-child {
      margin-left: 7em;
    }
  }

  &__button-goback {
    @include position(absolute, 10px, null, null, null);
  }

  &__none-background {
    background: #fff;
  }
}
