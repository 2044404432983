// ===============
//  COMPANY PAGES
// ===============

/*
  [1] Magic number. This is roughly the width of the absolute
      date. Cannot use media layout for small screen break
      purposes.
*/


// local variables:
// ----------------

  $block: company-page;

  $company-page-padding: gr(1);

// ----------------


.#{$block} {
  padding: $company-page-padding;

  &__column {
    @include mq(medium) {
      @include span(8 of 12 .5 last);

      & + & {
        border-right: 1px solid site("border-color");
        padding-right: $company-page-padding;
        @include span(4 of 12 .5);
      }
    }
  }

  &__column-header {
    @include mq(small, max-width) {
      & + & {
        margin-top: 1em;
      }
    }
    @include mq(small) {
      @include span(3 of 12 .5);
      & + & {
        @include span(9 of 12 .5 last);
      }
    }
    & + & {
      form {
        max-width: 22em;
      }
    }
  }

  &__header {
    @include mq(tiny, min-width) {
       @include columns(2);
    }

    @include mq(tiny, max-width) {
       margin-top: 1em;
    }
  }

  &__heading {
    font-size: gr(1);
  }

  &__connections {
    @include mq(tiny) {
      li {
        &::before {
          content: "•";
          font-size: 2em;

          float: left;
          margin-right: gr(-1);
          padding-top: gr(-3);
        }
      }
      &.connection-process-design {
        border: 1px solid color($grey, 300);
        background: #fff;
        padding: 1.5em;
        li {
          &:before {
            content: "";
            margin: 0;
            padding: 0;
          }
          &.row--small {
            border-top: 1px solid color($grey, 300);
          }
        } 
      }
    }
  }

  &__connection {
    display: block;
    overflow: hidden;
    padding: 1em;
    background: #fff;
    box-shadow: 0 1px 2px rgba(#000, .3);
    border-left: 5px solid site("color");
    border-radius: 0 site("br") site("br") 0;

    @include mq(tiny) {
      padding-right: 9em; /* [1] */
    }

    &:active {
      transform: translateY(1px);
      box-shadow: inset 0 0 2px rgba(#000, .3);
      color: rgba(site("text-color"), .5);
    }

    &__remove {
      width:  2em;
      height: 2em;
      padding: gr(-2);
      margin-top: gr(-1);
      border-radius: 50%;

      &:hover {
        background: color($dokiv-red);
        color: #fff;
      }

      &:active {
        background: darken(color($dokiv-red), 10%);
        color: rgba(#fff, .5);
      }
    }

  }

  &__aside {
    @include mq(medium, max-width) {
      transition: transform transition("duration") transition("swift-in"),
                  opacity   transition("duration") transition("swift-in");

      width: 100%;
      margin-right: gr(1);

      .show-body & {
        opacity: 0;
        transform: translateX(-100%);
        @include position(absolute, 0, 0, null, -($company-page-padding + 1em));
      }

      &.aside-process-design {
        display: none;
      }
    }
  }

  &__body {
    @include mq(medium, max-width) {
      transition: transform transition("duration") transition("swift-in"),
                  opacity   transition("duration") transition("swift-in");

      min-height: 30em;
      width: 100%;
      opacity: 0;
      margin-left: $company-page-padding + 1em;
      @include position(absolute, 0, null, 0, 100%);

      .show-body & {
        opacity: 1;
        position: relative;
        margin-left: 0;
        z-index: 1;
        transform: translateX(-100%);
      }
    }
  }

  &__date {
    display: block;
    font-size: .8em;

    @include mq(tiny, max-width) {
      margin-top: gr(-2);
    }

    @include mq(tiny) {
      @include position(absolute, gr(-1), gr(-1), null, null);
    }
  }

  &__breadcrumb {
    font-style: italic;
    li {
      display: inline-block;
    }
  }

  &__body-process-design{
    padding: 0 7em 0 3em;
    overflow: hidden;
    .page-tree {
      bottom: -2px;
    }
  }

  &__item {
    border-bottom: 2px solid #67a1dd;
    &:after, &:before {
      content: '';
      width: 65px;
      height: 69px;
      border: 2px solid #67a1dd;
    }
    &:after {
      border-left: 0;
      @include position(absolute, null, -65px, -2px, null);
    }
    &:before {
      border-right: 0;
      z-index: 3;
      @include position(absolute, null, null, -69px, -22px);
    }
    &:first-child a:before, &:last-child:before, &:last-child:after, &:last-child {
      border: 0;
    }
    @include mq(tiny,  to, small ) { 
      @include columns(2, .4);   
      &:after {
        border:0;
        border-bottom: 2px solid #67a1dd;
        @include position(absolute, null, -15px, -2px, null);
      }
      &:nth-child(2n):after {
        border: 2px solid #67a1dd;
        border-left: 0;
        @include position(absolute, null, -65px, -2px, null);
      }
      &:nth-child(2n+1):before {
        border: 2px solid #67a1dd;
        border-right: 0;
      }
      &:before, &:last-child:before, &:last-child:after {
        border: 0;
      }
    }

    @include mq(small, to, medium) { 
      @include columns(3, .25);  
      &:after {
        border:0;
        border-bottom: 2px solid #67a1dd;
        @include position(absolute, null, -15px, -2px, null);
      }
      &:nth-child(3n):after {
        border: 2px solid #67a1dd;
        border-left: 0;
        @include position(absolute, null, -65px, -2px, null);
      }
      &:nth-child(3n+1):before {
        border: 2px solid #67a1dd;
        border-right: 0;
      }
      &:before, &:last-child:before, &:last-child:after {
        border: 0;
      }
    }

    @include mq(medium) { 
      @include columns(4);  
      &:after {
        border:0;
        border-bottom: 2px solid #67a1dd;
        @include position(absolute, null, -15px, -2px, null);
      }
      &:nth-child(4n):after {
        border: 2px solid #67a1dd;
        border-left: 0;
        @include position(absolute, null, -65px, -2px, null);
      }
      &:nth-child(4n+1):before {
        border: 2px solid #67a1dd;
        border-right: 0;
      }
      &:before, &:last-child:before, &:last-child:after {
        border: 0;
      }
    }
    a {
      display: block;
      padding: 0 0 0 4em;
      color: #fff;
      margin: 2em 0;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border: 38px solid transparent;
        border-left: 40px solid color($grey, 100);
        z-index: 1;
        @include position(absolute, 0, null, null, 0);
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: -78px;
        top: 0;
        border: 38px solid transparent;
        border-left: 40px solid #67a1dd;
        z-index: 2;
        @include position(absolute, 0, -78px, null, null);
      }
      &:hover {
        box-shadow: rgba(0,0,0,.1) 0 0 0 inset, #98a5b3 5px 10px 12px -7px;
      }
      h5 {
        font-weight: 500; 
        height: 60px;
        padding-top: 15px;
      }
    }
  }

  &__tree {
    height: 16px;
    padding-top: 8px;
  }

  &__button {
    z-index: 2;
    @include position(absolute, 0, 0, null, null);
    &.button-detail {
      @include mq(small, max-width) { 
        display: none;
      }
    }
    &.edit-dashboard {
      right: 2em;
      top: 0.3em;
      a {
        color: #fff;
        margin-right: 1em;
        padding: 0.3em 0;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
      .dnd-handle {
        font-size: 1.3em;
        cursor: move;
      }
    }
  }

  &__process-design-detail {
    margin-top: 2.5em;
    .detail-phone {
      @include mq(medium) { 
        display: none;
      }
    }
  }

  &__color-line {
    width: 12px;
    height: 100%;
    @include position(absolute, 0, null, null, 0);
  }

  // &__drag-drop-icon {
  //   background: url('../../../../images/icons/icon-drag-drop.png');
  //   background-size: 100% 100%;
  //   @include position(absolute, 10px, 0, null, null);
  //   width: 25px;
  //   height: 25px;
  //   cursor: ns-resize;
  //   z-index: 2;
  // }

  &__checkbox-label {
    margin-top: 8px;
    margin-bottom: 8px;
    @include mq(medium) {
      margin-right: 70px;
    }
    @include mq(tiny,  to, small) {
      margin-right: 70px;
    }
    @include mq(small, to, medium) {
      margin-right: 10px;
    }
    @include mq(tiny, max-width) {
      margin-right: 70px;
    }
  }
}