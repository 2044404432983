// ============
//  VALIDATION
// ============

/*
  [*] These are styles for the form validation
*/


// local variables:
// ----------------

  $block: validation;
  $signature--width: 450px;

// ----------------

.#{$block} {
  &__message {
    display: inline-block;
    color: #fff;
    background: color($dokiv-red);
    border-radius: site("br");
    padding: gr(-1);
    margin-top: gr(-1);
  }

  &__signature {
    background: #fff;
    border-radius: site("br");
    border: 6px dashed site("border-color");
    cursor: crosshair;
    overflow: hidden;
    max-width: $signature--width;
  }

  &__signature-name {
    display: block;
    max-width: $signature--width;
  }

  &__signature-block {
    & + & {
      margin-top: gr(0);
    }
  }

  &__missing-required-fields {
    @include mq(tiny) {
      margin-left: 1rem;
    }

    @include mq(tiny,max-width) {
      clear: both;
      width: 100%;
    }
  }
}
.styles_modal__gNwvD {
  width: 100%;
  .validation__signature {
    max-width: 100% !important;
  }
}
