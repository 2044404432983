// ===========
//  FORM CONTROL
// ==============

/*
  [*] These are styles for the floating form builder on the form
      publication type.

  [1] This value comes from the generic button style document.

  [2] Place the form builder above the aside/header on small
      devices.

  [3] Add the default text input padding to the width of the
      "add new" button.

  [4] Hide the text inside the input and prevent the user from
      editing. This is for the DIVIDER question type which is
      not supposed to have a title or description.
*/

// local variables:
// ----------------

$block: form-controls;

$button-color: site("color");
$button-color--saturation: $button-color--saturation; /* [1] */

$input-add--width: 3em;
$body-bg-color: #fff;
$preview-list-padding: gr(-3);
$highlight-color: color($grey, 100);

$popover-width: 500px;

// ----------------

.#{$block} {
  &__column {
    @include mq(small) {
      @include columns(2);
    }
  }

  &__active-column {
    @include mq(medium) {
      & {
        @include span(4 of 12 0.25);
      }
      & + & {
        @include span(8 of 12 0.25 last);
      }
    }
  }

  &__bar {
    background: color($grey, 200);
    padding: gr(1);
    border-bottom: 1px solid site("border-color");

    @include mq(tiny) {
      @include border(1px solid site("border-color"), left right);
    }

    @include mq(tiny, max-width) {
      @include stretch(1em);
    }
  }

  &__report-aside {
    @include mq(tiny) {
      min-height: 100vh;
    }
  }

  &__verify-bankId {
    border: 1px solid site("border-color");
    border-bottom: none;
    margin-top: 1em;
    padding: 1.5em 1em 1em;
    margin-left: -1em;
    margin-right: -1em;
    position: relative;
    .bank-id-logo {
      @include position(absolute, 5px, 1em, 0, null);
      img {
        width: 45px;
      }
    }
  }

  &__wrap {
    &:not(.form__edit-question) {
      padding: gr(1);
      @extend %momentum-scroll;
      z-index: z("low");
      @include position(fixed, 0, 0, 0, null);

      @include mq(tiny) {
        max-height: calc(100vh - #{site("bar-height")});
        max-width: $popover-width;
        box-shadow: site("box-shadow");
        top: site("bar-height");
        &.#{$block}__save-as-object {
          max-width: $popover-width * 2;
        }
      }
    }

    &.form__edit-question {
      @include mq(medium, max-width) {
        z-index: z("low");
        @include position(fixed, 0, 0, 0, null);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        html.no-touch & {
          overflow-y: auto;
        }
      }

      @include mq(tiny, to, medium) {
        max-height: calc(100vh - #{site("bar-height")});
        max-width: $popover-width;
        box-shadow: site("box-shadow");
        top: site("bar-height");
      }

      > div {
        padding: 1em;
      }
      @include mq(tiny) {
        > div {
          border: 1px solid site("border-color");
          & + div {
            border-top: none;
          }
        }
      }
      @include mq(tiny, max-width) {
        padding: 0;
      }
    }

    background: $body-bg-color;
    @include mq(tiny, max-width) {
      z-index: z("mid") + 1 !important; /* [2] */
      left: 0;
    }

    &.sidebar-enter {
      animation: flyLeft transition("duration") transition("swift-in") forwards;
    }

    &.sidebar-exit,
    &.sidebar-leave {
      animation: flyRight transition("duration") transition("swift-in") forwards;
    }
  }

  &__item {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5; /* [5] */
    }
  }

  &__header {
    font-size: gr(1);
  }

  &__question {
    min-height: 0;
    resize: none;

    &[disabled] {
      cursor: not-allowed;
      color: #fff; /* [5] */
    }

    &--title {
      font-size: 1.2em;

      @include mq(tiny) {
        font-size: gr(1);
      }
    }
  }

  &__label {
    font-weight: header("bold");
    margin-bottom: gr(-2);
  }

  &__input {
    background: #fff !important;
  }

  &__type-list {
    @include extend("flex");
    @include extend("flex-wrap");
  }

  &__type {
    @extend %transition--paint;
    padding: gr(-1);
    text-align: center;
    background: #fff;
    border: 1px solid site("border-color");
    cursor: pointer;
    margin: 0 -1px -1px 0;

    @include mq(tiny, max-width) {
      width: 50%;
      padding: gr(-1) 1em;
    }

    @include mq(tiny) {
      float: left;

      &:first-child {
        border-radius: site("br") 0 0 site("br");
      }

      &:last-child {
        border-radius: 0 site("br") site("br") 0;
      }
    }

    &.active,
    &:hover {
      background: $button-color;
      border-color: $button-color;
      color: #fff;

      + & {
        border-left-color: $button-color;
      }
    }

    &:active {
      transition: none;
      user-select: none;
      background: darken($button-color, $button-color--saturation);
    }
  }

  &__create {
    input {
      padding-right: $input-add--width + gr(-2); /* [3] */
    }

    .button {
      padding: 0;
      width: $input-add--width;
      border-radius: 0 site("br") site("br") 0;
      @include position(absolute, 0, 0, 0, null);
    }
  }

  &__direction,
  &__list-source {
    .button {
      margin: 0 -1px 0 0;
      border-radius: 0;

      &:first-child {
        border-radius: site("br") 0 0 site("br");
      }

      &:last-child {
        border-radius: 0 site("br") site("br") 0;
      }

      &.active,
      &:hover {
        + .button {
          border-left-color: site("border-color-dark");
        }
      }

      &.active,
      &:active {
        transition: none;
        background: color($grey, 300);
      }
    }
  }

  &__preview {
    @include shadow-covers($body-bg-color);

    li {
      @extend %transition--paint;
      padding: $preview-list-padding;
      margin: 0;

      &.ng-leave {
        transition: none;
      }

      &:hover {
        background: color($grey, 300);
      }
    }
    &.#{$block}__select-recipient {
      margin-left: -gr(1);
      margin-right: -gr(1);
      li {
        padding: gr(-1) gr(1);
        .row {
          margin-bottom: 0;
          margin-top: gr(-1);
        }

        .#{$block}__remove {
        }
      }
    }
  }

  &__remove {
    @include position(absolute, 0, $preview-list-padding, 0, null);

    .checkbox__wrap:not(:hover) &,
    .radio__wrap:not(:hover) & {
      opacity: 0;
    }
  }

  &__close {
    padding: 1em;
    @include position(absolute, 0, 0, null, null);

    &:hover {
      color: color($dokiv-red);
    }

    .fa {
      font-size: 1.2em;
    }
  }

  &__highlight {
    &.stretch {
      @include stretch(site("default-margins"));
      border-left: none;
      border-right: none;
    }
    padding: gr(1);
    background: $highlight-color;
    @include separator(gr(1), bottom);
    border: 1px solid site("border-color");

    input,
    textarea,
    .select__button {
      background: #fff;
    }

    &.create-task {
      border-top: none;
      margin-bottom: 0;
      select {
        width: 100%;
        background: #fff;
      }
    }

    & ~ .#{$block}__highlight {
      //margin-top: -gr(1);
      border-top: none;
    }

    &::before {
      .csstransforms & {
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        background: $highlight-color;
        transform: rotate(45deg);
        @include border(1px solid site("border-color"), top left);
        @include position(absolute, -0.55em, null, null, gr(1));
      }
    }
  }
  &__option-item {
    & + & {
      border-top: 1px solid #ddd;
    }

    &.has-sub.radio__wrap {
      min-height: 40px;
      .radio__label {
        margin-top: 10px;
      }
    }

    button.add-sub-question {
      @include position(absolute, 7px, 2em, null, null);
      bottom: initial;
      width: auto;
      padding: 5px;
    }
  }

  &__row-stretch {
    margin-top: 1em;
    margin-left: -gr(0);
    margin-right: -gr(0);
    border-left: none;
    border-right: none;
  }

  &__grid {
    .columns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        &:first-child {
          display: flex;
          align-items: center;
        }
        width: 49%;
        padding-bottom: 15px;
      }
    }
  }

  &__row-preview {
    .row-name {
      padding: 5px 0;
    }
    .row-description {
      font-style: italic;
    }
  }

  &__customer-placeholder {
    @include position(absolute, 0, 0, 0, 0);
    display: flex;
    align-items: center;
    padding-left: gr(-1);
    button {
      @include position(absolute, 0, gr(-1), 0, null);
    }
  }

  &__object-info {
    @include mq(small) {
      border-right: 1px solid site("border-color");
      margin: 0;
      &:before {
        content: none !important;
      }
    }
  }

  &__create-order {
    background: #fff;
    margin: gr(1) -#{gr(1)} -#{gr(1)} -#{gr(1)};
    padding: gr(1);
    h3 {
      margin-bottom: 1rem;
    }
    select {
      background: #fff !important;
      min-width: 12rem;
    }
  }

  &__step {
    padding: gr(0);
    border: 1px solid site("border-color");
  }

  &__activate-wrapper {
    @include mq(medium) {
      p {
        margin-top: 4rem;
      }
    }
  }

  &__activate-button {
    background: #d8d8d8;
    border: 1px solid #c7c7c7;
    padding: 1rem;
    width: 100%;
    font-size: 1.2rem;
    margin-top: 1em;
    @include mq(small) {
      margin-top: 4em;
    }
  }

  &__content {
    border: 1px solid site("border-color");
    padding: gr(0);
  }

  &__cover {
    background: rgba(255, 255, 255, 0.5);
    @include position(absolute, 0, 0, 0, 0);
    z-index: 1;
  }

  &__step-control {
    margin-bottom: 5px;
    z-index: z("low");
    @include mq(medium) {
      text-align: right;
    }
    @include mq(medium, max-width) {
      padding-top: 5px;
      text-align: center;
    }
    text-align: right;
    button.button {
      padding: 6px 14px;
    }

    .close {
      @include position(absolute, 11px, 1em, null, null);
    }
  }
}

/* Step Navigation */

.stepNav {
  margin: 30px 0 20px;
  height: 43px;
  padding-right: 0;
  position: relative;
  z-index: 0;
  display: flex;
}

.stepNav li {
  float: left;
  position: relative;
  z-index: 6;
  flex-grow: 1;
}

.stepNav li:first-child {
  border-radius: 5px 0 0 5px;
}

.stepNav li:nth-child(2) {
  z-index: 5;
}
.stepNav li:nth-child(3) {
  z-index: 4;
}
.stepNav li:nth-child(4) {
  z-index: 3;
}
.stepNav li:nth-child(5) {
  z-index: 2;
}

.stepNav a,
.stepNav a:visited {
  width: 100%;
  height: 43px;
  padding: 0 0 0 25px;
  text-align: center;
  line-height: 43px;
  white-space: nowrap;
  border: 1px solid site("border-color");
  text-decoration: none;
  border-right: 0;
  background-color: #fff;
  float: left;
  position: relative;
  box-sizing: border-box;
}

.stepNav li:first-child a {
  padding-left: 12px;
  border-radius: site("br") 0 0 site("br");
}

.stepNav li:first-child a,
.stepNav li:last-child a {
  border-left: 1px solid site("border-color") !important;
}

.stepNav li:last-child a {
  border-right: 1px solid site("border-color") !important;
  background: color($dokiv-green);
  color: #fff;
}

.stepNav li:not(:first-child) a,
.stepNav li:not(:last-child) a {
  border-left: none;
  border-right: none;
}

.stepNav li:not(:last-child) a:before {
  content: "";
  width: 29px;
  height: 29px;
  border-right: 1px solid site("border-color");
  border-bottom: 1px solid site("border-color");
  display: block;
  position: absolute;
  top: 6px;
  right: -16px;
  z-index: -1;

  transform: rotate(-45deg);
  background-color: #fff;
}

.stepNav a:hover {
  text-decoration: underline;
  background-color: #fff;
}

.stepNav a:hover:before {
}

/* selected */

.stepNav li.selected {
}

.stepNav li.selected a,
.stepNav li.selected a:before {
  border-color: #f5e87f;
  background-color: #f5e87f;
}

.stepNav li.selected a {
  text-decoration: underline;
}
