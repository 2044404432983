// ========
//  SHARES
// ========

.share {
  &__approval {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 0 auto;

    &--pending  { background: color($dokiv-orange); }
    &--approved { background: color($dokiv-green); }
  }

  &__email {
    #newShareEmail {
      padding-right: 2em;
    }
  }

  &__suggestion-toggle {
    padding: 0 gr(-1);
    z-index: 2;
    color: site("text-color-light");
    @include position(absolute, 0, 0, 0, null);

    &:hover {
      color: site("text-color");
    }
  }

  &__suggestions {
    box-shadow: site("box-shadow");
    z-index: 1;
    max-width: 100%;
    border-radius: site("br");
    @include position(absolute, 0, 0, null);

    .scrollable__list {
      border-radius: 0 0 site("br") site("br");
      border-top: 1px solid site("border-color");
    }

    input {
      border-color: transparent !important;
      border-radius: site("br") site("br") 0 0;
    }
  }

  &__suggestion {
    @extend %transition;
    cursor: pointer;
    padding: gr(-1);

    &:hover {
      color: #fff;
      background: site("color");
    }

    &:last-child {
      border-radius: 0 0 site("br") site("br");
    }
  }
  &__table {
    z-index: z("low");
    @include mq(small, max-width) {
      margin-bottom: 1em;
    }
  }

  &__no-enddate {
    @include position(absolute, 0, 0, 0, null);
    .fa {
      opacity: 0.7;
    }
    &:hover,
    &:active {
      .fa {
        opacity: 1;
      }
    }
  }

  &__type {
    @include position(absolute, null, 0, 0, null);
    width: 9em;
  }

  &__subject {
    padding-right: 10em;
  }
}
