$block: reminder;
.#{$block} {
  &__page-wrapper {
    @include mq(small) {
      display: flex;
      margin-left: -1.5em;
    }
  }

  &__page-cell {
    &.close {
      @include mq(small, max-width) {
        display: none !important;
      }
    }
    &.open {
      display: block;
      animation: fallDown transition("duration") transition("easing");
    }

    & {
      @include mq(small) {
        margin-left: 1.5em;
        flex: 1;
      }
    }
    & + & {
      @include mq(small, max-width) {
        margin-top: gr(1);
      }
      @include mq(medium) {
        flex: 0 0 90%;
      }
      @include mq(small) {
        flex: 0 0 80%;
      }
    }
  }

  &__title-col {
    padding: 1em 0;
    @include mq(tiny, min-width) {
      &:nth-child(1) {
        @include span(1.2 of 12);
      }
      &:nth-child(2) {
        @include span(4.8 of 12);
      }
      &:nth-child(3) {
        @include span(3 of 12 last);
      }
      &:nth-child(4) {
        @include span(3 of 12 last);
      }
    }
    > p {
      display: inline-block;
    }
  }

  &__col {
    text-align: center;
    > div > div > button,
    > span {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      width: 100%;
    }
    > span {
      line-height: 1;
      display: block;
    }
    > div > div > .reminder__watchers {
      height: auto;
      padding: 3px;
      .watchers__button {
        top: 0;
      }
    }
    @include mq(medium) {
      @include span(1.2 of 12);
      &:nth-child(5) {
        @include span(2 of 12);
      }
      &:nth-child(6) {
        @include span(1 of 12 last);
      }
      &:nth-child(7) {
        @include span(3 of 12 last);
      }
      margin-right: 0;
    }
    @include mq(tiny, to, medium) {
      @include span(2 of 12);
      &:nth-child(6),
      &:nth-child(7) {
        @include span(3 of 12);
        margin-top: 0.5em;
        margin-right: 0;
      }
      margin-right: 0;
    }
    .fa {
      margin-right: 0;
    }
    button:hover {
      color: color($orange, 400);
    }
    &.grey-col {
      background: color($grey, 300);
    }
    &.grey-light-col {
      background: color($grey, 200);
    }
    &.red-col {
      background: color($red, 300);
    }
    &.blue-col {
      background: color($blue, 300);
    }
    &.white-col {
      background: #fff;
    }
  }

  &__toggle-filter {
    &.icon-only {
      padding: 0.4rem;
    }
    &.active {
      background: color($grey, 500);
    }
  }

  &__watchers {
    height: 16px;
    .watchers__person {
      margin-bottom: 0;
      margin-right: 4px;
      height: 12px;
      display: inline-block;
      float: none;
    }
    .watchers__picture {
      width: 20px;
      height: 20px;
    }
    .watchers__button {
      top: -2px;
    }
  }

  &__add--member {
    .popover {
      margin-top: 5px;
      .select__wrap {
        min-width: 12em;
      }
    }
  }

  &__percentage {
    font-weight: 700;
    cursor: pointer;
    @include mq(small, max-width) {
      white-space: normal;
    }
    > div {
      @include mq(small, max-width) {
        display: inline-block;
        white-space: normal;
        & + div {
          margin-left: 1em;
        }
      }
      line-height: 1.2;
    }
  }

  &__group-button {
    > .button {
      margin-left: 1em;
    }
    .active {
      color: color($dokiv-orange, 500);
    }
    .button-massedit {
      margin-right: 1em;
    }
  }

  &__type-unit {
    width: 35%;
    @include position(absolute, null, null, gr(-1), gr(-1));
    .button-type {
      width: 100%;
      padding: 0.6em 0.4em;
      font-size: 0.9em;
    }
    .button-unit {
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
    }
  }

  &__type-row {
    margin-top: 0.4em;
  }

  &__table-row {
    margin-bottom: 1em;
    background: color($grey, 50);
    border: 1px solid site("border-color");
    border-left: none;
    > a {
      padding-left: 20px;
      display: block;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1em;
    .date {
      margin-left: 1em;
    }
  }

  &__item {
    @extend %card-styles;
    @include mq(tiny, to, small) {
      width: calc(33.3333% - 1em);
    }
    @include mq(small, to, medium) {
      width: calc(25% - 1em);
    }
    @include mq(medium) {
      width: calc(20% - 1em);
    }
    @include mq(tiny, max-width) {
      width: calc(100% - 1em);
    }
    border-radius: site("br");
    margin-left: 1em;
    .expired {
      background: color($dokiv-red, 500);
      color: color($dokiv-red, 500);
    }
  }

  &__link {
    display: block;
    color: #fff;
    padding: gr(-1);
    min-height: 13.5em;
    @include mq(tiny, max-width) {
      min-height: inherit;
    }

    &:active {
      opacity: 0.5;
    }
  }

  &__meta {
    font-size: 0.95em;
    color: color($grey, 900);

    > div > i,
    > div > span,
    > span {
      /* [7] */
      opacity: gr(-1, null);
    }
  }

  &__body {
    @include mq(small, max-width) {
      margin-bottom: gr(0.5);
    }
    .title {
      font-size: 1.1em;
      white-space: normal;
      margin: 0;
      display: -webkit-box;
      max-width: 100%;
      max-height: 35px;
      line-height: 1.2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__btns {
    @include mq(small) {
      @include position(absolute, null, null, 0, 0);
    }

    @include mq(small, max-width) {
      display: inline-block;
    }
    > span {
      @include mq(small, max-width) {
        display: inline-block;
        margin-right: gr(-1);
        padding: gr(-3);
      }
      @include mq(tiny, min-width) {
        margin-top: 5px;
      }
      padding: gr(-2);
      border: 1px solid site("border-color");
      border-radius: site("br");
      font-size: 0.8rem;
      display: block;
      margin: 0;
      @include mq(small) {
        width: 100%;
      }
      text-align: center;
      line-height: 1.2;
      &.grey {
        background: color($grey, 500);
        color: #fff;
      }
      &.white {
        background: site("bg-color");
        color: site("text-color");
      }
    }
  }

  &__members {
    @include position(absolute, null, 0, 0, 0);
    padding: 0 gr(-1) gr(-1) gr(-1);
    @include mq(tiny, max-width) {
      @include position(relative, null, null, null, null);
      padding: 0;
    }

    text-align: right;

    .watchers__person {
      margin-bottom: 0;
      margin-right: 0;
      & + .watchers__person {
        margin-left: gr(-2);
      }
    }

    .watchers__person {
      float: none;
      display: inline-block;
      padding: 10px 0;
    }
    .watchers__picture--blank {
      overflow: hidden;
      border-color: #fff;
    }
    @include mq(small) {
      display: flex;
      > div {
        width: 40%;
        & + div {
          width: 60%;
        }
      }
    }
  }
  &__icons {
    color: #fff;
    button {
      font-size: 1.25em;
      z-index: 1;
      & + button {
        margin-left: 0.5em;
      }
    }
  }

  &__header-column {
    @include mq(small) {
      & {
        @include span(4 of 12);
      }
      & + & {
        @include span(8 of 12 last);
      }
    }
    @include mq(small, max-width) {
      &.gdpr-setting {
        margin-top: 1em;
      }
    }
    @include mq(tiny, max-width) {
      h1 {
        font-size: 1.6em;
      }
    }
  }

  &__filter-wrapper {
    @include mq(small) {
      @include position(absolute, 0, 0, null, null);
    }
    .select__wrap {
      min-width: 10em;
      text-align: left;
      display: inline-block;
      @include mq(small, max-width) {
        margin-right: 1em;
      }
      .select__button {
        padding: 0.5em gr(0.5);
      }
    }
    .checkbox__wrap {
      margin-top: gr(-1);
      @include mq(tiny, max-width) {
        margin-top: gr(0);
      }
    }
    &.hide {
      @include mq(small, max-width) {
        display: none !important;
      }
    }
    &.open {
      display: block;
      &-add {
        animation: fallDown transition("duration") transition("easing");
      }
    }
  }
  &__filter {
    @include mq(small) {
      float: left;
      margin-left: gr(0);
    }
    @include mq(small, to, medium) {
      margin-bottom: 1em;
    }

    @include mq(small, max-width) {
      margin-top: gr(0);
    }
    &__text {
      padding-left: 3em !important;
      height: calc(2em + 6px) !important;
    }
    &__icon {
      @include position(absolute, null, null, null, null);
      z-index: 999;
      padding: 0.7em 1em;
      cursor: pointer;
      i {
        color: #d1d1d1;
      }
    }
    .customers-dashboard__select-manager {
      width: 10em;
    }
  }
  &__currentdate {
    @include mq(tiny) {
      margin-left: 1em;
    }
  }
  &__clear-text {
    cursor: pointer;
    padding: 0.5em gr(-1);
    @include position(absolute, 0, 0, 0, null);
    opacity: 0.7;

    &:hover,
    &:active {
      opacity: 1;
    }
    i {
      margin: 0;
    }
  }
  &__checkbox-label {
    &:before {
      float: right;
      margin-right: 0;
      margin-left: gr(-2);
    }
    &:after {
      left: initial;
      right: 1px;
    }
  }
  &__toggle-menu {
    padding: 0.7em 0.4em;
    border: 1px solid site("border-color");
  }
  &__mobile-btn {
    display: flex;
    flex-flow: row;
    @include position(absolute, 0, 0, null, null);
    > * {
      margin-left: 1rem;
      display: inline-block;
    }
  }
  &__icon-parent {
    width: 20px;
  }

  &__filter-users {
    max-width: 25em;
    float: right;
  }
  &__grid-filter {
    display: flex;
    margin-left: -1rem;
    & + & {
      margin-bottom: 2rem;
      padding-bottom: 2em;
    }
    &.box-shadow {
      box-shadow: 0px 10px 6px -8px color($grey, 400);
    }
    @include mq(small, max-width) {
      flex-direction: column;
    }
    > div {
      margin-left: 1rem;
      @include mq(small, max-width) {
        width: calc(100% - 1rem);
        margin-top: 1rem;
      }
      @include mq(medium) {
        width: calc(20% - 1rem);
      }
    }
  }
  &__created-at {
    @include position(absolute, null, null, 0, 0);
    margin: 0;
    font-size: 0.9em;
  }

  &__task-status {
    @include position(absolute, -1px, null, -1px, 0);
    width: 10px;
  }

  &-feed {
    &__wrap {
      padding: gr(1) gr(0);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__column {
      @include columns(6);
      @include mq(medium, max-width) {
        @include columns(3);
        margin-bottom: 2em;
      }
      @include mq(tiny, max-width) {
        @include columns(1);
        margin-bottom: 2em;
      }
      h2 {
        font-size: 1.5em;
        font-weight: 700;
        color: site("text-color-light");
        height: 2rem;
      }
    }
    &__filter-type {
      float:right;
      margin-top: 10px;
      margin-right: 1em;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3) 0,
        rgba(255, 255, 255, 0.2) 100%
      );
      &:hover {
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.4) 0,
          rgba(255, 255, 255, 0.3) 100%
        );
      }
      border-radius: site("br");
      color: #fff;
      min-width: 12em;
      .close {
        border-top: 1px solid site("border-color");
      }
    }
    &__create-new {
      min-width: auto;
      button {
        i.fa {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
    &__content {
      border-radius: site("br");
      background: site("bg-color");
      border: 1px solid site("border-color");
      padding: 0.5em;
      height: calc(100% - 2rem);
    }

    &__item {
      @extend %card-styles;
      &:last-child {
        margin-bottom: 0;
      }
      .card-list__link {
        min-height: auto;
        .card-list__body {
          h3 {
            font-size: 1.2em;
            font-weight: 700;
          }
        }
        .card-list__header {
          font-size: 0.9em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            opacity: 1;
          }
          span.importance {
            background: color($grey, 500);
            color: #fff;
            padding: 3px;
            border: 1px solid #fff;
          }
        }
      }
    }
    &__local-time {
      color: #fff;
      font-size: 24px;
      @include position(absolute, 10px, null, null, 40%);
    }
  }

  &__cate-wrap {
    margin-top: 3em;
    h3 {
      padding-bottom: 0.5em;
    }
  }

  &__table-cate {
    width: 100%;
    td {
      background: color($grey, 200);
      border: 2px solid #fff;
      padding: 0.5em;
      background-clip: padding-box;
      &:first-child {
        border-left: 5px solid color($grey, 400);
      }
    }
    tr {
      padding-top: 4px;
      &:first-child td {
        border-top: 0;
      }
      &:last-child td {
        border-bottom: 0;
      }
    }
  }

  &__table-plan {
    min-width: 1500px;
    margin: 0 auto;
    td, th {
      border: 1px solid color($grey, 600);
      padding: 0.5em 0;
      background-clip: padding-box;
      min-width: 160px;
    }
    tr {
      border-top: 1px solid #757575;
      &:nth-child(2n) {
        background: color($grey, 200);
        td {
          padding: 0.5em 1em;
        }
        td:first-child {
          background: color($grey, 200);
        }
      }
      &:nth-child(2n+1) td:first-child {
        background: #fff;
      }
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid color($grey, 600);
        padding: 0.5em 1em;
        .button.icon-only {
          position: absolute;
          top: 1.3em;
        }
      }
      th {
        background: color($grey, 400);
        & {
          position: sticky;
          top: -1px;
          z-index: 2;
        }
      }
    }
  }

  &__lable {
    border: 1px solid color($grey, 600);
    border-left: 5px solid;
    border-radius: site("br");
    padding: 0.5em 1em;
    margin: 3px 0;
    font-size: 0.8em;
    line-height: 1.6;
    &--pos {
      @include position(absolute, null, 0, 0, null);
    }
  }

  &__actions {
    .icon-left .fa {
      font-size: 1.4em;
    }
    .icon-right {
      a {
        display: inline-block;
      }
      .fa.on-list {
        font-size: 1.8em;
        color: color($grey, 600);
        margin-right: 0;
        &.fa-comments {
          font-size: 1.5em;
          top: -4px;
        }
      }
    }
  }

  &__tooltip {
    background: #000;
    border-radius: site("br");
    padding: 0.5em;
    color: #fff;
  }

  &__moveforward-button {
    @include position(absolute, 10px, 10px, null, null);
  }

  &__moveforward-box {
    @include position(absolute, 1.7em, 0, null, null);
    &.top {
      @include position(absolute, null, 0, 1.7em, null);
    }
    @include mq(tiny, min-width) {
      width: 24em;
      height: 28em;
    }
    background: #eeeeee;
    z-index: 1;
    padding: 1em;
    box-shadow: 0px 0px 3px 2px #cdcdcd;
    input {
      width: 12em;
    }
  }

  &__body-menu {
    @include position(fixed, 0, null, null, 0);
    background: #0000003b;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
}
