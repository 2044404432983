// =====================
//  PUBLICATION DETAILS
// =====================

/*
  [1] The "last" container contains content that should be above
      the "first" on small screens.

  [2] Make sure that the .25 custom margin is the same both here
      and in image-gallery.scss so that they line up. :-)

  [3] Limit the width of the header to the same width as the
      column below it.
*/


// local variables:
// ----------------

  $block: publication-details;

// ----------------


.#{$block} {
  &__show-more {
    padding: 1em 0em .61805em !important;
    .short-text {
      height: 250px !important;
    }
    .short-text:before {
      background: -webkit-linear-gradient(top,rgba(100,100,100,0) 0%,#f5f5f5 75%);
      background: linear-gradient(to bottom,rgba(100,100,100,0) 0%,#f5f5f5 75%);
    }
  }

  &__notice-list {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e0e0e0;
    li a i {
      font-size: 10px;
    }
  }

  &__wrap {
    @extend %clearfix;
  }

  &__main {
    @extend %transition;
  }

  &__column {
    @include mq(tiny, max-width) {
      & + & {
        @include separator(gr(1), top);
      }
    }

    @include mq(tiny, to, small) {
      &     { @include span(6 of 12 .25 last); } /* [1] */
      & + & { @include span(6 of 12 .25); }      /* [2] */
    }

    @include mq(small) {
      &     { @include span(8 of 12 .25 last); }
      & + & { @include span(4 of 12 .25); }
    }

    &--tiny {
      @include mq(small, max-width) {
        & + & {
          margin-top: site("default-margins");
        }
      }

      @include mq(small) {
        @include columns(2);
      }
    }
  }

  &__media {
    @include mq(small) {
      & {
          @include span(6 of 12);
        }
    }
    .versions__thumbnail {
      &-parent {
        max-height: 20em;
      }
      img {
        max-width: 100%;
      }
    }
    .versions__holder {
      padding-bottom: 0;
    }
  }


  &__header {
    @include mq(small) {
      @include span(6 of 12);
      float: none; /* [3] */
      &.header-edit {
        @include span(4 of 12);
        float: none;
      }
    }
    h2 {
      margin: 0;
    }

    .fa::before {
      margin-right: .33em;
    }
  }

  &__description {
    line-height: site("line-height");

    @include mq(tiny) {
      min-height: 5em;
    }
  }

  &__version {
    display: inline-block;
    margin-top: gr(-2);
    margin-right: gr(-1);

    @include mq(tiny, max-width) {
      display: block;
    }

    a {
      &:not(:hover) {
        color: site("text-color-light");
      }
    }
  }

  &__type {
    display: inline-block;
    color: site("text-color-light");
    font-size: .7em;
    vertical-align: top;
    top: .25em;
  }

  &__print {
    &:not(:hover) {
      color: site("text-color-light");
    }
    @include mq(tiny, max-width) {
      display: block;
      margin-top: gr(-1);
    }
  }
  &__copy-object-btn {
    margin-right: gr(-1);
    @include mq(tiny, max-width) {
      margin: gr(-1) 0 0 0;
      width: 100%;
    }
  }

  &__thank-msg {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    line-height: site("line-height");
    @include mq(tiny) {
      min-height: 5em;
    }
  }

  &__thank-wrapper {
    background: #FFF;
    padding: 1em;
  }

  &__add-customer {
    max-width: 20em;
  }

  &__customer-detail {
    margin-bottom: 1.5em;
    ul {
      line-height: 1.5;
      color: site("text-color-light");
    }
  }
  &__more-actions {
    padding: 0;
    border: none;
    min-width: 10em;
    vertical-align: bottom;
    transition: none;
    > .select__button {
      padding: 7px gr(-1);
      @include mq(tiny, max-width) {
        .select__placeholder {
          display: block;
          text-align: center;
          width: 100%;
        }
        .fa {
          position: absolute;
          right: 0.5em;
        }
      }
    }
    .select__list {
      min-width: 14em;
      left: initial !important;
      width: auto;
      @include mq(tiny, to, small) {
        right: initial !important;
        left: 0 !important;
      }
      @include mq(tiny, max-width) {
        width: 100%;
      }
    }
  }
  &__pdf {
    max-width: 50px;
    padding-right: 0.5em;
    &.small {
      max-width: 17px;
      padding-right: 0;
      margin-right: gr(-2);
    }
  }
  &__post {
    img {
      max-width: 100%;
      padding: gr(-1) 0;
    }

    &--edit-btn {
      &:not(:hover) {
        opacity: .61805;
      }
    }
  }
  &__post-meta {
    padding-bottom: gr(-1);
    font-size: 0.9em;
  }

  &__new-blog {
    z-index: 2;
    @include position(absolute, 0, 0, null, null);
  }
  &__separator {
    padding-top: 3.618em;
    margin-top: 1.618em;
    border-top: 1px solid #e0e0e0;
  }

  &__company-logo {
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    padding: 2em 3em;
    margin-top: 1em;
    background-color: site("color")
  }

  &__collabration {
    width: 100%;
    background-color: color($dokiv-orange);
    padding: 1em;
    border-radius: site("br");
    .collabration-icon  {
      float: left;
      margin-right: 2em;
      img {
        max-width: 100px;
        max-height: 30px;
      }
    }
    
    .collabration-label {
      float: left;
      color: #fff;
      font-size: 25px;
      padding-top: 0.15em;
    }
  }

  &__button--yellow {
    background: color($dokiv-orange, 500);
    border-color: color($dokiv-orange, 500);
    color: #fff;
    &:hover {
      background: #f7a63b;
      border-color: #f7a63b;
    }
  }

  &__certificate-doc {
    border: 1px solid color($grey, 300);
    border-radius: site("br");
    padding: 1em;
    p {
      margin: 0;
    }
  }

  &__button-first {
    .button {
      padding: 0.5em;
    }
  }

  &__not-scroll {
    html.no-touch & {
      overflow: inherit;
    }
  }

  &__flow-verify {
    padding-right: 1.9em;
    .publication-details__only-icon {
      padding: 0.6em 0.5em
    }
  }

  &__only-icon {
    .fa {
      margin-right: 0;
    }
  }

  &__comments-wrap {
    margin-top: 2em;
    .comment__list {
      margin-top: 0.7em;
    }
  }

  &__comment {
    border: 0;
    box-shadow: none;
    &.comment__wrap {
      padding: 0 0 0.7em 2.6em;
      min-height: inherit;
    }
    &.comment__wrap, & .comment__meta {
      overflow: inherit;
    }
    .comment__meta {
      float: left;
      padding: 0;
      @include position(inherit, null, null, null, null);
      width: inherit;
      margin-right: 2em;
      p {
        margin-bottom: 0.3em;
      }
      .user {
        font-size: 12px;
        color: color($grey, 600);
      }
      margin-bottom: 0.3em;
      @include mq(small, max-width) {
        margin-bottom: 0.3em;
      }
    }
    .comment__body {
      float: left;
      .comment {
        color: #000;
        font-size: 12px;
        line-height: 1;
      }
    }
    .comment__avatar {
      @include position(absolute, 0, null, null, 0);
    }
  }

  &__comment-wrap-body {
    float: left;
  }

  &__add-comment {
    padding-bottom: 0;
    &.comment__add .comment__avatar {
      width: 34px;
    }
  }

  &__add-comment-child {
    margin-left: 1em;
    padding: 0 0 0 0.7em;
    border-left: 0.3em solid color($grey, 300);
    &.comment__add .comment__avatar {
      width: 27px;
    }
    &.comment-child {
      .publication-details__comment, & .comment__meta {
        padding-top: 0;
      }
    }
    .comment__avatar {
      width: 24px;
    }
  }

  &__show-more-comment {
    @include position(absolute, null, null, 35px, 18em);
    font-size: 12px;
  }

  &__notes-description {
    box-shadow: 1px 1px 1px 0px color($grey, 500);
    background: #fff;
    padding: 4em;
    border: 1px solid color($grey, 500);
  }

  &__form--footer {
    text-align: center;
    padding-top: 1em;
    margin-top: 2em;
    border-top: 1px solid site("border-color");
  }

  &__form--wrap {
    .button__bar--back {
      @include position(absolute, -32px, null, null, 85px);
      z-index: z("low");
    }
  }
  &__deviations {
    border-radius: site("br");
    border: 1px solid color($grey, 500);
    li {
      padding: 1em;
      background: color($grey, 200);
      cursor: pointer;
      &:not(:first-child){
        border-top: 1px solid color($grey, 500);
      }
    }
  }

  &__main-image {
    border-radius: site("br");
    border: 1px solid color($grey, 300);
  }

  &__slide-image {
    li {
      float: left;
      margin-right: 1em;
      font-size: 0.5em;
      margin-bottom: 1em;
      height: 70px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item-line {
    padding: 0.5em;
    border-bottom: 1px solid color($grey, 300);
  }

  &__lable-description {
    height: 100px;
    border-radius: site("br");
    border: 1px solid color($grey, 300);
    padding: 0.5em;
  }

  &__uppercase {
    text-transform: uppercase;
  }
}
