// ===========
//  CHECKLIST
// ===========

/*
  [*] Must nest to override regular form nested rules...
      At some point we *really* should flatten things down.
*/

// local variables:
// ----------------

  $block: checklist;

// ----------------


.#{$block} {
  background: #fff;
  padding: gr(-1) 1em;
  border-radius: site("br");
  box-shadow: 0 1px 2px rgba(#000, .3);

  li { /* [*] */
    margin: 0;

    &:last-child {
      .checkbox__wrap {
        margin-bottom: 0;
      }
    }
  }

  label { /* [*] */
    margin: 0;
    padding: gr(-2) 0;
    font-weight: 400;
  }

  &__delete {
    font-size: 1.3em;
    color: color($grey, 500);
  }

  &__label {
    margin-left: 24px;
    .checkbox__input[disabled] + & {
      opacity: 1;
    }

    .checkbox__input:checked + & {
      color: site("text-color-light");

      &::before {
        background:   color($dokiv-green);
        border-color: color($dokiv-green);
      }
    }

    &::before {
      margin-right: gr(-1);
      float: left;
    }
  }

  &--editing {
    label {
      padding: .5em 0;
    }

    li {
      &:nth-child(even) {
        background: color($grey, 100);
      }
    }
    @include mq(tiny, max-width) {
      .fa-plus {
        margin-right: 0;
      }
    }
  }

  .#{$block}__input { /* [*] */
    background: color($grey, 50);
  }

  &__action {
    padding: .55em;

    &:hover,
    &:focus {
      color: #fff;
      background: site("color");
    }
  }

  &__form-wrapper {
    position: fixed !important;
    width: 300px;
    @extend %skin;
    background: #fff;
    padding: 1em;
    margin-top: 0.5em;
    z-index: z("high");
    .select__list {
      width: 100% !important;
    }
    .select__item {
      overflow: auto;
    }
  }

  &__new-wrapper {
    > .button {
      width: 100%;
    }
  }

  &__checkbox {
    float: left;
    padding-right: 3px;
    .fa-lg {
      line-height: 1;
    }

    .fa-check-square {
      color: color($dokiv-green);
    }

    .fa-square-o {
      color: site("border-color-dark");
    }
  }

  &__content {
    margin: 0;
    padding: gr(-2) 0;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      .checklist__dnd--item {
        opacity: 0.8;
      }
    }
  }

  &__header {
    cursor: move;
    h3 {
      cursor: pointer;
    }
    p {
      margin: 0;
      line-height: 1;
    }
  }

  &__item {
    padding: .5em 0 1em;
    z-index: 100;
  }

  &__dropzone {
    @extend %separator;
    .dndDragging {
      opacity: 0.8;
    }

    .dndDraggingSource {
        display: none;
    }

    .dndPlaceholder {
      background-color: #ddd !important;
      min-height: 200px;
      display: block;
      position: relative;
    }
    .itemlist {
      padding: 5px 0;
      .dndPlaceholder {
        min-height: 25px;
      }
      li {
        z-index: 1052;
      }
    }

    .page-tree__sub {
      .dndPlaceholder {
        min-height: 25px;
      }
    }
  }

  &__dnd--item {
    @include position(absolute, 0, 0, null, null);
    opacity: 0;
    font-size: 22px;
    z-index: z("high");
    @include mq(small, max-width) {
      display: none;
    }
    @extend %transition;
  }

  &__fixed {
    margin-right: 1em;
  }

  &__list-centrally {
    @include mq(small) {
      & {
        float: left;
      }
      
      & + & {
        float: right;
      }
    }
    @include mq(max-width) {
      margin-top: 1.5em;
    }
  }
}
