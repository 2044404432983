// =========
//  HELPERS
// =========

.cf {
  @extend %clearfix;
}

.overflow--hidden {
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  @extend %ellipsis;
}

.row {
  @extend %vertical-margins;

  &:empty {
    margin: 0;
  }

  &--small {
    @include vertical-margins(gr(-1));
  }

  &--large {
    @include vertical-margins(gr(1));
  }
}

.separator {
  @extend %separator;

  &--small {
    @include separator(1em, top);
  }
}

.text-color--light {
  color: site("text-color-light");
}

.text-height {
  line-height: site("line-height");
}

.text-center { text-align: center !important; }
.text-left   { text-align: left   !important; }
.text-right  { text-align:  right !important; }

.text-monospaced {
  font-family: monospace;
}

.text-regular {
  font-family: body("family"), body("fallback");
}

.no-padding { padding: 0 !important; }
.no-margin  { margin:  0 !important; }

[ng-cloak] {
  display: none !important;
}

.sr-only {
  position: absolute;
  width:    1px;
  height:   1px;
  margin:  -1px;
  padding:  0;
  border:   0;
  overflow: hidden;
  clip:     rect(0, 0, 0, 0);
}

.line-component {
  & {
    float: left;

    &--padded + &--padded:not(.ng-hide) {
      margin-left: gr(-1);
      margin-right: 0;
    }
  }

  &--omega {
    float: right;

    &.line-component--padded + &.line-component--padded:not(.ng-hide) {
      margin-right: gr(-1);
      margin-left: 0;
    }
  }
}

.center {
  margin-left:  auto;
  margin-right: auto;
}

.hide-above-tiny   { @extend %hide-above-tiny;   }
.hide-below-tiny   { @extend %hide-below-tiny;   }
.hide-above-small  { @extend %hide-above-small;  }
.hide-below-small  { @extend %hide-below-small;  }
.hide-above-medium { @extend %hide-above-medium; }
.hide-below-medium { @extend %hide-below-medium; }
.hide-above-large  { @extend %hide-above-large;  }
.hide-below-large  { @extend %hide-below-large;  }

.disable-a-href {
  cursor: default;
  pointer-events: none;
}