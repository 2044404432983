// ==============
//  FORM ANSWERS
// ==============

/*
  [1] The answers in the report are (obviously) disabled, but to
      improve readability we remove the regular disabled styles.

  [2] This selector is supposed to cover all sorts of "meta"
      information that we want to display but not have visual
      focus.

  [3] Limit the max-width of table headers, or long questions will
      push the answers to the very edge of the table.
*/


// local variables:
// ----------------

  $block: answer;

  $visible-rows: 10;
  $list-max-height: site("line-height") * $visible-rows * 1em;
  $answer-padding: 1em;

// ----------------


.#{$block} {
  &__list {
    @include mq(tiny, max-width) {
      @include stretch();
    }

    .checkbox__label,
    .radio__label, { /* [1] */
      opacity: 1 !important;
    }
  }

  &__wrap {
    @extend %transition--paint;
    background: #fff;
    box-shadow: 0 1px 2px rgba(#000, .3);
    border-left: gr(-2) solid site("color");
    padding: $answer-padding;
    border-radius: 0 site("br") site("br") 0;
    page-break-inside: avoid !important;
    display: block;
    & + & {
      margin-top: 1em;
    }

    &--empty {
      &:not(:hover) {
        opacity: gr(-1, null);
      }
    }
  }
  
  &__number {
    display: inline-block;
    padding: 0.2em 0.4em;
    background: #eee;
    border-radius: site("br");
  }

  &__body {
    margin-top: gr(-1);
  }

  &__text {
    @extend %momentum-scroll;
    max-height: $list-max-height;
    line-height: site("line-height");
    @include shadow-covers(#fff);
  }

  &__percentage {
    color: site("text-color-light");
    font-size: .9em;
  }

  &__details {
    @extend %transition--paint; /* [2] */
    font-size: .9em;

    &:not(:hover) {
      color: site("text-color-light");
    }
  }

  &__details-toggle {
    margin-top: 2.5em;
  }

  &__submissions {
    font-size: gr(1);

    span {
      display: inline-block;
      padding: .15em .2em .1em;
      border-radius: site("br");
      background: site("color");
      color: #fff;
    }
  }

  &__participation {
    font-size: gr(1);
  }

  &__tick {
    th {
      max-width: 25em; /* [3] */
    }
  }

  &__company {
    margin: 0;
  }

  &__signature {
    @include separator;
  }

  &__query {
    max-width: 35em;
  }

  &__query-column {
    @include columns(2);
  }
  &__column {
    @include mq(tiny, max-width) {
      & + & {
        @include separator();
      }
    }


    @include mq(small) {
      &     { @include span(4 of 12 .25 last); }
      & + & { @include span(8 of 12 .25); }
    }

  }

  &__create-object {
    @include position(absolute, 0, 0, null, null);
  }
}
