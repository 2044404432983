$block: templates;

.#{$block} {
  &-one {
    &__column {
      @include mq(small) {
        & {
          @include span(4 of 12 0.25);
        }
        & + & {
          @include span(8 of 12 0.25 last);
        }
      }

      @include mq(small, max-width) {
        & + & {
          margin-top: 2em;
        }
      }
    }
  }

  &-two {
    &__column {
      @include mq(large) {
        &:nth-child(1) {
          @include span(6 of 12 0.25);
        }
        &:nth-child(2) {
          @include span(6 of 12 0.25 last);
        }
        &:nth-child(3) {
          @include span(6 of 12 0.25);
        }
      }
      @include mq(small) {
        &:nth-child(1) {
          @include span(6 of 12 0.25);
        }
        &:nth-child(2) {
          @include span(6 of 12 0.25 last);
        }
        &:nth-child(3) {
          @include span(6 of 12 0.25);
        }
      }
      @include mq(large, max-width) {
        &:nth-child(3) {
          margin-top: 2em;
        }
      }

      @include mq(small, max-width) {
        margin-bottom: 2em;
      }

      &.latest-column {
        @include mq(large) {
          @include span(9 of 12 0.25 last);
        }
        .#{$block}__split-column {
          @include mq(large) {
            flex: 0 0 33.333%;
          }
          @include mq(small) {
            flex: 0 0 50%;
          }
        }
      }
    }
  }
  &-three {
    &__column {
      &.have-message {
        @include mq(small) {
          & {
            @include span(4 of 12 0.25);
          }
          &:nth-child(2n) {
            @include span(8 of 12 0.25 last);
          }
        }
        &:nth-child(2n+1) {
          .templates__split-column {
            padding-right: 0;
          }
        }

        @include mq(small, max-width) {
          & + & {
            margin-top: 2em;
          }
        }
      }
      ol {
        min-height: 3em;
      }
    }
  }

  &-four {
    &__column {
      @include mq(small) {
        &:nth-child(1) {
          @include span(2 of 12 0.25);
        }
        &:nth-child(2) {
          @include span(10 of 12 0.25 last);
        }
      }
      &-right {
        &.have-message {
          @include mq(small) {
            &:nth-child(1) {
              @include span(8 of 12 0.25);
            }
            &:nth-child(2) {
              @include span(4 of 12 0.25 last);
            }
          }
          @include mq(small, to, medium) {
            &:nth-child(1) {
              @include span(6 of 12 0.25);
            }
            &:nth-child(2) {
              @include span(6 of 12 0.25 last);
            }
          }
          &:nth-child(2) {
            .templates__split-column {
              padding-right: 0;
            }
          }
        }
        ol {
          min-height: 3em;
        }
      }

      @include mq(small, max-width) {
        & + & {
          margin-top: 2em;
        }
      }
    }
  }

  &-five {
    &__body-background {
      background: #f2f8f8;
      .main-view {
        padding: 0;
      }
    }

    &__split-col-wrap {
      @extend %flex;
      @extend %flex-wrap;
    }
    &__split-column {
      width: 100%;
      @include mq(small, min-width) {
        &:first-child {
          flex: 1;
          min-height: 1200px;
        }
        & + & {
          flex: 7;
        }
      }
      & + & {
        .content-holder {
          margin: 2em;
          @include mq(large, max-width) {
            margin: 1em;
          }
          padding: 0;
        }
      }
    }

    &__navdrawer {
      width: site("sidebar-width");
      @include mq(small, to, medium) {
        width: 150px;
      }
      @include mq(small, max-width) {
        width: 100%;
      }
      border-right: 1px solid darken(site("color"), 5%);
      background: site("color");
      color: #fff;
      height: 100%;
      ul {
        @include mq(small, max-width) {
          padding-bottom: gr(1);
          display: none;
          &.open {
            display: block;
          }
        }
        li {
          padding: gr(0.5) gr(1);
          @include mq(small, to, medium) {
            padding: gr(0.5);
          }
          a {
            background: $color-bg--focus;
            &:focus,
            &:hover,
            &:active {
              background: $color-bg--active;
              border-radius: site("br");
            }

            &:active {
              @extend %user-select-none;
            }
            &.last-menu {
              background: #0094da;
              &:focus,
              &:hover,
              &:active {
                background:   lighten(#0094da, $button-color--saturation);
              }
            }
          }
        }
      }
    }

    &__title-menu-step {
      padding: gr(1);
      @include mq(medium, max-width) {
        padding: gr(0.5);
      }
      line-height: 1.5;
      font-size: 1.2em;
      .fa {
        font-size: 2.5em;
      }
      button {
        @include position(absolute, 3px, 0, null, null);
      }
    }

    &__chart {
      padding: gr(0.5);
    }

    &__five-column {
      margin-top: gr(0.5);
      @include mq(medium) {
        @include columns(5, .25);
      }
      @include mq(small, to, medium) {
        @include columns(3, .25);
      }
      @include mq(tiny, to, small) {
        @include columns(2, .25);
      }
      min-height: 290px;
      background: #fff;
    }

    &__four-column {
      margin-top: gr(0.5);
      @include mq(medium) {
        @include span(3 of 12 0.25);
        &:nth-child(4n) {
          @include span(3 of 12 0.25 last);
        }
      }
      @include mq(small, to, medium) {
        @include span(6 of 12 0.25);
        &:nth-child(2n) {
          @include span(6 of 12 0.25 last);
        }
      }
      @include mq(medium, max-width) {
        .line-component--omega {
          width: 150px;
        }
      }
      background: #fff;
      padding: gr(1) gr(1) gr(0.5) gr(1);
    }
    &__column {
      background: #fff;
      margin-top: gr(0.5);
      min-height: 308px;
      padding: gr(1);
      @include mq(small, max-width) {
        padding-bottom: gr(3);
      }
      @include mq(large, min-width) {
        @include span(4 of 12 0.25);
        &:nth-child(3) {
          @include span(4 of 12 0.25 last);
        }
      }
      @include mq(small, to, large) {
        @include span(6 of 12 0.25);
        & + & {
          @include span(6 of 12 0.25 last);
        } 
      }
      &-header {
        @include mq(tiny, min-width) {
          & {
            @include span(4 of 12 0.25);
          }
          & + & {
            @include span(8 of 12 0.25 last);
            text-align: right;
          }
        }
        @include mq(tiny, max-width) {
          & + & {
            margin-top: 2em;
          }
        }
      }
      &--title {
        font-size: 1.5em;
      }
    }

    &__effect {
      cursor: pointer;
      &:hover {
        box-shadow: site("box-shadow");
      }
    }

    &__title-padded {
      padding: 1em;
    }

    &__row-text {
      font-size: 1.5em;
      @include mq(medium, to, large) {
        font-size: 1.2em;
      }
      margin: 0;
      button:hover {
        color: #9e9e9e;
      }
    }

    &__large-icon {
      .fa {
        font-size: 2.5em;
        @include mq(medium, to, large) {
          font-size: 1.5em;
        }
        margin: 1em 0 .5em 0;
      }
    }

    &__light-color {
      color: color($grey, 500);
    }

    &__button-line {
      height: 15px;
      background: #2ab6d3;
      bottom: 18px;
      width: 100%;
      margin-top: 1.5em !important;
    }

    &__table-changes {
      width: 100%;
      &.separator {
        border-top: 1px solid color($grey, 400);
      }
      tr {
        border-bottom: 1px solid color($grey, 400);
        td, th {
          padding: 10px 0;
        }
        &:last-child {
          border: 0;
        }
      }
      .tab-content__tittle-step {
        display: inline-block;
        line-height: 2.45;
      }
    }

    &__bottom-position {
      @include position(absolute, null, null, 0, 0);
      width: 100%;
      border-top: 1px solid color($grey, 300);
      padding: 0.4em 1.5em;
      .line-component {
        padding-top: 0.6em;
      }
      a:hover {
        color: #9e9e9e;
      }
    }

    &__row-delete {
      td {
        background: lighten(color($dokiv-red), $button-color--saturation) !important;
      }
    }

    &__home-icon {
      width: 27px;
      top: -2px;
      margin-right: 5px;
    }
  }

  &__dashboard-settings {
    box-shadow: 0 4px 6px rgba(0,0,0,.25);
    background: color($grey, 100);
    padding-bottom: 1em;
    width: 100%;
    &.setting-scroll {
      @include position(fixed, 51px, 0, null, null);
      z-index: 3;
    }
    .button:not(.button--positive):not(.button--negative) {
      background: #fff;
    }
    .watchers__list {
      min-width: 30em;
      min-height: auto;
    }
    @include mq(tiny, min-width) {
      .button {
        margin-bottom: gr(0.5);
      } 
    }
    @include mq(tiny, max-width) {
      .publication-edit__wrap {
        margin-top: gr(0.5);
      }
      .select__list {
        text-align: left;
      }
    }
  }

  &__split-col-wrap {
    margin-right: -#{gr(1)};
    margin-top: 0;
    @extend %flex;
    @extend %flex-wrap;
    & > li {
      list-style: none;
    }
  }
  &__split-column {
    padding: 0 gr(0.5) 2em gr(0.5);
    @include mq(medium) {
      flex: 0 0 66.666%;
      max-width: 666.666%;
      &.not-full-box {
        flex: 0 0 33.333%;
        max-width: 33.333%;
      }
      .templates-four__column-right.have-message &, .templates-three__column.have-message &, .templates-two__column &, .templates-one__column & {
        flex: 0 0 100%;
        max-width: 100%;
        &.not-full-box {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    flex: 0 0 100%;
    max-width: 100%;
  }

  &__categories {
    .card-list__list {
      .card-list__item,
      .card-list__item:nth-child(3n+3) {
        @include columns(2, .3);
        margin-bottom: gr(-1);
        .templates__three-header & {
          @include mq(tiny,  to, small ) { @include columns(2, .4); }
          @include mq(small, to, medium) { @include columns(4, .25); }
          @include mq(medium)            { @include columns(6); }
        }
        .templates-four__column & {
          @include mq(small, min-width) { @include columns(1, 0); }
        }
      }
    }

    .card-list__item {
      .card-list__link {
        padding: 0.7em;
        height: auto;
        h3.card-list__name {
          font-size: 1.2em;
        }
      }
    }
  }
  &__links {
    .card-list__list {
      .card-list__item,
      .card-list__item:nth-child(3n+3) {
        @include columns(2, .3);
        margin-bottom: gr(-1);
        .card-list__link {
          height: auto;
          .card-list__label {
            font-size: 1em;
          }
        }
        .templates__three-header & {
          @include mq(tiny,  to, small ) { @include columns(2, .4); }
          @include mq(small, to, medium) { @include columns(4, .25); }
          @include mq(medium)            { @include columns(6); }
        }
        .templates-four__column & {
          @include mq(small, min-width) { @include columns(1, 0); }
        }
      }
    }
  }

  &__user-links {
    .card-list__list {
      .card-list__item:not(.item-template-three) {
        .card-list__link {
          padding: 0.7em;
        }
        background: color($dokiv-blue);
        .icon-only {
          @include position(absolute, 0, 0, null, null);
          color: #FFF;
          opacity: 0.7;
          padding: 4px;
          &:hover,
          &:active {
            opacity: 1;
          }
        }
      }
    }
  }
  &__content-column {
    background: site("bg-color");
    border-radius: site("br");
    border: 1px solid #d2d2d2;
    .markdown__wrap {
      margin-top: gr(-1);
      margin-bottom: 0;
    }

    .#{$block}__label {
      @include position(sticky, 0, null, null, 0);
      z-index: 1;
      display: inline-block;
      background: #adacac;
    }

    a {
      color: color($dokiv-blue);
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .publication-edit__wrap {
      .dnd-handle {
        font-size: 1.3em;
        cursor: move;
        top: 2px;
      }
    }
  }

  &__content {
    padding: gr(-1);
  }

  &__label {
    background: site("color");
    padding: gr(-1);
    color: #FFF;
    &.label-orange {
      background: color($dokiv-orange);
    }
    &.label-green {
      background: color($dokiv-green);
    }
    .line-component--omega {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
    &.label-orange, &.label-green {
      margin-bottom: 1em;
    }
  }

  &__add-link {
    .button {
      padding-right: 3em;
    }
    .icon-right {
      padding: 0.5em 0.5em 0.5em 0;
      border-left: 1px solid darken(color($dokiv-green), 5%);
      @include position(absolute, 0, 0, null, null);
    }
    margin-bottom: 0.5em;
  }

  &__shortcut-modal {
    ul {
      > li {
        margin-top: 5em !important;
      }
    }
  }

  &__message-separator {
    margin-bottom: 2em;
  }

  &__reminder-wrapper {
    .reminder__item {
      width: 100% !important;
      &:not(:first-child) {
        .reminder__link {
          .reminder__body {
            padding-bottom: 2.5em;
          }
        }
      }
      .reminder__link {
        min-height: inherit;
      }
      &:first-child {
        background: #f26a5d;
        .reminder__link {
          padding: gr(-1);
          font-size: 1em;
          .reminder__body {
            margin: 0;
          }
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  &__toggle-menu {

    &.open {
      display: block;
      animation: fallDown  transition("duration") transition("swift-in") forwards;
    }

    @include mq(small, max-width) {
      display: none;
    }
    
  }

  &__toggle-menu-three {
    display: none;
    &.open {
      display: block;
      animation: fallDown  transition("duration") transition("swift-in") forwards;
    }
  }
  &__button-menu {
    padding: 1em;
    border-right: 1px solid #e0e0e0;
    @include position(absolute, 0, 0, null, null);
    display: none;
    @include mq(small, max-width) {
      display: block;
    }
  }

  &__text-button-menu {
    &.button.icon-only {
      padding-top: 2px;
    }
    @include mq(small, max-width) {
      display: none;
    }
    .fa {
      font-size: 1.8em;
      bottom: -3px;
    }
    .fa-angle-up, .fa-angle-down {
      font-size: 1.5em;
      top: 5px;
    }
  }

  &__group-members {
    min-width: 22em;
    background: site("bg-color");
    padding: 1em;
    border: 1px solid site("border-color");
    border-radius: site("br");
    position: absolute;
    z-index: z("mid");
    @include mq(small, max-width) {
      min-width: 100%;
    }
    h4 {
      color: site("text-color");
    }
  }

  &__group-btn {
    @include position(absolute, null, 0, gr(-2), null);
    z-index: z("low");
    .fa {
      font-size: 1.5em;
    }
  }

  &__read-more {
    padding-bottom: gr(-1);
    padding-top: gr(-1);
    > span {
      cursor: pointer;
      &:hover {
        color: color($dokiv-red);
      }
    }
  }
  &__more-less-toggle {
    &:before {
      content: "";
      @include position(absolute, null, 0, 0, 0);
      width: 100%;
      height: 25px;
      z-index: z("low");
    }
  }

  &__list-posts {
    .visible-comments__section {
      &:nth-child(even) {
        .visible-comments__comment {
          .#{$block}__more-less-toggle.short-text {
            &:before {
              background: linear-gradient(to bottom, rgba(100, 100, 100, 0) 0%, #daedf4 75%);
            }
          }
        }
      }

      &:nth-child(odd) {
        .visible-comments__comment {
          .#{$block}__more-less-toggle.short-text {
            &:before {
              background: linear-gradient(to bottom, rgba(100, 100, 100, 0) 0%, site("bg-color") 75%);
            }
          }
        }
      }
    }
  }

  &__button-read-more {
    @include position(sticky, null, 0, 0, null);
    background: color($grey, 100);
    padding: 1em;
    .button {
      margin-right: 1em;
      background: #388803;
      color: #fff;
    }
  }
}

.grid-stack {
  @include mq(small, max-width) {
    height: auto !important;
  }
}
.grid-stack-item,
.grid-stack-item-content {
  @include mq(small, max-width) {
    display: block !important;
    position: relative !important;
    top: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
    height: auto !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    min-height: 0 !important;
  }
}