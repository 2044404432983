// ==========
//  VERSIONS
// ==========

/*
  [1] The thumbnail must have a static height since images can
      have different heights, since we occasionally want the
      thumbnail to be just text.

  [2] Force images to fill be their parent's width.

  [3] Allow images to stretch beyond their parent's maximum width
      if necessary.

  [4] Limit the parent's height to the same value so that larger
      images don't affect page layout.
*/


// local variables:
// ----------------

$thumbnail-height: 12em;

// ----------------


.versions {
  &__holder {
    @extend %transition;
    @extend %skin;
    background: #fff;
    padding-bottom: 1em;

    &:hover {
      border-color: color($grey, 400);
    }

    &--latest {
      text-align: center;
    }
  }

  &__thumbnail {
    display: block;
    min-height: $thumbnail-height; /* [1] */

    img {
      display: block;
      min-width: 100%; /* [2] */
      height: $thumbnail-height;
      object-fit: contain;
    }

    &.not-thumb img {
      height: inherit;
    }

    &-parent {
      display: block;
      overflow: hidden;
      max-height: $thumbnail-height; /* [4] */
      border-bottom: 1px solid site("border-color");
    }
  }

  &__number {
    display: inline-block;
    font-size: 1em;
    padding: gr(-1);
    font-weight: body("bold");
    border-radius: 0 site("br") 0 site("br");
    pointer-events: none;
    background: #fff;
    @include border(1px solid site("border-color"), left bottom);
    @include position(absolute, 0, 0, null, null);
  }

  &__expand {
    padding: gr(-1) gr(-2);
    @include position(absolute, null, 0, 0, null);
    opacity: 0.7;
    &:hover,
    &:active {
      opacity: 1;
    }
    z-index: z("mid");
  }
}
