.right-menu {
  &__wrap {
    color: site("text-color");
    @extend %momentum-scroll;
    background: #FFF;
    padding: gr(1);
    z-index: z("low");
    @include position(fixed, 0, 0, 0, null);

    min-width: site("sidebar-width");
    max-width: 500px;
    max-height: calc(100vh - #{site("bar-height")});
    box-shadow: site("box-shadow");
    top: site("bar-height");


    &.sidebar-enter {
      animation: flyLeft transition("duration") transition("swift-in") forwards;
    }

    &.sidebar-exit {
      animation: flyRight transition("duration") transition("swift-in") forwards;
    }

    .dropdown__list {
      margin: 0 (- gr(1)) ;
      a, button {
        padding: gr(-1) gr(1);
      }
    }
    h4  {
      margin-bottom: gr(-2);
    }
  }

  &__header {
    margin-bottom: gr(0);
  }

  &__sub-header {
    padding: gr(-1) gr(1) 0 gr(1);
  }
  &__current-company {
    padding: gr(-1) gr(1);
    font-size: 1.2em;
    background: lighten(color($dokiv-green), 5%);
  }
  &__companies {
    padding: 0 gr(1);
    list-style-type: disc;
    a {
      display: block;
      padding-bottom: gr(-1);
      &.active,
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__close {
    padding: gr(1);
    @include position(absolute, 0, 0, null, null);

    &:hover {
      color: color($dokiv-red);
    }

    .fa {
      font-size: 1.2em;
    }
  }
}