// ==========
//  REQURIED
// ==========

/*
  [*] Apply the class name "required" to any element to give it
      one of them fancy star tails.
*/

.required {
  &::after {
    content: "*";
    color: color($dokiv-red);
    margin-left: gr(-3);
  }
}
.required-text {
  span {
    color: color($dokiv-red);
  }
}
