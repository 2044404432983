.progress-wrapper {
  padding: gr(-1) 0;
  margin-bottom: 1em;
}
.progress {
  background: #d6dadc;
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(black, .1), 0 1px rgba(white, .08);
  margin-left: 3em;
  margin-top: 2px;
  overflow: hidden;
}

.progress-bar {
  width: 0;
  position: relative;
  height: 10px;
  border-radius: 6px;
  transition: width transition("duration") transition("swift-in"),
      background-color   transition("duration") transition("swift-in");
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &:before {
    bottom: 0;
    background: #4ca5d0;
    border-radius: 6px 0 0 6px;
  }

  &:after {
    z-index: 2;
    bottom: 45%;
    border-radius: 6px;
  }
}