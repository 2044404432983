// =====================
//  PUBLICATION CARDS
// =====================

// local variables:
// ----------------
  $block: card;
// ----------------


.#{$block} {
  &__separator {
    @include mq(small) {
      padding-right: gr(2);
      margin-right:  1em;
      border-right:  1px solid site("border-color");
    }
  }

  &__column {
    @include mq(small, max-width) {
      & + & {
        @include separator();
      }
    }

    @include mq(small) {
      @include columns(3, .6);
    }
    &--main {
      @include mq(small, max-width) {
        & + & {
          @include separator();
        }
      }
      @include mq(small) {
        &:nth-child(1) {
          @include span(8 of 12 .5);
        }
        &:nth-child(2) {
          @include span(4 of 12 .5 last);
        }
      }
    }
  }

  &__thumbnail-holder {
    text-align: center;
    border-radius: site("br");
    border: 1px solid site("border-color");
  }
}
