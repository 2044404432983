// =======
//  USAGE
// =======

/*
  [1] Currently all warning type classes can exist at the same
      time, so keep in mind that their order in the CSS is
      important.

  [2] Override the local font-weight set in __wrap.
*/

.usage {
  &__wrap {
    font-weight: body("bold");
  }

  &--warning { color: color($orange); } /* [1] */
  &--full    { color: color($red); }

  &__percentage {
    font-size:   .8em;
    font-style:  italic;
    font-weight: body("regular"); /* [2] */
  }
}
