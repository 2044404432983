// ================
//  SELECT COMPANY
// ================

/*
  [1] This is for the glow color.
*/

// local variables:
// ----------------

$box-inset: 1em;

// ----------------

.select-company {
  &__animation {
    &-enter {
      animation: zoomIn transition("duration") transition("swift-in") forwards;
    }
    &-exit {
      animation: zoomOut transition("duration") transition("swift-in") forwards;
    }
  }
  &__content-holder {
    max-width: site("width--select-company");
    margin: 0 auto;
    padding: gr(1) 1em 0;
  }
  &__column {

    @include mq(medium) {
      & {
        @include span(2 of 12 0.3);
      }
      & + & {
        @include span(10 of 12 0.3 last);
      }
    }

    @include mq(tiny, to, medium) {
      & {
        @include span(2 of 12 0.4);
      }
      & + & {
        @include span(10 of 12 0.4 last);
      }
    }

    &--first {
    }
  }

  &__wrap {
    @include mq(small) {
      display: flex;
      flex-wrap: wrap;
    }

    min-height: calc(100vh - 7em);
  }

  &__header {
    font-size: 1.4em;
    @include mq(tiny, max-width) {
      font-size: gr(1);
    }
  }

  &__body {
    margin-bottom: gr(1);
    @include mq(small, max-width) {
      text-align: center;
    }
    &--column {
      @include mq(small) {
        & {
          @include span(8 of 12 0.3);
        }
        & + & {
          @include span(4 of 12 0.3 last);
        }
      }
      @include mq(tiny, to, small) {
        & + & {
          margin-top: 1em;
        }
      }
    }
  }

  &__body-start {
    background: none;
  }

  &__search {
    label {
      font-size: 1.2em;
      margin-bottom: gr(-3);
    }
  }

  &__type {
    @extend %transition;
    text-align: center;
    margin: $box-inset;
    padding: gr(-2);
    border: 1px solid rgba(#fff, gr(-1, null));
    opacity: gr(-1, null);
    border-radius: site("br");
    @include position(absolute, null, null, 0, 0);

    .card-list__item:hover & {
      opacity: 1;
    }

    .fa {
      font-size: 1.2em;
      margin: 0;
    }

    span {
      display: block;
      font-size: .9em;
      line-height: site("line-height");
    }
  }

  &__name {
    @extend %ellipsis;
    &.font-smaller {
      font-size: 1.1em;
    }
  }

  &__separator {
    @include mq(small) {
      padding-right: gr(1);
      border-right: 1px solid site("border-color");
    }
  }

  &__collaboration-column {
    @include columns(2, .25);
    margin: 0.3em 0;
  }

  &__item {
    &.select-company__item-publication {
      @include mq(tiny) {
        @include columns(2, .3);
      }
    }
    &.menu-column {
      @include mq(medium, min-width) {
        @include columns(7, .25);
      }
      @include mq(small, to, medium) {
        @include columns(4, .25);
      }
      @include mq(tiny, to, small) {
        @include columns(2, .25);
      }
      a {
        min-height: 130px;
      }
      .icon-symbol {
        font-size: 3em !important;
      }
    }
    @extend %card-styles;
    white-space: normal;

    &.grey-item {
      background: #778085;
      color: #778085;
    }

    &.white-item {
      background: #fff;
      color: #778085;
    }

    &.white-item {
      background: #ffffff;
      h3, h4 {
        color: color($grey, 700);
      }
    }

    &--new {
      background: color($dokiv-green);
      color: color($dokiv-green); /* [1] */
    }

    .company-logo {
      display: block;
      margin: $box-inset;
      @include position(absolute, null, 0, 0, null);

      img {
        display: block;
        max-width: 100px;
        max-height: 50px;
      }
    }

    &-publication {
      background: #ec9b0b;
      &:hover {
        box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .1),
          0 0 1em rgba(236, 155, 11, 0.92);
      }
      h3 {
        white-space: normal;
        line-height: 1.1;
        padding-right: 10px;
      }
      .popover {
        color: site("text-color");
      }
    }

    &-overlay {
      @include position(absolute, 0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.2);
      .fa-stack {
        @include position(absolute, calc(50% - 1em), calc(50% - 1em), null, null);
        .fa-check {
          color: color($dokiv-green);
        }
        .fa-square {
          color: #fff;
        }
        animation: zoomIn 0.2s ease;
      }
    }
  }

  &__features {
    .card-list__link {
      display: flex;
      min-height: 6.5em;
      padding: 0.7em 1em;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        width: 100%;
        font-weight: normal;
      }
      .menu-top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        h3 {
          font-size: 1.8em;
          text-align: center;
        }
      }
    }
  }

  &__collabration-info {
    color: #fff;
    padding: gr(-2);
  }

  &__collabration-icon {
    display: block;
    margin: $box-inset;
    @include position(absolute, null, 0, 0, null);

    img {
      display: block;
      max-width: 100px;
      max-height: 30px;
    }
  }

  &__description {
    border: 0;
    opacity: inherit;
  }

  &__welcome {
    color: color($grey, 600);
    h2 {
      font-weight: 400;
    }
    p {
      font-size: 1.2em;
    }
  }

  &__image {
    img {
      width: 100%;
    }
    @include mq(small, max-width) {
      display: none;
    }
  }

  &__card-list {
    @include mq(tiny, max-width) {
      &:not(.open) {
        display: none;
      }
    }
  }
}