// ==================
//  PUBLICATION BULK
// ==================


// local variables:
// ----------------

  $block: bulk;

// ----------------


.#{$block} {
  &__remove {
    @include position(absolute, 0, 0, 0, null);
    padding: 0 gr(-1);
    opacity: 0;

    .#{$block}__wrap:hover &,
    .#{$block}__wrap > input:focus + & {
      opacity: 1;
    }
  }

  &__bar {
    @include mq(small, max-width) {
      @include separator(gr(1), bottom);
    }

    @include mq(small) {
      @include position(absolute, 0, 0, null, null);
      padding: gr(1);
    }
  }
}
