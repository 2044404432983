// ==========
//  CHECKBOX
// ==========

/*
  [*] Markup structure must be as follows:
      .checkbox__wrap>input.checkbox__input+label.checkbox__label

      Don't forget to add the for="" attribute to the label and
      match it with the input's ID. For repeated inputs, use
      checkbox-{{$index}}.

  [1] This is a map of colors for checkboxes. To add a new color,
      just add a new color name and value to the list, then use it
      by applying class name "checkbox--name" to the label.

  [2] We hide the regular checkbox by moving it outside of its
      wrapper.

  [3] Move it!

  [4] Avoid resizing with em in this case. Even the slightest
      difference in subpixel rounding will make the checkboxes
      look awful.
*/

// local variables:
// ----------------

$checkbox-colors: (
  /* [1] */ "blue": site("color"),
  "green": color($dokiv-green)
);

$block: checkbox;
$checkbox-length: 15px;

// ----------------

.#{$block} {
  &__wrap {
    overflow: hidden; /* [2] */
    @include vertical-margins(gr(-2));

    &.line-component {
      margin-top: 0;
      margin-bottom: 0;
    }

    table & {
      display: inline-block;
    }
  }

  &__item {
    position: static;
    @include vertical-margins(gr(-2));
  }

  &__input {
    margin-right: 1em;
    @include position(absolute, null, 100%, null, null); /* [3] */
  }

  &__label {
    overflow: hidden;
    vertical-align: top;
    margin: 0;
    display: inline-block;

    &::before,
    &::after {
      vertical-align: top;
      cursor: pointer;
    }

    &::before {
      content: "";
      float: left;

      background: #fff;
      border-radius: site("br");
      border: 1px solid site("border-color-dark");

      width: $checkbox-length;
      height: $checkbox-length;
      margin-right: gr(-2);
    }

    &:active {
      &::before {
        background: color($grey, 100);
      }
    }

    &::after {
      content: "";
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      display: none;
      color: #fff;
      position: absolute;

      display: none;
      color: #fff;

      @include position(absolute, 1px, null, 0, 4px);
    }

    .#{$block}__input:checked + & {
      &::before {
        background: site("text-color");
        border-color: site("text-color");
      }

      &::after {
        display: block;
      }

      @each $color, $value in $checkbox-colors {
        &.#{$block}--#{$color}::before {
          background: $value;
          border-color: $value;
        }
      }
    }

    .#{$block}__input[disabled] + & {
      opacity: 0.5;

      &::before,
      &::after {
        cursor: not-allowed;
      }
    }
  }
}
