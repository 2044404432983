// ============
//  HEADER BAR
// ============

/*
  [1] Box-shadow instead of border because of the static height;
      a regular border would disappear behind child elements'
      backgrounds.

  [2] Wrapper for the "left" section of the bar.

  [3] Wrapper for the "right" section of the bar.

  [4] Allow dropdown to escape to edges of screen.

  [5] These buttons (may) function as toggles for dropdown menus,
      so don't intrude on their active state styling.

  [6] The logo and the off-canvas sidebar should have the same
      width so that they align when it's on-canvas.
*/

// local variables:
// ----------------

$header-color-text: site("text-color-light");
$header-color-border: site("border-color");
$header-height: site("bar-height");

$logo-width: site("sidebar-width");
$logo-width--small: site("logo-width--small");
$logo-border-color: darken(site("color"), 10%);
$logo-height-offset: 12px;

$user-type--guest: color($dokiv-orange);

/* vars */

$menu_background: #035e8d;
$menu_color: #e2e2e2;
$menu_breakpoint: 1024px;
$menu_toggle_background: black;
$menu_border: 1px solid rgba(255, 255, 255, 0.1);
$menu_padding: 18px 20px;
$menu_icon_padding: 5px 10px;

/* hide show mixin */

@mixin show() {
  display: block;
  //animation: grow 0.2s ease-in; // optional animation
}

@mixin hide() {
  display: none;
}

/* global  styles */

// ----------------

.header {
  &__wrap {
    z-index: z("high");
    box-shadow: 0 1px 0 $header-color-border; /* [1] */
    color: $header-color-text;
    background: site("bg-color");
    height: $header-height;
    @include mq(medium) {
      display: flex;
    }

    @include mq(tiny) {
      @include position(fixed, 0, 0, null);
    }

    .button {
      border: 0;
      text-align: center;

      background: none;
      border-radius: 0;
    }
    .select__wrap {
      margin-top: 0.25em;
      margin-left: 1em;
      height: 35px;
    }
    .select__button {
      margin-left: 0 !important;
    }
  }

  &__close-menu {
    @include position(absolute, 60px, 20px, null, null);
    z-index: z("low");
        border-radius: 3px !important;
    background: #f5f5f5 !important;
    border: 1px solid #e0e0e0 !important;
    &:hover {
      background: darken(#f5f5f5, 5%) !important;
      border: 1px solid darken(#e0e0e0, 5%) !important;
    }
  }

  &__nav {
    float: left;
    height: $header-height;

    > ul > li {
      float: left;
    }
  }

  &__menu {
    @include position(fixed, 51px, null, null, 0);
    width: 100%;
    z-index: 9999;
    box-shadow: 1px 4px 9px color($grey, 500);
    background: color($grey, 100);
    padding: 2em;

    &.sidebar-enter {
      animation: fallDown transition("duration") transition("swift-in") forwards;
    }

    &.sidebar-exit {
      animation: flyUp--leave transition("duration") transition("swift-in")
        forwards;
    }

    @include mq(tiny, max-width) {
      overflow: auto;
      height: 90%;
    }
  }

  &__user-menu {
    /* [3] */
    float: right;
    height: $header-height;

    &,
    > ul {
      position: static; /* [4a] */
    }

    > ul > li {
      float: left;
    }

    .button {
      .fa-chevron-down {
        transition: transform transition("duration") transition("swift-in");
        margin: 0 0 0 gr(-2) !important;
        opacity: 0.5;

        @include mq(medium) {
          left: 0.3em;
          top: 0.2em;
        }
      }
    }

    .dropdown__toggle.active {
      .fa-chevron-down {
        top: 1px;
        transform: scale(1, -1);
      }
    }
  }

  &__user-dropdown {
    position: static; /* [4b] */
    > .dropdown__toggle {
      padding-left: 2.5em;
    }

    &--guest {
      @include mq(tiny, max-width) {
        background: $user-type--guest;
        color: #fff;

        > .button {
          &:hover:not(.active) {
            color: #fff;
          }
        }
      }
    }
  }

  &__logo {
    display: flex;
    padding: 0.5em;
    height: $header-height;
    float: left;
    align-items: center;
    color: #fff;

    width: $logo-width--small;
    @include mq(tiny, max-width) {
      width: 100px;
    }

    img {
      vertical-align: top;
      max-height: $header-height - $logo-height-offset;
    }
  }

  &__icons {
    float: left;
    height: $header-height;
    &,
    > ul {
      position: static; /* [4a] */
    }
    background: #ebe7e6;
  }

  &__icon {
    float: left;
    height: $header-height;
    line-height: $header-height;
    .button {
      height: 32px;
      border-radius: site("br");
    }
    margin-left: 1rem;
    &:first-child {
      margin-left: 0;
    }

    > div {
      line-height: 47px;
    }
  }

  &__toggle-menu {
    height: 50px !important;
    border-right: 0;
    border-radius: 0 !important;
    padding-left: 2em;
    padding-right: 1.5em;
    top: -2px;
    &.button {
      background: site("color");
    }
    @include mq(tiny, max-width) {
      padding-left: 1em;
      padding-right: 0.5em;
    }
    &.sidebar-open {
      .hamburger-icon {
        &:after {
          top: 0;
        }
      }
    }
    .hamburger-icon {
      &,
      &:before,
      &:after,
      &:active {
        background: #fff !important;
      }
      &:before,
      &:after {
        width: 30px;
        position: absolute;
        right: 0;
      }
      &:before {
        top: -10px;
      }
      &:after {
        top: 10px;
      }
    }
  }

  &__user-type {
    background: $user-type--guest;
    color: #fff;
    box-shadow: 0 1px 0 0 darken($user-type--guest, 5%);
    padding: 0 1em;
    line-height: $header-height;
  }

  &__companyinfo {
    @include position(absolute, null, 0, 0, null);
    &--column {
      @include mq(tiny, max-width) {
        & + & {
          margin-top: gr(1);
        }
      }

      @include mq(tiny) {
        & {
          @include span(7 of 12 0.25);
        }
        & + & {
          border-left: 1px solid site("border-color");
          padding-left: 1rem;
          @include span(5 of 12 0.25 last);
        }
      }
    }
  }

  &__module {
    & {
      width: 20%;
      float: left;
      > .select-company__item {
        margin: 0.5rem;
      }
    }
  }

  &__user-avatar {
    width: 24px;
    @include position(absolute, 4px, null, null, 0.5em);
  }
  &__user-name {
    margin-left: 1em;
  }

  &__home-icon {
    color: site("text-color");
    float: left;
    font-size: 0.9em;
    padding: 12px 15px !important;
  }

  &__primary-menu {
    @include mq(medium) {
      flex: 2;
      & > nav {
        position: initial !important;
        & > .main-menu {
          position: inherit !important;
          > li {
            position: inherit !important;
          }
        }
      }
    }
    float: left;
    .main-menu {
      @include mq(medium, max-width) {
        position: fixed;
        left: 0;
        right: 0;
        overflow-y: auto;
        max-height: calc(100vh - 50px);
      }
      > li {
        &:hover {
          a {
            background: #fff;
          }
        }
        a {
          border-left: 1px solid site("border-color");
        }

        &:last-child {
          a {
            border-right: 1px solid site("border-color");
          }
        }
      }
    }

    .menu {
      @include mq(medium, max-width) {
        ul li a {
          background: #fff;
        }
      }
      background: transparent;
      li.menu-hasdropdown {
        a {
          padding-right: 30px;
        }
        ul.menu-dropdown {
          li a {
            line-height: 1.4;
          }
        }
      }
      a {
        display: block;
        //white-space: nowrap; // prevent links form going to a new line
        color: site("text-color");
        &:hover {
          color: darken(site("text-color"), 10%);
        }
        label {
          position: absolute;
          right: 0;
          top: 18px;
        }
      }
      .menu-dropdown {
        @include mq(medium) {
          max-width: 100%;
          background: #fff;
          li {
            // &:last-child {
            //   > a {
            //     border-bottom: 1px solid site("border-color");
            //   }
            // }
            // > a {
            //   border-left: 1px solid site("border-color");
            //   border-top: 1px solid site("border-color");
            //   border-right: 1px solid site("border-color");
            // }
            a {
              border: none;
              padding: 12px 20px;
              label {
                top: 11px;
              }
            }
          }
        }
      }
    }

    .menu-dropdown,
    .menu input[type="checkbox"] {
      // hide defaults
      @include hide;
    }

    .menu label:hover {
      cursor: pointer;
    }

    @include mq(medium) {
      .menu > label,
      input[type="checkbox"] {
        @include hide;
      }
      .menu a {
        padding: $menu_padding;
      }
      .menu > ul > li {
        display: inline-block;
      }
      .menu-hasdropdown {
        &.open {
          > ul {
            @include show;
          }
        }
        &.menu-hasflyout {
          .menu-dropdown {
            border-left: 1px solid site("border-color");
            height: 1140px;
            z-index: 2;
          }
        }
        &:not(.menu-hasflyout) {
          position: relative;

          > .menu-dropdown {
            width: 100%;
            box-shadow: 1px 4px 9px color($grey, 500);
            border: 1px solid site("border-color");
            max-height: calc(100vh - 50px);
            min-height: 50vh;
            overflow: auto;
          }
        }
      }
      .menu-hasdropdown > ul {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;

        li {
          width: 230px;
        }
      }

      .menu-hasflyout {
        position: initial !important;
      }

      .menu-hasflyout > ul {
        left: 230px;
        top: 0;
      }
      .menu-hasflyout .menu-downicon {
        @include hide;
      }
    }

    @keyframes grow {
      0% {
        display: none;
        opacity: 0;
      }
      50% {
        display: block;
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    .menu-dropdown {
      li {
        a {
          padding: $menu_padding;
          border-bottom: $menu_border;
        }
        &:hover {
          > a {
            background: darken(site("bg-color"), 5%);
          }
        }
      }
    }

    @include mq(medium, max-width) {
      .main-menu {
        border-bottom: 1px solid site("border-color");
        overflow-x: scroll;
        > li {
          > a {
            border-top: 1px solid site("border-color");
            border-right: 1px solid site("border-color");
          }
        }
        .menu-dropdown {
          > li {
            a {
              border-top: 1px solid site("border-color");
            }
          }
        }
      }
      .menu > label {
        display: block;
        padding: 16px;
        border-left: 1px solid site("border-color");
        border-right: 1px solid site("border-color");
        text-align: right;
        font-size: 1.3em;
        @include mq(tiny, max-width) {
          padding: 16px 12px;
        }
      }
      .menu a {
        padding: $menu_padding;
      }
      .menu > ul a label {
        right: 5px;
        top: 11px;
        > span {
          .svg-inline--fa {
            margin: 0;
            color: #fff;
          }
          float: right;
          padding: $menu_icon_padding;
          border: 1px solid darken(site("color"), 10%);
          background: site("color");
          border-radius: site("br");
        }
      }
      .menu-dropdown a {
        background: site("bg-color") !important;
      }
      .menu-hasflyout > ul a {
        background: darken(site("bg-color"), 5%) !important;
      }

      .menu > ul,
      .menu-righticon {
        @include hide;
      }
      input[type="checkbox"]:checked + ul {
        @include show;
      }
    }
  }

  &__menu-search {
    @include mq(medium, max-width) {
      display: none;
    }
    > div {
      padding-left: 15px;
      margin-top: 3rem;
    }
    border-left: 1px solid site("border-color");
    @include position(absolute, 0, null, 0, 230px);
    display: flex;
    width: 230px !important;
    background: #fff;
    h3 {
      color: site("text-color");
    }

    .icon-only {
      &:hover {
        .svg-inline--fa {
          color: color($dokiv-red);
        }
      }
      @include position(absolute, 0, 0, null, null);
      padding: 7px;
    }
  }

  &__search-results {
    overflow: auto;
    max-height: calc(100% - 5rem);
    z-index: 1;
    > div > a {
      &:hover {
        background: site("bg-color") !important;
      }
      padding-left: 10px !important;
    }
  }
}
