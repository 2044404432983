// ==============
//  PUBLICATIONS
// ==============

/*
  [1] Disallow multiple rows because it breaks the grid.

  [2] Since there's no property for "box-shadow-color", we make
      use of the default behaviour of box-shadow inheriting their
      element's color. This sets the glow to the default blue,
      then we override the color inline per item to change the
      color of the glow.

  [3] Use "--slim" for publication lists that should have one less
      column, such as the Children-tab. We must currently override
      all media queries set by the regular list. Should separate
      them better at some point.

  [6] !important to prevent :hover from overriding styles.

  [7] The meta row can contain tooltips which shouldn't be
      affected by the opacity. We cannot use rgba as color because
      the color is set dynamically.
*/

// local variables:
// ----------------

$block: card-list;

$glow-color: lighten(site("color"), 20%);
$priority-circle: 13px;
$inset-box-shadow: inset -1px -1px 0 rgba(#000, 0.1);
$address-book-color: #b5965e;
$reminder-box-color: #27c2eb;

%card-styles {
  @extend %transition--paint;
  margin-bottom: 1em;
  background: site("color");
  border-radius: site("br");
  white-space: nowrap; /* [1] */

  color: site("color"); /* [2a] */
  box-shadow: $inset-box-shadow;

  &:hover {
    box-shadow: $inset-box-shadow, 0 0 1em; /* [2b] */
  }

  &:active {
    transition: none;
    box-shadow: $inset-box-shadow, 0 0 gr(-2);
  }

  @include mq(tiny, max-width) {
    font-size: 0.9em;
    margin-bottom: gr(-1);
  }

  .#{$block}__link {
    display: block;
    color: #fff;
    padding: 1em;
    min-height: 8.5em;
    &.isDisabled {
      cursor: not-allowed;
      opacity: 0.3;
      pointer-events: none;

    }

    &:active {
      opacity: 0.5;
    }

    .#{$block}__list--low & {
      min-height: 0;
      height: 5.1em;
    }

    &.#{$block}__link--centered {
      display: flex;
      align-items: center;

      .#{$block}__name {
        flex-grow: 1;
      }
    }
  }
}

%card-list__label {
  display: inline-block;
  font-size: 0.9em;
  opacity: 0.5;
}

// ----------------

.#{$block} {
  &__item {
    @extend %card-styles;

    @include mq(tiny, to, small) {
      @include columns(2, 0.4);
    }
    @include mq(small, to, medium) {
      @include columns(3, 0.25);
    }
    @include mq(medium) {
      @include columns(4);
    }

    .#{$block}__list--slim & {
      /* [3] */
      @include mq(tiny, to, small) {
        @include columns(2, 0.4);
      }
      @include mq(small) {
        @include columns(3);
      }
      @include mq(medium) {
        @include columns(3);
      }
    }

    &--styles {
      @extend %card-styles;
    }

    &--object {
      @include mq(small, max-width) {
        text-align: center;

        .fa {
          margin: 0;
        }
      }
    }

    &.upload {
      &--locked {
        cursor: not-allowed;
        box-shadow: 0 0 1em color($dokiv-red) !important;
        background: color($dokiv-red) !important;
      }

      &--possible {
        cursor: copy;
        box-shadow: 0 0 1em color($dokiv-green) !important;
        background: color($dokiv-green) !important;
      }

      &--forbidden {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__list {
    .list-icon {
      @include mq(tiny, to, small) {
        float: none;
      }
    }
  }

  &__user {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inherit;
    background: none;
    &:hover {
      box-shadow: inset 0px 0px 0 rgba(0, 0, 0, 0.1), 0 0 1em rgba(0, 0, 0, 0.5);
      border: 1px solid #fff;
    }
    .select__item {
      padding: 0;
      border: 0;
      &:hover {
        background: none;
      }
    }
    .card-list__link {
      @include mq(tiny, width) {
        min-height: 13em;
      }
    }
  }

  &__column-avatar {
    width: 30%;
    float: left;
  }

  &__column {
    width: 65%;
    float: right;
    margin-left: 5%;
    * {
      color: #424242;
    }
    p {
      margin-top: 0.5em;
    }
  }

  &__link {
    &--company-page {
      border-radius: site("br");
      box-shadow: inset 0 0 0 2px site("color"), inset 0 0 0 4px #fff;
    }
  }

  &__address-book {
    color: $address-book-color;
    background: $address-book-color;

    .#{$block}__link {
      border-radius: site("br");
      box-shadow: inset 0 0 0 2px $address-book-color, inset 0 0 0 4px #fff;
    }
  }

  &__reminder-box {
    color: $reminder-box-color;
    background: $reminder-box-color;

    .#{$block}__link {
      border-radius: site("br");
      box-shadow: inset 0 0 0 2px $reminder-box-color, inset 0 0 0 4px #fff;
    }
  }

  &__create {
    background: color($dokiv-green);
    color: color($dokiv-green);

    .#{$block}__label {
      margin: gr(-1) 0 0;
    }
  }

  &__star {
    padding: gr(-1, 1rem);
    font-size: 1.25em;
    text-shadow: 1px 1px 0 rgba(#fff, 0.2);
    z-index: 1;
    color: site("text-color");

    @include position(absolute, 0, 0, null, null);

    &--starred {
      color: color($yellow, 500) !important; /* [6] */
      text-shadow: 1px 1px 0 rgba(#000, 0.2);
    }

    &:hover {
      color: color($grey, 100);
      text-shadow: 1px 1px 0 rgba(#000, 0.2);
    }

    &:active {
      color: color($yellow, 500);
      text-shadow: 0 0 1em color($yellow, 500);
    }
  }

  &__add {
    padding: gr(-3) gr(-2);
    font-size: 2em;
    text-shadow: 1px 1px 0 rgba(#fff, 0.2);
    z-index: 1;
    color: #fff;
    @include position(absolute, 0, 0, null, null);
  }

  &__meta {
    font-size: 0.95em;

    > div > i,
    > div > span,
    > span {
      /* [7] */
      opacity: gr(-1, null);
    }
  }

  &__meta-block {
    float: left;
    margin-right: 0.5em;

    > .fa {
      margin: 0;

      &-unlock-alt {
        top: 1px;
      }
    }

    > span {
      margin-left: gr(-3);
    }
  }

  &__category {
    background: rgba(#000, 0.1);
    padding: gr(-1);
    font-size: 0.9em;
    @include position(absolute, null, 0, 0);

    @include mq(large) {
      font-size: 0.95em;
    }
  }

  &__priority {
    display: block;
    float: left;
    margin-right: gr(-1);

    width: $priority-circle;
    height: $priority-circle;
    border-radius: site("br");
    background: site("color");

    &.ng-hide {
      display: none !important;
    }
  }

  &__body {
    margin-top: gr(-1);

    p {
      @extend %ellipsis;
      margin: 0;
      line-height: 1;
    }
  }

  &__name {
    @extend %ellipsis;
    font-size: 1.4em;
    font-weight: header("regular");

    .fa {
      margin-right: gr(-1);
    }
  }

  &__email {
    @extend %ellipsis;
  }

  &__split-col {
    @include span(3 of 12 0.3);

    & + & {
      @include span(9 of 12 0.3 last);
    }
  }

  &__dashboard {
    .#{$block}__item {
      @include mq(medium) {
        @include columns(3, 0.45);
      }
    }

    .#{$block}__item,
    .#{$block}__item--styles {
      margin-bottom: gr(-1);

      @include mq(small) {
        margin-bottom: gr(1);
      }
    }
  }

  &__quicklink {
    @extend %card-list__label;
    font-size: 0.8em;
    color: rgba(#fff, 0.75);
    padding: 0.5em 1em 0.6em;
    background: darken(site("color"), 1%);
    border-top: 1px solid darken(site("color"), 10%);
    opacity: 1;
    border-radius: 0 0 site("br") site("br");

    @include position(absolute, null, 0, 0, 0);

    &:hover {
      background: darken(site("color"), 10%);
    }
  }

  &__label {
    @extend %card-list__label;
    margin-bottom: gr(-2);
  }

  &__form-actived {
    width: 48px;
    @include position(absolute, 0, 40px, null, null);
    padding: 0.3em;
    background: color($dokiv-orange);
    white-space: normal;
    font-size: 0.7em;
    color: #fff;
  }
}

.publication-header {
  &__column {
    @include mq(small) {
      & {
        @include span(4 of 12);
      }
      & + & {
        @include span(8 of 12 last);
      }
    }
  }

  &__filter {
     @include mq(small, max-width) {
        margin-top: 1rem;
     }
    input[type=text] {
      @include mq(small) {
        min-width: 20rem;
      }
    }
  }

  &__draft-column {
    @include mq(small) {
      & {
        @include span(8 of 12);
      }
      & + & {
        @include span(4 of 12 last);
      }
    }
  }
  &__delete-all {
    padding: gr(-1);
    @include mq(small) {
      @include position(absolute, 0, 0, null, null);
    }
    @include mq(small, max-width) {
      margin-top: gr(-1);
    }
  }
  &__drafts {
    background: #f4be00;
    padding: gr(-1);
    h1 {
      @include mq(small) {
        line-height: 28px;
      }
    }
  }
  &__draft-msg {
    font-size: 0.9rem;
    font-weight: 700;
  }
  &__notices {
    background: #e4a444;
    padding: gr(-1) 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0 auto;
    p {
      display: none;
      margin: 0;
      color: #fff;
      &.current {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        transform: translateX(100%);
        animation: left-one 15s ease infinite;
      }
    }
  }

  &__filter-archived {
    min-width: 20em;
  }

  &__option {
    margin-top: 1em;
    @include mq(tiny) {
      margin-top: 0;
      float: right;
      padding-left: 1em;
    }
  }
}

.fa-blog {
  content: url("../../../../images/blog-icon.png");
  width: 18px;
}

.fa-investigation {
  content: url("../../../../images/investigation-icon.png");
  width: 16px;
}
