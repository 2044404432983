// ==========
//  WATCHERS
// ==========

/*
  [1] Ideally this should be half the size actual image.

  [2] Allow the popover to escape its container and fill the
      entire section.
*/


// local variables:
// ----------------

  $block: watchers;
  $avatar-width: 29px; /* [1] */

// ----------------


.#{$block} {
  &__list {
    min-height: $avatar-width * 2;

    &--embed {
      @extend %skin;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding: gr(-1);
      top: -1px;

      h5 {
        font-weight: header("regular");
        margin-bottom: gr(-2);
        color: color($grey, 600);
      }
    }
  }

  &__person {
    float: left;
    margin: 0 gr(-2) gr(-2) 0;
    position: static; /* [2] */
  }

  &__picture {
    border-radius: site("br");
    width: $avatar-width;

    &--blank {
      display: block;
      border: 1px solid site("border-color-dark");

      &::before {
        display: block;
        content: "";
        padding-bottom: 100%;
      }

      > span {
        font-size: .85em;
        text-align: center;
        margin-top: -.5em;
        @include position(absolute, ($avatar-width / 2), 0, 0);
      }
    }
  }

  &__button {
    .#{$block}__picture {
      cursor: pointer;
    }

    .user-list__type {
      @include position(absolute, null, null, 0, 0);
      padding: gr(-2);
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
