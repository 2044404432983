// ===============
//  GLOBAL LAYOUT
// ===============

/*
  [1] This is possibly dangerous to be dependent on, but it's
      required for us to be able to animate items in the content
      flow in and out of the screen without causing scroll bars.

  [2] Is it ok to use this element for global main padding?

  [3] Kind of following the same as #1 for a global view header. We
      should do this in a prettier way.
*/

body {
  > [ui-view] {
    overflow: hidden; /* [1] */
    min-height: 100vh;
  }
}

.main-view {
  padding-bottom: gr(1);
}

.content-holder { /* [2] */
  max-width: 1270px;
  margin: 0 auto;
  padding: gr(1) 1em 0;

  &__cover {
    z-index: z("high");
    cursor: pointer;
    @include position(fixed, 0, 0, 0, 0);
  }

  &__chart {
    max-width: 1280px;
    margin: 0 auto;
    padding: gr(1) 1em 0;
    @include mq(medium, max-width) {
      .publication-edit__wrap {
        margin-top: 1rem;
        .button {
          display: inline-block;
          min-width: 8rem;
          text-align: center;
        }
      }
    }
  }
}

.container {
  &--slim {
    max-width: site("width--slim");
  }

  &--stretch {
    @include mq(tiny, max-width) {
      @include stretch(site("default-margins"));

      &, & > .skin {
        border-radius: 0;
        @include border(0, left right);
      }
    }
  }
}

.content { /* [3] */
  &__header {
    margin-bottom: site("default-margins");

    &.dashboard-header {
      padding: 0 gr(0.5);
    }

    p {
      margin-top: gr(-2);
      line-height: 1;
    }

    &--themed {
      padding: 1em;
      text-align: center;
      border-bottom: 1px solid site("border-color");
      margin-bottom: 0;
    }
  }
}
