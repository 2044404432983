.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.modal-backdrop {
  z-index: z("high") + 1 !important;
}
.modal {
  overflow: hidden;
  display: none;
  @include position(fixed, 0, 0, 0, 0);
  z-index: z("high") + 2 !important;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  .modal-dialog {
    width: 50em;
    margin: 0 auto;
    max-width: 100%;

    @include mq(tiny) {
      padding: 70px 0;
    }
  }
  &.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -35%);
  }
  &.show .modal-dialog {
    transform: translate(0, 0);
  }
}

// Actual modal
.modal-content {
  background-color: site("bg-color");
  background-clip: padding-box;
  padding: 1em;
  color: color($grey, 800);
  border-radius: site("br");

  @include mq(tiny, max-width) {
    height: 100%;
  }
}
.fade.show {
  opacity: 1;
}
.fade {
  opacity: 0;
  transition: opacity .15s linear;
}
// Modal background
.modal-backdrop {
  @include position(fixed, 0, 0, 0, 0);
  background-color: #000;
  z-index: 1040;

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: .5;
  }
}
.modal-body {
  position: relative;
}
@include mq(tiny) {
  .modal-dialog {
    width: 40em;
  }
}

@include mq(tiny, max-width) {
  .modal-dialog {
    width: 100%;
  }
  .modal {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    display: block !important;
  }
}
