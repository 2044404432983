// ===================
//  CHARACTER-COUNTER
// ===================


// local variables:
// ----------------

  $block: character-counter;

// ----------------


.#{$block} {
  font-size: .8em;
  color: site("text-color-light");
  padding: gr(-2);
  background: #fff;
  @include border(1px solid site("border-color"), bottom right left);
  @include position(absolute, 100%, 0, null, null);

  &--warning {
    color: color($dokiv-orange);
  }

  &--full {
    font-weight: 700;
    color: color($dokiv-red);
  }
}
