// =======
//  LOGIN
// =======

/*
  [1] Place body above aside so that aside disappears behind it
      on smaller screens.

  [2] Remove margin-left set globally to side-by-side buttons.

  [3] This layout is kind of weird but did it because reasons.
      We'll keep it until we decide how the login aside should
      behave.
*/

$login-width:      46em;
$login-background: color($cold-grey, 600);

.login {
  &__body {
    padding-top: 0;
    @include mq(small) {
      background: $login-background;
    }
    overflow: auto;
    display: flex;
    flex-direction: column;
    .main-view {
      padding: 0;
      height: 100%;
    }
    > #root {
      > div {
        width: 100%;
      }
      
      display: flex;
      flex-grow: 1;

      @include mq(small) {
        align-items: center;
      }
    }
  }

  &__wrap {
    overflow: hidden;
    height: 100%;
    @include mq(small, max-width) {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    @include mq(small) {
      width: 100%;
      max-width: $login-width;
      margin: 0 auto;
      box-shadow: site("box-shadow");
    }
  }

  &__primary {
    @include mq(small, min-width) {
      align-self: center;
    }
    flex-grow: 1;
    max-width: 20em;
    margin: 0 auto;
  }

  &__banner {
    background: site("color");
    @include mq(small) {
      display: flex;
    }
  }

  &__main {
    z-index: 1; /* [1] */
    background: #fff;
    padding: gr(1);
    display: flex;

    @include mq(small, max-width) {
      flex-grow: 1;
    }

    @include mq(small) {
      min-height: 33em;
      padding: gr(2);
    }

    .flag__wrap {
      display: block;
      padding: gr(-1);
      color: site("text-color-light");
      text-align: center;
      margin-top: 3em;
      @include mq(small) {
        margin-top: 6em;
      }
      a:hover {
        color: site("text-color");
      }
      .flag__item {
        float: none;
        display: inline-block;
      }
    }
  }

  &__aside {
    background: #ccc;

    @include mq(small, max-width) {
      display: none;
    }

    @include mq(small) {
      width: 20em;
      @include position(absolute, 0, 0, 0, null);
    }
  }

  &__image {
    float: right;
    min-height: 100%;
    min-width:  100%;
    max-width:  none;
  }


  &__header {
    text-align: center;
    font-size: 1rem;
    font-weight: header("bold");
    margin: gr(1) 0;
    @include mq(small, max-width) {
      margin: gr(1) 0;
    }
  }

  &__logo {
    display: block;
    margin: 1em 1.2em;
    text-align: center;
    @include mq(small) {
      float: left;
    }

    img {
      max-width: 150px;
    }
  }
  &__menu {
    @include mq(small) {
      float: left;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding-right: 2em;
    }
    ul {
      width: 100%;
      text-align: right;
      @include mq(small, max-width) {
        text-align: center;
      }
      li {
        display: inline-block;
        &:hover,
        &.active {
          a {
            text-decoration: underline;
          }
        }
        a {
          padding: gr(0) 8px gr(0) 0;
          display: inline-block;
          color: #FFF;
          font-size: 13px;
          @include mq(tiny, max-width) {
            font-size: 12px;
            padding-right: 4px;
          }
        }
        & + li {
          &:before {
            content: "|";
            padding-right: 8px;
            color: #FFF;
            @include mq(tiny, max-width) {
              padding-right: 4px;
            }
          }
        }
      }
    }
  }

  &__form {
    select,
    input:not([type="radio"]) {
      width: 100%;
      text-align: center;
    }
  }

  &__footer {
    p {
      @include vertical-margins(gr(-2));
    }
  }

  &__button {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: gr(-1) gr(1);
  }

  &__verifySMS {
    @include mq(tiny, min-width) {
      padding-top: 5em;
    }
    margin: 0 auto;
    max-width: 27em;
  }

  &__input-code {
    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important; 
    text-align: left !important;
    &:focus {
      border-color: #3f82f7 !important;
    }
  }

  &__icon-password {
    @include position(absolute, 0, 0, null, null);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
    padding: 0.48805em !important;
    .fa {
      top: 0px;
    }
  }
}
