// ========
//  SELECT
// ========

/*
  [1] Can't use padding for <select>, so setting a height will
      have to be sufficient.

  [*] This currently looks aweful in Firefox (and probably other
      browers as well) since the select arrow – among other GUI
      elements – aren't stylable. There are a few hacks around,
      but I'm not sure what we want to do right now so I'm
      leaving it as is.
*/

select {
  @extend %reset-input;
  @extend %skin;

  height: 2.35em; /* [1] */
}
