// ==============
//  DOCUMENT NAV
// ==============

/*
  [1] Since the list also float themselves, we can't clear with
      the clear attribute.

  [2] We push the list above its parent's border-bottom, so that
      when one of its list items is the current view its tab can
      melt into the application background. Note that we  also
      move the wrapper back up one pixel to close the gap from the
      header.

  [3] Separated the links into two different lists to allow them
      to be styled separately at some point in the future.

  [4] Since the primary buttons have another color, the last
      element's right border looks awful when it's active. Fix.

  [5] For centering purposes.
*/


// local variables:
// ----------------

$button-padding:           .75em;
$button-border:            1px solid site("border-color");

$button-text-color:        site("text-color-light");
$button-text-color--hover: site("text-color");

$button-color--hover:      #fff;
$button-color--active:     color($grey, 100);

$favorite-width:           18em;

%document-nav-button {
  display:       block;
  padding:       $button-padding;
  border-right:  $button-border;
  color:         $button-text-color;
  border-bottom: 1px solid site("border-color");

  &:active {
    &:not(.active) {
      color: rgba(site("text-color"), .5);
      background: $button-color--active;
    }
  }
}

%document-nav-button--focus {
  background: $button-color--hover;
  color:      $button-text-color--hover;
}

// ----------------


.document-nav {
  &__wrap {
    @extend %flex;
    background: #fff;
    border-bottom: $button-border;
    top: -1px;
  }

  &__list {
    @extend %flex;
    //overflow: hidden; /* [1] */
    top: 1px; /* [2] */
    float: left;

    & > li {
      float: left;
    }

    a {
      @extend %flex-grow-1;
      @extend %document-nav-button;

      &:hover,
      &.active {
        @extend %document-nav-button--focus;
      }

      &.active {
        cursor: default;
        border-bottom-color: $button-color--hover;
      }
    }
  }

  &__primary { /* [3] */
    @extend %transition;
    @extend %flex;
    @extend %flex-shrink-0;
    width: site("logo-width--small");

    @include mq(medium) {
      width: site("sidebar-width");
    }

    li {
      @extend %flex-grow-1;

      &:last-child a { /* [4] */
        &.active {
          border-right: $button-border;
        }
      }
    }

    a {
      display: block;
      width: 100%;
      color: #fff;
      background:   darken(site("color"), 5%);
      border-color: darken(site("color"), 10%);
      white-space:  nowrap;
      text-align:   center;

      &:not(.active) {
        &:hover {
          background: site("color");
          color: #fff;
        }

        &:active {
          color: rgba(#fff, .5);
          background: darken(site("color"), 10%);
        }
      }

      > span {
        @extend %hide-below-medium;
      }

      .fa {
        margin-right: gr(-2);

        @include mq(medium, max-width) { margin: 0; }
      }
    }

    &--guest {
      li {
        width: 100%;

        @include mq(medium) {
          width: 49.9%;
        }
      }
    }
  }

  &__favorites {
    @extend %flex-grow-1;
    @extend %hide-below-medium;

    a {
      @extend %ellipsis;
      max-width: $favorite-width;
      top: 2px;
    }

    li.has-submenu {
      > a > i {
        margin-right: 0;
      }
      &:hover {
        .dropdown-content {
          opacity: 1;
          visibility:visible;
          li {
            &:hover {
              a {
                background: site("color");
                color: #FFF;
              }
            }
          }
        }
      }
      .dropdown-content {
        > li {
          min-width: 12em;
        }
      }
    }
  }

  &__create {
    @extend %flex;
    @extend %flex-direction-row-reverse;
    @extend %flex-shrink-0;
    top: 1px;

    .no-flexbox & {
      @include position(absolute, 0, 0, -1px, null);
    }

    a {
      @extend %document-nav-button;
      border-right: 0;
      border-left:  1px solid;
      border-color: color($dokiv-green);
      background:   color($dokiv-green);
      color: #fff;

      .fa {
        display: inline; /* [5] */

        @include mq(tiny, max-width) {
          margin: 0;
        }
      }

      &:hover {
        @extend %document-nav-button--focus;
        border-color: site("border-color");
      }
    }
  }

  &__additional {
    @extend %flex-shrink-0;
    @extend %justify-content-flex-end;
    margin-left: -1px;

    @include mq(medium, max-width) {
      flex-grow: 1;
    }

    a {
      @extend %document-nav-button;
      border-left: 1px solid site("border-color");
      border-right: 0;

      &.active {
        cursor: default;
        border-bottom-color: $button-color--hover;
      }

      @include mq(tiny, max-width) {
        .fa {
          margin: 0;
        }
      }

      > span {
        @extend %hide-below-tiny;
      }
    }

    .button--positive, .button--positive:hover {
      color: #fff;
    }
    .button--positive:hover {
      background: #27b96b;
      border-color: #27b96b;
    }
  }

  &__dropdown-content {
    opacity: 0;
    visibility:hidden;
    @include position(absolute, 100%, null, 0, null);
    z-index: z("low");
    transition: all .3s ease;
    li {
      background: #FFF;
      border-left: 1px solid site("border-color");
    }

  }
}
