// ===========
//  TAG CLOUD
// ===========

/*
  [1] Temporary solution. We use two different sets of tag
      functions. Since we're going to remove one of them
      eventually I can't be bothered with making a new template.

  [2] Disallow multi-row tag labels. Overflow ellipsis instead.
*/


// local variables:
// ----------------

  $block: tag;

  $tag-color: site("color");
  $tag-color--dark:   darken($tag-color, 2.5%);
  $tag-color--light: lighten($tag-color, 2.5%);

  $tag-padding: gr(-1);
  $tag-gutter:  gr(-2);

  %remove-button {
    display: block;
    padding: $tag-padding;
    z-index: 1;
    box-shadow: -1px 0 0 $tag-color--dark;
    background: $tag-color--light;
    border-radius: 0 site("br") site("br") 0;
    @include position(absolute, 0, 0, 0, null);

    &:active {
      background: $tag-color--dark;
    }
  }

// ----------------


.#{$block} { /* [1] */
  &-item,
  &__item {
    @extend %skin;
    background: $tag-color;
    display: inline-block;
    margin: 0 $tag-gutter $tag-gutter 0;
    border: 0;
    max-width: 100%;
    color: #fff;
    font-size: .95em;

    &:active {
      user-select: none;
    }

    span {
      @extend %ellipsis; /* [2] */
      display: block;
      padding: $tag-padding;
    }

    &.tag-grey {
      background: #cdcdcd;
    }
    &.tag-white {
      background: #fff;
      border: 1px solid color($grey, 400);
    }
    &.tag-grey, &.tag-white {
      color: #000;
      padding-right: 1em;
      padding-left: 1em;
    }
  }

  &__remove {
    @extend %remove-button;

    .#{$block}__item:not(:hover) & {
      opacity: 0;
    }
  }
}

.remove-button {
  @extend %remove-button;

  .#{$block}-item:not(:hover) & {
    opacity: 0;
  }
}
