// =================
//  COMPANY ACCOUNT
// =================

/*
  [1] Logotypes are kind of sensitive, so we center it instead of
      upscaling it.
*/


// local variables:
// ----------------

$column-gutter: 1;
$logo-width: 250px;

// ----------------


.company-account {
  &__column {
    @include mq(tiny, max-width) {
      & + & { margin-top: gr(1); }
    }

    @include mq(tiny, to, small) {
      &     { @include span(8 of 12 $column-gutter last); }
      & + & { @include span(4 of 12 $column-gutter); }
    }

    @include mq(small) {
      &     { @include span(9 of 12 $column-gutter last); }
      & + & { @include span(3 of 12 $column-gutter); }
    }
  }

  &__wrapper {
    @include mq(small) {
      display: flex;
      width: 100%;
    }
  }

  &__cell {
    &.close {
      @include mq(small, max-width) {
        display: none !important;
      }
    }
    &.open {
      display: block;
      animation: fallDown  transition("duration") transition("easing");
    }

    & {
      @include mq(small) {
        margin-left: 1.5em;
        flex: 1;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    & + & {
      @include mq(small, max-width) {
        margin-top: gr(1);
      }
      @include mq(medium) {
        flex: 0 0 77%;
      }
      @include mq(small) {
        flex: 0 0 70%;
      }
    }
  }

  &__content-wrapper {
    @extend %skin;
    padding: 1em;
    width: 100%;
  }

  &__moving-top {
    @include mq(small) {
      margin-top: -3em;
    }
  }

  &__sidebar-wrapper {
    @extend %skin;
    .button__bar--back {
      margin-bottom: 1em;
    }
  }

  &__sidebar {
    font-size: 1.2em;
    ul.title {
      h3 {
        font-weight: 400;
      }
      padding: gr(-2) gr(0) !important;
    }
    ul:not(:empty) {
      padding: site("default-margins");
      li {
        padding: 0.3em 0;
        &:hover,
        &:active,
        &:focus,
        &.active {
          a {
            display: block;
            text-decoration: underline;
          }
        }
      }
      li {
        a {
          &.active {
            text-decoration: underline;
          }
        }
      }
      & + ul {
        border-top: 1px solid site("border-color");
      }
    }
  }

  &__logo {
    text-align: center; /* [1] */

    img {
      width: 100%;
      max-width: $logo-width;
    }
  }

  &__billing {
    @extend %separator;

    @include mq(small, max-width) {
      .button {
        .fa  { margin: 0; }
        span { display: none; }
      }
    }
  }

  &__toggle-menu {
    @include position(absolute, -8px, 0, null, null);
    padding: .6em .4em;
    border: 1px solid site("border-color");
  }

  &__filter {
    @include mq(small) {
      width: 50%;
    }
    width: 100%;
    display: flex;
    margin-bottom: 1em;
    > div {
      flex-grow: 1;
    }
  }
  &__new-checklist {
    @include position(absolute, 0, 0, 0, null);
    .checklist__form-wrapper {
      @include position(absolute, 100%, 0, null, initial);
    }
  }

  &__checklist-note {
    padding-top: 0.6em;
  }

  &__title {
    margin-bottom: 0.6em;
  }

  &__text {
    color: color($grey, 800);
    padding-bottom: 0.38198em;
    line-height: 1.45;
    margin: 0;
  }
}
