// =======
//  RADIO
// =======

/*
  [*] Markup structure must be as follows:
      .radio__wrap>input.radio__input+label.radio__label

  [1] We hide the regular radio input by moving it outside of
      its wrapper.

  [2] Move it!

  [3] Remove any margin from labels within forms.

  [4] Active class to allow use of styling on other types of
      elements.

  [5] Cannot change color directly since the box-shadow inherits
      its color from the regular color.
*/

// local variables:
// ----------------

$radio-colors: (/* [1] */ "blue": site("color"), "green": color($dokiv-green));

$block: radio;
$radio-length: 15px;
$polar-length: gr(2);

// ----------------

.#{$block} {
  &__wrap {
    overflow: hidden; /* [1] */
    @include vertical-margins(gr(-2));
    line-height: 1.4;

    &.line-component {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__item {
    position: static;
    @include vertical-margins(gr(-2));
  }

  &__input {
    margin-right: 1em;
    @include position(absolute, null, 100%, null, null); /* [2] */
  }

  &__label {
    margin: 0; /* [3] */
    display: inline-block;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      float: left;

      cursor: pointer;
      background: #fff;
      border-radius: 50%;
      border: 1px solid site("border-color-dark");

      width: $radio-length;
      height: $radio-length;
      margin-right: gr(-2);
    }

    &:active {
      &::before {
        background: color($grey, 100);
      }
    }

    .#{$block}__input:checked + &,
    &.active {
      &::before {
        /* [4] */
        background: site("text-color");
        border-color: site("text-color");
      }

      @each $color, $value in $radio-colors {
        &.#{$block}__label--#{$color}::before {
          background: $value;
          border-color: $value;
        }
      }
    }

    .#{$block}__input[disabled] + & {
      opacity: 0.5;

      &::before {
        cursor: not-allowed;
      }
    }
  }

  &__polar {
    @extend %transition--paint;
    transition-duration: transition("short");
    width: $polar-length;
    height: $polar-length;
    text-align: center;
    font-size: 1.4em;
    box-shadow: inset 0 0 0 1px;
    border-radius: 50%;
    margin-right: gr(-1);
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }

    .#{$block}__input[disabled] + & {
      cursor: not-allowed;
    }

    .#{$block}__input:not([disabled]) + & {
      &:hover {
        box-shadow: inset 0 0 0 5px;
        will-change: box-shadow;
      }
    }

    .#{$block}__input:checked + & {
      transition-duration: transition("long");
      box-shadow: inset 0 0 0 ($polar-length * 0.51) !important;

      &::before {
        /* [5] */
        @extend %transition--paint;
        color: #fff;
        border: solid #fff;
      }

      &--yes {
        &::before {
          border: solid #fff;
          border-width: 0 5px 5px 0;
        }
      }

      &--no {
        &::before,
        &::after {
          border: solid #fff;
        }
      }
    }

    &::before {
      @include position(absolute, 50%, 0, null, 20px);
    }

    &--yes {
      color: color($dokiv-green);
      &::before {
        width: 12px;
        height: 20px;
        border: solid color($dokiv-green);
        border-width: 0 5px 5px 0;
        transform: rotate(45deg);
        display: inline-block;
        margin-top: -11px;
        content: "";
      }
    }

    &--no {
      color: color($dokiv-red);
      &:before,
      &:after {
        position: absolute;
        content: " ";
        background-color: color($dokiv-red);
        border: solid color($dokiv-red);
        left: 23px;
        top: 13px;
        height: 25px;
        width: 5px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
