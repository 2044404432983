// ================
//  INLINE ACTIONS
// ================

/*
  [*] This is supposed to wrap things that places an action button
      (such as edit) next to a header, text block etc.
*/


// local variables:
// ----------------

  $block: inline-actions;

// ----------------


.#{$block} {
  &__wrap {
    padding-right: gr(1);
  }

  &__button {
    display: block;
    padding: .1em gr(-2);
    @include position(absolute, 0, 0, null, null);

    &:not(:hover) {
      opacity: gr(-1, null);
    }

    .fa {
      margin: 0;
    }
  }
}
