// ==========
//  INFO BOX
// ==========

/*
  [*] This box is created with the infoBoxDirective. It's supposed
      to provide a base for information boxes, which should then
      be styled per context.

  [1] Since we create a new element type, give it a default
      display value.

  [2] Sometimes we may want to use the information box without a
      header.
*/


// local variables:
// ----------------

  $block:            info-box;
  $box-padding:      gr(-1);
  $box-header-bg:    site("color");
  $box-header-color: #fff;
  $box-border:       1px solid site("border-color");

// ----------------


#{$block} { /* [1] */
  display: block;
}

.#{$block} {
  &__wrap {
    font-size: .95em;

    .fa {
      margin-right: gr(-2);
    }
  }

  &__header {
    font: header("bold") 1em/1 header("family"), header("fallback");

    padding:    $box-padding;
    color:      $box-header-color;
    background: $box-header-bg;
    border:     $box-header-bg;

    margin-bottom: -1px;

    border-radius: site("br") site("br") 0 0;

    & + .#{$block}__text { /* [2] */
      border-top-left-radius:  0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }

  &__text {
    padding: $box-padding;
    border-radius: site("br");
    background: #fff;
    margin: 0;
    border: $box-border;
  }
}
