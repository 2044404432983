// ============
//  DATEPICKER
// ============


// local variables:
// ----------------

$cell-padding:      .5em;
$datepicker--width: 19em;

// ----------------


.datepicker {
  max-width: $datepicker--width;
  color: site("text-color");

  &.dropdown-menu {
    right: auto !important;
    padding: 0 0 $cell-padding;
    position: absolute;
    font-size: site("font-size");
  }

  table {
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  td,
  th {
    padding: 0;
  }

  button {
    width: 100%;
  }

  span {
    display: block;
    padding: $cell-padding;
    border-radius: site("br");


    &:not(.text-muted) {
      color: site("text-color");

      &:hover {
        background: color($grey, 200);
      }

      &.selected {
        background: site("color");
        color: #fff;
      }
    }
  }

  &__month {
    background: site("color");
    color: #fff;

    button {
      padding: gr(-1) 0;

      &:hover {
        background: lighten(site("color"), 3%);
      }

      &:active {
        background: site("color");
      }
    }
  }

  .dow {
    padding: gr(-1) 0 $cell-padding;
    color: site("text-color");
  }
}
