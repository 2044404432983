// =============
//  DESCRIPTION
// =============

/*
  [*] This document contains styles for a generic "description"
      type element, for a field with header + text that describes
      an adjacent input, or similar. Markup will probably be
      wrap>h3.header>p.body.
*/


.description {
  &__wrap {
    @extend %vertical-margins;
  }

  &__header {
    font-weight:   header("bold");
    font-size:     gr(1);
    margin-bottom: gr(-2);
  }

  &__body {
    @extend %text-block;
  }
}
