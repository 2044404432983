// ============
//  NESTED NAV
// ============

/*
  [1] Don't overwrite the local :active styles. Because DRY.

  [2] Prevent ugly user selection if user double clicks the element,
      without preventing drag-to-select.

  [3] Some icons seem to have different amounts of white space, this
      aligns them and the following elements to a straight line.

  [4] Styles for elements under the .active class.
*/


// local variables:
// ----------------

$color-border:      lighten(site("color"), 5%);
$color-bg--focus:    darken(site("color"), 10%);
$color-bg--active:   darken(site("color"), 15%);
$nested-nav-padding: 1em;

// ----------------


.nested-nav {
  &__wrap {
    ul {
      ul {
        display: none;

        a {
          background:   $color-bg--focus;
          border-color: $color-bg--active;

          &:focus,
          &:hover {
            &:not(:active) { /* [1] */
              background: lighten($color-bg--focus, 3%);
            }
          }
        }
      }
    }

    a {
      display: block;
      padding: $nested-nav-padding;
      border-top: 1px solid $color-border;
      background: site("color");

      &:focus,
      &:hover {
        background: $color-bg--focus;
      }

      &:active {
        @extend %user-select-none; /* [2] */
        background: $color-bg--active;
      }
    }


    .fa {
      width: gr(1); /* [3] */
      text-align: center;

      &.pull-right {
        margin-right: 0;
      }
    }


    .active { /* [4] */
      > ul {
        display: block;
      }

      > a {
        background: $color-bg--focus;
      }
    }
  }

  &__header {
    padding: $nested-nav-padding;
    font-weight: header("regular");
    font-size: gr(1);
  }

  &__input {
    &[type="search"] {
      background: color($grey, 200);
      color: site("text-color");
      border-radius: 0;
      padding: 1em;
      border-color: #fff;

      &:focus {
        background: #fff;
        border-color: #fff;
      }

      @include placeholder-styles {
        color: site("text-color");
      }
    }
  }
}
