// =========
//  PROFILE
// =========

/*
  [*] These are common styles for profile type pages.
*/


// local variables:
// ----------------

  $block:           column-number;

// ----------------


.#{$block} {
  &__two {
    @include mq(small, max-width) {
      & + & {
        margin-top: 1em;
      }
    }
    @include mq(small) {
      & {
        @include span(6 of 12 .25);
      }
      & + & {
        @include span(6 of 12 .25 last);
      }
    }

    &--large {
      @include mq(tiny, max-width) {
        & + & {
          margin-top: 1em;
        }
      }
      @include mq(tiny) {
        & {
          @include span(7 of 12 1);
        }
        & + & {
          @include span(5 of 12 1 last);
        }
      }
    }
  }
}
