// ===========
//  DROP ZONE
// ===========

.drop-zone {
  &__wrap {
    border: 1px dashed site("border-color");
    background: #fff;
    text-align: center;
    border-radius: site("br");
    padding: gr(1);
  }

  &__text {
    display: block;
    margin: 1em 0;
  }

  &__heading {
    line-height: site("line-height");
    font-weight: header("bold");
  }

  &--active {
    &.tab-content {
      opacity: .333;
    }
  }
}
