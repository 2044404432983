.content-holder-wrap {
    max-width: 1270px;
    margin: 0px auto;
    padding: 1.618em 1em 0px;
}
.filter-left{
  margin-bottom: 1em;
  @include mq(small) {
    display: inline-block;
  }
}
.filter-right{
  @include mq(tiny) {
    float: right;
    display: flex;
    flex-wrap: wrap
  }
}
.type-text{
  margin: 0px 5px 0px 5px;
  @include mq(small,max-width) {
    margin-bottom: 0.5em;
    margin-left: 0px;
  }
  @include mq(tiny,max-width) {
    width: 100%;
  }
}
.edit-button {
  @include mq(small, max-width) {
    margin-top: 20px;
  }
}
.table--theme{
    &.table-td tbody tr:nth-child(odd){
      td{
        background-clip: initial;
      }
    }
}
.type-text:last-child {
  margin-right: 0px;
}
.input-date{
  width: 170px;
  @include mq(tiny, max-width) {
    width: 100%;
  }
}
.type-text input{
  height: 34px;
}