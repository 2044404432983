// =========
//  POPOVER
// =========

/*
  [1] Ideally this should be half the size actual image.

  [2] Override any local styles for buttons.
*/


// local variables:
// ----------------

  $padding:        gr(-1);
  $button-width:   gr(3);
  $popover-width:  16em;
  $triangle-width: 6px;
  $avatar-width:   35px; /* [1] */

// ----------------


.popover {
  z-index: 99999;
  &__wrap {
    @extend %skin;
    background: #fff;
    word-break: break-all;
    outline: 0;
    z-index: 1;

    padding: $padding ($button-width + $padding) $padding $avatar-width;

    &--no-button {
      padding-right: $padding;
    }
  }

  &__button {
    margin: 0 !important; /* [2] */
    width: $button-width;
    border-radius: 0 site("br") site("br") 0;
    @include position(absolute, -1px, -1px, -1px, null);
  }

  &__header {
    font-weight: header("bold");
    margin-bottom: gr(-3);
  }

  &__image {
    border-radius: site("br");
    width: $avatar-width;
    @include position(absolute, $padding, null, $padding, $padding);
  }

  &__content {
    padding-left: $padding * 2;
  }
}
