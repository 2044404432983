// ==============
//  COLOR PICKER
// ==============

/*
  [1] It's a magic world. This module becomes really static.
      Perhaps we should have flex override the staticness?

  [2] Need to set input type to override global specificity.

  [3] New vertical padding to fix content alignment caused by
      monospace font-family.

  [4] Prevent suggestions from breaking into two rows on some
      screen widths.
*/


// local variables:
// ----------------

$input-height:       30px; /* [1] */
$border-light:       1px solid rgba(#fff, .1);
$border-dark:        1px solid rgba(#000, .1);
$border-dark--focus: rgba(#000, .2);
$suggestion-amount:  4;

// ----------------


.color-picker {

  &__input {
    &[type="text"] { /* [2] */
      color: site("text-color-light");
      height: $input-height;

      border-right:  0;
      border-left:   0;
      border-radius: 0;

      padding: .5em .5em .5em 3em !important; /* [3] */
      font-family: monospace;

      &:focus {
        ~ button {
          border-color: site("border-color-dark");
        }

        ~ span {
          border-color: $border-dark--focus;
        }
      }
    }
  }

  &__button {
    @extend %skin;

    height: $input-height;
    width:  $input-height;

    @include border-radius(0, top-left, bottom-left);
    @include position(absolute, 0, 0, 0, null);
  }

  &__indicator {
    @extend %transition;

    display: block;
    cursor: pointer;
    border: $border-dark;
    width: $input-height;
    border-radius: site("br") 0 0 site("br");

    @include position(absolute, 0, null, 0, 0);

    &:active {
      transition: none;
      box-shadow: inset 0 0 $input-height rgba(#000, .15);
    }
  }

  &__list {
    overflow: hidden;
    height: $input-height;
  }

  &__suggestion {
    @extend %transition;

    float:     left;
    height:    100%;
    width:     $input-height;
    max-width: 100% / $suggestion-amount; /* [4] */
    margin:    0;

    border-top:    $border-light;
    border-bottom: $border-dark;
    cursor: pointer;

    &:first-child {
      border-radius: site("br") 0 0 site("br");
      border-left:   $border-light;
    }

    &:last-child {
      border-radius: 0 site("br") site("br") 0;
      border-right:  $border-dark;
    }

    &:active {
      transition: none;
      box-shadow: inset 0 0 $input-height rgba(#000, .15);
    }
  }
}
