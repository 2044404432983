// ===========
//  VARIABLES
// ===========

$site: (
  // sizes:
  "font-size":          14px,

  "width":              1600px,
  "width--slim":        50em,
  "width--select-company": 1270px,
  "height":             960px,
  "default-margins":    1em,
  "line-height":        1.45,

  "bar-height":         50px,
  "sidebar-width":      18em,
  "logo-width--small":  150px,

  // colors:
  "color":              #196c96,
  "text-color":         color($grey, 800),
  "text-color-light":   color($grey, 500),
  "text-color-dark":    color($grey, 900),

  "border-color-light": color($grey, 200),
  "border-color":       color($grey, 300),
  "border-color-dark":  color($grey, 400),

  "bg-color":           color($grey, 100),

  // misc:
  "box-shadow":         0 0 18px rgba(#000, .25),
  "br":                 3px
); @function site($value) { @return map-get($site, $value); }



// grid:
$susy: (
  columns: 12,
  gutters: .175
);


// font variables:
$body-font: (
  "family":   "Roboto",
  "fallback": sans-serif,

  "thin":     300,
  "regular":  400,
  "bold":     700
); @function body($value) { @return map-get($body-font, $value); }


$header-font: (
  "family":   "Roboto",
  "fallback": sans-serif,

  "thin":     300,
  "regular":  400,
  "bold":     700
); @function header($value) { @return map-get($header-font, $value); }


// z-index:
$z-index: (
  "high": 900,
  "mid":  500,
  "low":  100
); @function z($value) { @return map-get($z-index, $value); }


// transitions:
$transition: (
  "duration":   .25s,
  "short":      .1s,
  "long":       .4s,
  "easing":     ease,
  "sticky":     cubic-bezier(.6, .06,  .3,  1),
  "elastic":    cubic-bezier(.15, 1.4, .2,  1),
  "elastic2":   cubic-bezier(.33, 1.3, .66, 1),
  "swift-out":  cubic-bezier(.55, 0,   .1,  1),
  "swift-out2": cubic-bezier(.55, 0,   .1,  1.3),
  "swift-in":   cubic-bezier(.1,  1,   .55, 1),
  "swift-in2":  cubic-bezier(.1,  1.3, .55, 1)
); @function transition($value) { @return map-get($transition, $value); }


//$fa-font-path: "../../fonts";
$fancybox-image-url: '../fancybox';

