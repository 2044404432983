// ===============
//  MEDIA ELEMENT
// ===============

/*
  [*] \o/
*/


// local variables:
// ----------------

  $block: media;

// ----------------


.#{$block} {
  @extend %clearfix;

  &__item {
    float: left;
    display: block;
    margin-right: 1em;

    &--omega {
      float: right;
      display: block;
      margin-left: 1em;
    }
  }

  &__body {
    overflow: hidden;
    display: block;
    padding-bottom: 1px;
  }

  &.break {
    &--small {
      @include mq(small, max-width) {
        > .#{$block}__item {
          float: none;
          margin-right: 0;

          &--omega {
            float: none;
            margin-left: 0;
          }
        }
      }
    }
  }
}
