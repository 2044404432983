// =============
//  USER AVATAR
// =============

/*
  [*] Styles for the avatar generated by UserAvatarDirective

  [1] Percentual vertical paddings are based on the element width,
      this makes the element effectively square. Must be a psuedo
      element or the height calc of the image gets screwed up.

  [2] Take image the image out of content flow to give it the
      same shape and size as its parent.

  [3] When there's no image, we set the user's initials as
      placeholder.
*/


// local variables:
// ----------------

  $block: user-avatar;

// ----------------


.#{$block} {
  overflow: hidden;
  border-radius: site("br");

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%; /* [1] */
  }

  img {
    width: 100%;
    min-height: 100%;
    @include position(absolute, 0, null, null, 0); /* [2] */
  }

  &__initials { /* [3] */
    background: linear-gradient(135deg, lighten(site("color"), 10%), site("color"));
    font-size: .9em;
    @include position(absolute, 0);

    span {
      display: block;
      color: #fff;
      text-align: center;
      margin-top: -.5em;
      @include position(absolute, 50%, 0, null);
    }
  }
}
