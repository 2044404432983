// =========
//  ACTIONS
// =========

/*
  [*] This document contains styles for the "actions" bar with
      remove/edit buttons typically found in tables.
*/

.actions {
  &__wrap {
    &, .table--theme & {
      white-space: nowrap;
      padding: 0;
    }
  }

  &__button {
    font-size: site("font-size");
    display: inline-block;
    padding: 1em .8em;
    width: gr(2);
    text-align: center;

    &.active,
    &:hover {
      color: #fff;
      background: site("color");
      border-color: transparent;
    }

    &:active {
      background: darken(site("color"), 5%);
      color: rgba(#fff, .5);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
    }

    > .fa {
      margin: 0;
    }
  }

  &__checkbox {
    margin-right: 1em;
    vertical-align: middle;
  }
}
