// ==========
//  MATH PLZ
// ==========

// power of:
@function pow($base, $exp) {
  $value: $base;

  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }

  @if $exp < 1 {
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }

  @return $value;
}

// power of golden ratio:
@function gr($exp, $unit: 1em, $split: 1) {
  $value: null;

  @if $unit != null { $value: pow(1.618, $exp) / $split * $unit; }
  @else             { $value: pow(1.618, $exp) / $split; }

  @return $value;
}

// hitta brytpunkt i root em som motsvarar $site-width:
@function siteWidth($exp: 1) {
  $normalize: ((site(font-size) / 16px) * site(width)) * $exp;

  @return $normalize;
}

// hämta färg ur färgpalett:
@function color($color, $value: 500) {
  @return map-get($color, $value);
}
