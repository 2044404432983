// ===========
//  KEYFRAMES
// ===========

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  100% { opacity: 0; }
}

@keyframes fallDown {
  0%   { opacity: 0; transform: translateY(-1em); }
  100% { opacity: 1; transform: translateY(0);    }
}

@keyframes flyUp--enter {
  0%   { opacity: 0; transform: translateY(1em); }
  100% { opacity: 1; transform: translateY(0);   }
}

@keyframes flyUp--leave {
  100% { opacity: 0; transform: translateY(-1em); }
}

@keyframes flyLeft--leave {
  100% { opacity: 0; transform: translateX(-125%); }
}

@keyframes flyLeft {
  0%   { transform: translateX(105%); }
  100% { transform: translateX(0); }
}

@keyframes flyRight {
  0%   { transform: translateX(0); }
  100% { transform: translateX(105%); }
}

@keyframes growOut {
  0%   { opacity: 1; }
  100% { opacity: 0; transform: scale(1.1); }
}

@keyframes uploadGlow {
  0%   { transform: translateX(0); }
  100% { transform: translateX(300%); }
}


@keyframes left-one {
  0% {
    transform:translateX(-100%);
  }
  10% {
    transform:translateX(0);
  }
  40% {
    transform:translateX(0);
  }
  50% {
    transform:translateX(100%);
  }
  100%{
    transform:translateX(100%);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}