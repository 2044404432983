// ============
//  TIMEPICKER
// ============


// local variables:
// ----------------

$cell-padding: .5em;
$cell-spacing: .2em;

// ----------------


.timepicker {
  color: site("text-color");

  &.dropdown-menu {
    right: auto !important;
    padding: 0;
  }

  td,
  th {
    padding: 0 $cell-spacing;

    &:first-child { padding-left:  $cell-spacing * 2; }
    &:last-child  { padding-right: $cell-spacing * 2; }
  }

  button {
    text-align: center !important;
    display: block;
    margin: 0;
    padding: $cell-padding;
  }

  thead,
  tfoot {
    button {
      &:hover {
        color: site("text-color");
      }
    }
  }

  tbody {
    button {
      color: #fff;
      background: site("color");
      border-radius: site("br");
    }
  }
}
