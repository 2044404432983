/*!
  components.css
*/

@import // =======
    //  SETUP
    // =======
    // susy:
    "setup/susy",
  // functions:
    "setup/functions",
  // color palette:
    "setup/color-palette",
  // variables:
    "setup/variables",
  // font imports:
    "typography/font-faces",
  // setup:
    "setup/mixins",
  "setup/media-queries", "setup/keyframes", "setup/normalize",
  // ================
    //  SILENT CLASSES
    // ================
    // extends:
    "silent-classes/media",
  "silent-classes/extends", "silent-classes/resets", "silent-classes/flexbox",
  // ======
    //  CORE
    // ======
    // defaults:
    "core/globals",
  "core/layout", "core/theme",
  // typograhy:
    "typography/body",
  "typography/headings",
  // inputs:
    "inputs/text",
  "inputs/search", "inputs/buttons", "inputs/select", "inputs/radio",
  "inputs/checkbox", "inputs/forms", "inputs/required", "inputs/datepicker",
  "inputs/timepicker", "inputs/filter",
  // =====
    //  APP
    // =====
    // template:
    "template/header-bar",
  "template/off-canvas-menu", "template/document-nav", "template/right-menu",
  // system components:
    "system/font-awesome",
  "system/loading", "system/autocomplete", "system/hamburger-icon",
  "system/actions", "system/dropdown", "system/popover", "system/info-box",
  "system/file-upload-progress", "system/help-block", "system/usage",
  "system/select", "system/notification-banners", "system/notifications",
  "system/inline-actions", "system/embed", "system/user-avatar",
  "system/browser-warning", "system/media-element", "system/mw-typeahead",
  "system/select-publication", "system/modal", "system/progress-bar",
  "system/color-picker",
  "system/character-counter",
  "system/alert-bar",
  // global sections:
    "sections/nested-nav",
  "sections/tables", "sections/table-popover", "sections/drop-zone",
  "sections/top-buttons", "sections/tab-content", "sections/nav-tabs",
  "sections/tag-cloud", "sections/flags", "sections/copyright",
  "sections/profile", "sections/description", "sections/index",
  "sections/watchers", "sections/pagination", "sections/active-tags",
  "sections/checklist", "sections/image-gallery", "sections/activity",
  "sections/column-number",
  "sections/publication-search",
  // =======
    //  VIEWS
    // =======
    // login:
    "views/login/login",
  // publication details:
    "views/publication/card-list",
  "views/publication/edit", "views/publication/set-parent",
  "views/publication/details", "views/publication/albums",
  "views/publication/cards", "views/publication/versions",
  "views/publication/shares", "views/publication/keypass",
  "views/publication/company-order",
  "views/publication/options",
  "views/publication/create",
  "views/publication/create-bulk",
  "views/publication/empty",
  // company:
    "views/company/company-account",
  "views/company/select-company", "views/company/notification",
  "views/company/header-company", "views/company/templates",
  "views/company/company-pages", "views/company/user-roles",
  "views/form/question", "views/form/answers", "views/form/answers-details",
  "views/form/control", "views/form/submitter-copy", "views/form/verification",
  "views/form/validation", "views/user/list",
  // Reminders:
    "views/reminders/notice-detail",
  "views/reminders/reminder-detail", "views/reminders/reminder",
  "views/reminders/list-reminder",
  // Visible comments:
    "views/visible-comments/visible-comments",
  // error:
    "views/error/404",
  // =========
    //  MODULES
    // =========
    // vendor:
    "../../../node_modules/fancybox/dist/css/jquery.fancybox.css",
  "../../../node_modules/flatpickr/dist/flatpickr.min.css",
  "../../../node_modules/gridstack/dist/gridstack.css",
  "../../../node_modules/react-datepicker/dist/react-datepicker.min.css",
  "../../../node_modules/rc-time-picker/assets/index.css",
  "../../../node_modules/react-responsive-modal/styles.css",
  "../../../node_modules/font-awesome/scss/font-awesome",
  "../../../node_modules/rc-color-picker/assets/index.css",
  "../../../node_modules/tinymce/skins/lightgray/skin.min.css",
  // ======
    //  MISC
    // ======
    // helpers:
    "core/helpers",
  // print:
    "core/print";

// FIX for react-datepicker background colour, CSS classes collide with the internal ones here:
.form__clear-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: z("low");
}

.form-processing {
  opacity: 0.4;
  pointer-events: none;
}

.datepicker__container .datepicker__month {
  background: inherit;
}

.big-calendar {
  display: block;
  height: 40em;
}

.rbc-now {
  background: #eee;
}
.rbc-event {
  background: #fefefe;
  border: 1px solid #dfdfdf;
  color: #101010;
  &.rbc-selected {
    color: #fff;
  }
}
.rbc-current-time-indicator,
.rbc-current-time-indicator::before {
  background-color: #303030;
}
.editor-toolbar {
  .separator {
    padding-top: 0;
  }
}
.addressbook {
  &__column {
    @include mq(tiny, max-width) {
      & + & {
        margin-top: gr(1);
      }
    }

    @include mq(tiny, to, small) {
      & {
        @include span(4 of 12 $column-gutter);
      }
      & + & {
        @include span(8 of 12 $column-gutter last);
      }
    }

    @include mq(small) {
      & {
        @include span(3 of 12 $column-gutter);
      }
      & + & {
        @include span(9 of 12 $column-gutter last);
      }
    }
  }
}

.flatpickr-time input.numInput {
  border: none;
}

.flatpickr-calendar .flatpickr-month {
  height: 40px;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #e0e0e0 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  background: #fff;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}

.text-list {
  li {
    padding-right: 1.5em;
    button.remove {
      @include position(absolute, 0, 0, 0, null) opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.hidden {
  display: none;
}

.sortableHelper {
  list-style: none;
}

.react-datepicker__close-icon {
  right: 35px;
}

.react-responsive-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-responsive-modal-modal {
  max-width: 951px;
  width: 100%;
  .validation__signature {
    max-width: 100% !important;
  }
}
.scroll-to-left {
  display: none;
}
.deviation-scrollbar {
  overflow-y: auto;

  & ~ .scroll-to-left {
    display: block;
    @include position(absolute, 0, -0.9em, 0, null);
    padding: 1em 0;
    width: 1.1em;
    display: flex;
    align-items: center;
    -webkit-box-shadow: inset -8px 0px 12px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset -8px 0px 12px -1px rgba(0, 0, 0, 0.75);
    box-shadow: inset -8px 0px 12px -1px rgba(0, 0, 0, 0.75);
    justify-content: center;
    .fa {
      color: #fff;
      font-size: 1.5em;
    }
  }
}
