// ========
//  MIXINS
// ========


// susy grid:
@mixin columns($columns, $gutter: null) {
  $grid-size: map-get($susy, columns);
  $amount:    $grid-size / $columns;

  @include span($amount of $grid-size $gutter);

  &:nth-child(#{$columns}n + #{$columns}) {
    @include span($amount of $grid-size $gutter last);
  }
}


// add parent before &:
@mixin parent($parent, $pseudo: null, $negation: null) {
  @if $pseudo == null {
    @at-root .#{$parent} & {
      @content;
    }
  }

  @else if $negation == null {
    @at-root .#{$parent}:#{$pseudo} & {
      @content;
    }
  }

  @else {
    @at-root .#{$parent}:not(:#{$pseudo}) & {
      @content;
    }
  }
}


// reverse-logic to use feature detection (modernizr) without being dependent of the class name
@mixin if($classname) {
  @at-root {
    html:not(.no-#{$classname}) { @content; }
  }
}


// selector for h1-6 #lazy:
@mixin headings($max: 6, $min: 1) {
  $id: unique-id();

  %#{$id} { @content; }

  @if $max <= 6 and $min >= 1 {
    @for $i from $min through $max {
      h#{$i} {
        @extend %#{$id};
      }
    }
  }

  @else {
    @for $i from 1 through 6 {
      h#{$i} {
        @extend %#{$id};
      }
    }
  }
}

// traditional clockwise trbl-inheritance. exclude properties using "null":
@mixin position($position, $top: null, $right: $top, $bottom: $top, $left: $right) {
  @if $position != null { position: $position; }
  @if $top      != null { top:      $top;      }
  @if $right    != null { right:    $right;    }
  @if $bottom   != null { bottom:   $bottom;   }
  @if $left     != null { left:     $left;     }
}


@mixin border($properties, $sides: null) {
  @each $side in $sides {
    @if      $side == null   { border:        $properties; }
    @else if $side == top    { border-top:    $properties; }
    @else if $side == right  { border-right:  $properties; }
    @else if $side == bottom { border-bottom: $properties; }
    @else if $side == left   { border-left:   $properties; }
  }
}


@mixin placeholder-styles {
  &::-webkit-input-placeholder { @content; }
  // &:-moz-placeholder           { @content; } // lte fx18
  &::-moz-placeholder          { @content; } // gte fx19
  &:-ms-input-placeholder      { @content; }
}


@mixin delay-siblings($type, $amount, $shortest-duration, $delay-child: null) {
  @for $nth-child from 1 through $amount {

    &:nth-child(#{$nth-child}) #{$delay-child} {
      @if $type == transition {
        -webkit-transition-delay: #{$shortest-duration * $nth-child};
                transition-delay: #{$shortest-duration * $nth-child};
      }

      @if $type == animation {
        -webkit-animation-delay: #{$shortest-duration * $nth-child};
                animation-delay: #{$shortest-duration * $nth-child};
      }
    }
  }
}


@mixin border-radius($radius, $corners...) {
  @if (length($corners) >= 4) {
    border-radius: $radius;
  }

  @else {
    @each $corner in $corners {
      border-#{$corner}-radius: $radius;
    }
  }
}

@mixin stretch($distance: 1em) {
  left:  -#{$distance};
  width: calc(100% + #{$distance * 2});
}

@mixin separator($distance: gr(1),
                 $directions: top,
                 $border-style: 1px solid site("border-color")
                ) {

  @each $direction in $directions {
    padding-#{$direction}: $distance;
    margin-#{$direction}: $distance;

    @if ($border-style != null) {
      border-#{$direction}: $border-style;
    }
  }
}

@mixin vertical-margins($margins-y: 1em, $margins-x: 0) {
  margin: $margins-y $margins-x;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin vertical-paddings($paddings-y: 1em) {
  padding-top: $paddings-y;
  padding-bottom: $paddings-y;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

@mixin shadow-covers($bg-color: #fff, $shadow-strength: .2, $shadow-size: .75em) {
  background:
    linear-gradient($bg-color 30%, rgba(#fff,0)),
    linear-gradient(rgba(#fff,0), $bg-color 70%) 0 100%,

    radial-gradient(50% 0,   farthest-side, rgba(#000, $shadow-strength), rgba(#000, 0)),
    radial-gradient(50% 100%,farthest-side, rgba(#000, $shadow-strength), rgba(#000, 0)) 0 100%;

  background:
    linear-gradient($bg-color 30%, rgba(#fff,0)),
    linear-gradient(rgba(#fff,0), $bg-color 70%) 0 100%,

    radial-gradient(farthest-side at 50% 0,    rgba(#000, $shadow-strength), rgba(#000, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(#000, $shadow-strength), rgba(#000, 0)) 0 100%;

  background-repeat: no-repeat;
  background-color: $bg-color;

  background-size: 100% 40px,
                   100% 40px,
                   100% $shadow-size,
                   100% $shadow-size;

  background-attachment: local,
                         local,
                         scroll,
                         scroll;
}
