// =======
//  FLAGS
// =======

/*
  [1] Using the :checked pseudo selector to apply the "current"
      class.

  [2] Applying width to the img element instead of the parent
      because we want to be fairly specific about flag resizing.
*/


// local variables:
// ----------------

  $block: flag;
  $flag-border: 2px;

// ----------------


.#{$block} {
  &__wrap {
    overflow: hidden;
    left: -$flag-border;
  }

  &__item {
    float: left;
    margin: 0;

    & + & {
      margin-left: gr(-2);
    }
  }

  &__icon {
    @extend %transition;
    cursor: pointer;
    display: inline-block;

    border-radius: site("br");
    padding: 1px;
    border:  $flag-border solid transparent;

    &.active {
      border-color: color($dokiv-red);
    }

    img {
      width: 30px; /* [2] */
    }
  }
}
