// ==============
//  MW TYPEAHEAD
// ==============

/*
  [1] Place the input above the dropdown. :-)
*/


// local variables:
// ----------------

  $block: mw-typeahead;

  $typeahead-width: 25em;

// ----------------


.#{$block} {
  max-width: 100%;
  width: $typeahead-width;

  &--has-button {
    .#{$block}__input {
      padding-right: 5em;
    }
  }

  &__input {
    z-index: 2; /* [1] */
  }

  &__dropdown {
    @extend %swift-in;
    @extend %momentum-scroll;
    z-index: z("low");
    border-radius: site("br");
    background: #fff;
    box-shadow: site("box-shadow");
    max-height: $typeahead-width * 1.25;
    margin-top: gr(-1);
    @include position(absolute, 100%, 0, null);

    &.ng-hide {
      opacity: 0;
      transform: translateY(-#{gr(1)});
    }
  }

  &__item {
    padding: gr(-1);
    cursor: pointer;

    &:hover {
      background: color($grey, 100);
    }

    &.active {
      background: site("color");
      color: #fff;
    }

    &:first-child {
      border-radius: site("br") site("br") 0 0;
    }

    &:last-child {
      border-radius: 0 0 site("br") site("br");
    }
  }

  &__button {
    z-index: 2;
    border-radius: 0 site("br") site("br") 0;
    @include position(absolute, 0, 0, 0, null);
  }
}
