// =============
//  TAB CONTENT
// =============

/*
  [*] I'm thinking we should use .tab-content as the generic block
      for common styling across tabbed views.

  [1] Transition because of drop zone fade.

  [2] Kind of lame, but since we want to inherit several styles from
      %skin it's probably better to keep the @extend.

  [3] Setting the layout for .additional-options (or whatever any
      other aside that's right next to .tab-content) in this document
      for simplicity.
*/

.tab-content {
  @extend %transition; /* [1] */
  @extend %skin;
  border-radius: 0; /* [2] */
  padding: gr(1);

  @include mq(tiny, max-width) {
    @include stretch(site("default-margins"));

    &, & > .skin {
      border-radius: 0;
      @include border(0, left right);
    }
  }

  @include mq(tiny) {
    border-top-left-radius: site("br");
  }
  
  &.ng-leave {
    display: none;
  }

  &__wrap {
    @include mq(medium) {
      @include span(9);

      + aside { /* [3] */
        @include span(3 last);
      }
    }
    @include mq(medium, max-width) {
      + aside {
        margin-top: gr(1);
      }
    }
  }

  &__header {
    margin-bottom: gr(1);
  }

  &__footer {
    margin-top: gr(1);
  }
}

.tab-pane-content,
.tab-pane {
  &:not(.active) {
    display: none;
  }
}
