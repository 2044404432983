// =========
//  BUTTONS
// =========

/*
  [1] Apparently button elements don't inherit font-smoothing,
      so we apply it manually here.

  [2] We don't style the regular button element more than
      necessary, since we don't want to have to override styles
      for one-off buttons, and it's nice to allow other elements
      to look like button elements.

  [3] "Disabled" styles carry !important flags to always override
      themed button rules.

  [4] Since we often toggle buttons around but never animate them,
      remove transition from them on .ng-hide.

  [5] Override left margins on buttons that are siblings to hidden
      buttons to prevent visible margins on left-aligned buttons.

  [6] Add margins between two visible buttons with hidden buttons
      in between.
*/


// local variables:
// ----------------

  $block:                    button;
  $button-color--saturation: 5%;
  $button-color:             color($grey, 50);
  $button-color--hover:      #fff;
  $button-color--active:     color($grey, 200);

  %button {
    @extend %transition--paint;
    @extend %reset-input;

    cursor: pointer;
    display: inline-block;

    &.ng-hide,
    &.ng-hide-remove,
    &.ng-leave,
    &:active {
      transition: none !important;
    }
  }

  @mixin bar--break {
    .line-component,
    .line-component--omega,
    .button {
      display: block;
      float: none;
      width: 100%;
      max-width: 25em;
      margin: 0 auto !important;
      text-align: center;

      + .line-component,
      + .line-component--omega,
      + .button {
        margin-top: gr(-1) !important;
      }
    }
  }

// ----------------


button {
  @extend %button;
  -moz-osx-font-smoothing: grayscale; /* [1] */
   -webkit-font-smoothing: antialiased;
}

.#{$block} { /* [2] */
  @extend %button;
  @extend %skin;
  background: $button-color;
  padding: .5em 1em;
  font-weight: 400;

  &:hover {
    background: $button-color--hover;
    border-color: site("border-color-dark");
  }

  &:active {
    color: rgba(site("text-color"), .5);
    background: $button-color--active;
  }
  
  &--system {
    color: #fff;
    background:   site("color");
    border-color: site("color");

    &:hover {
      background:   lighten(site("color"), $button-color--saturation);
      border-color: lighten(site("color"), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(site("color"), $button-color--saturation);
      border-color: darken(site("color"), $button-color--saturation);
    }
  }

  &--positive {
    color: #fff;
    background:   color($dokiv-green);
    border-color: color($dokiv-green);

    &:hover {
      background:   lighten(color($dokiv-green), $button-color--saturation);
      border-color: lighten(color($dokiv-green), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(color($dokiv-green), $button-color--saturation);
      border-color: darken(color($dokiv-green), $button-color--saturation);
    }
  }

  &--negative {
    color: #fff;
    background:   color($dokiv-red);
    border-color: color($dokiv-red);

    &:hover {
      background:   lighten(color($dokiv-red), $button-color--saturation);
      border-color: lighten(color($dokiv-red), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(color($dokiv-red), $button-color--saturation);
      border-color: darken(color($dokiv-red), $button-color--saturation);
    }
  }

  &--orange {
    color: #fff;
    background:   color($dokiv-orange);
    border-color: color($dokiv-orange);

    &:hover {
      background:   lighten(color($dokiv-orange), $button-color--saturation);
      border-color: lighten(color($dokiv-orange), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(color($dokiv-orange), $button-color--saturation);
      border-color: darken(color($dokiv-orange), $button-color--saturation);
    }
  }

  &--grey {
    background:   color($grey, 400);
    border-color: color($grey, 400);
  }

  &[disabled] { /* [3] */
    &,
    &:hover,
    &:focus,
    &:active {
      cursor:       not-allowed;
      color:        site("text-color-light") !important;
      border-color: site("border-color")     !important;
      background:   $button-color            !important;
    }
  }

  & + & {
    margin-left: gr(-1);
  }

  &__bar {
    @extend %vertical-margins;
    @extend %clearfix;

    &.row, &.row--small {
      .#{$block} {
        @include mq(tiny, max-width) {
          padding:       0.5em;
          border-radius: 0;
        }
      }

      .#{$block} + .#{$block} {
        @include mq(tiny, max-width) {
          margin-left: 0;
          border-left: 0;
        }
      }
    }

    &--absolute {
      @include position(absolute, 0, null, null, 0);
      z-index: 2;
      margin: 0;

      &--right {
        right: 0;
        left: auto;
      }
    }

    &--break-tiny {
      @include mq(tiny, max-width) {
        @include bar--break;
      }
    }

    &--break-small {
      @include mq(small, max-width) {
        @include bar--break;
      }
    }
    
    &--right {
      margin-right: 1em;
    }

    &--back {
      margin: 0 0 gr(-2);

      .button {
        padding: gr(-2) gr(-1);
        color: color($grey, 600);
      }

      @include mq(tiny, max-width) {
        .button {
          border-radius: 50%;
        }

        span {
          display: none;
        }

        .fa, .svg-inline--fa {
          margin-right: 0 !important;
          left: -1px;
        }
      }

      .fa {
        font-size: .85em;
      }
    }
  }

  &.icon-only {
    padding: gr(-1);

    &--tiny {
      @include mq(tiny, max-width) {
        > span {
          display: none;
        }

        > .fa {
          margin: 0;
        }
      }
    }
  }

  &.ng-hide,
  &.ng-leave { /* [4] */
    transition: none;
  }

  &.ng-hide {
    + .#{$block} {
      margin-left: 0; /* [5] */
    }

    .#{$block}:not(.ng-hide) ~ & + .#{$block}:not(.ng-hide) { /* [6] */
      margin-left: gr(-1);
    }
  }
}
