// ===========
//  HAMBURGER
// ===========

/*
  [*] Uses itself and the ::before and ::after pseudo elements to
      create the classic "hamburger icon".

  [1] !important to override the hover/open state applied below.
*/


// local variables:
// ----------------

$burger-height:  2px;
$burger-width:   24px;
$burger-spacing: 2;
$burder-color:   site("text-color-light");

// ----------------


.hamburger-icon {
  &, &::before, &::after {
    @extend %transition;
    display: block;

    width:         $burger-width;
    height:        $burger-height;
    border-radius: $burger-height;
    background:    $burder-color;

    cursor: pointer;
    margin: 0 auto;
  }

  &::before, &::after {
    content: "";
  }

  &::before { top: -(($burger-height * $burger-spacing) + ($burger-height)); }
  &::after  { top:    $burger-height * $burger-spacing; }

  .sidebar-open > & {
    background: transparent !important; /* [1] */

    &::before, &::after {
      top: 0;
      background: site("text-color");
    }

    &::before { transform: rotate( 45deg); }
    &::after  { transform: rotate(-45deg) translateY(0); top: -$burger-height; }
  }

  .header__toggle-menu:hover & {
    &, &::before, &::after {
      will-change: transform, background, top;
      background: site("text-color");
    }
  }
}
