// ====================
//  SELECT PUBLICATION
// ====================

/*
  [*] These rules are primarily intended to used in or override
      the styles of the the mw-typeahead.
*/


// local variables:
// ----------------

  $block: select-publication;

// ----------------


.#{$block} {
  &__heading {
    @extend %ellipsis;
    font-size: 1em;
    font-weight: 700;
    margin: 0;
    line-height: 1;
  }

  &__info {
    @extend %ellipsis;
    margin: 0;
    line-height: 1;
    font-size: .8em;
  }

  &__description {
    opacity: .75;
    margin: gr(-2) 0;
  }

  &__item {
    border-top: 1px solid site("border-color");
  }
}
