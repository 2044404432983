$block: visible-comments;

.#{$block} {
  &__wrapper {
    margin-top: 2em;
    color: site("text-color-light");
    a {
      transition: none;
      line-height: 1.2;
    }
    a:hover,
    a:active {
      border-bottom: 1px solid site("border-color");
    }
  }
  &__content {
    margin: 0 auto;
  }
  &__wrap {
    width: 80%;
    margin: auto;
    @include mq(small, max-width) {
      width: 100%;
      .tab-content__footer {
        margin-right: 1.2em;
      }
    }
  }

  &__column-nav {
    margin-bottom: 1em;
    .button {
      width: 100%;
      &.active, &:hover {
        background-color: site("color");
        border-color: site("color");
        color: #fff;
      }
    }
  }

  &__column {
    @include mq(small) {
      &     { @include span(7 of 12 .4); }
      & + & { @include span(5 of 12 .4 last); }
    }
    @include mq(small, max-width) {
      & + & {
        margin-top: 2em;
      }
    }
  }
  &__comment-items {
    .#{$block}__section {
      &.no-avatar {
        padding-left: 0;
      }
      &:nth-child(even) {
        .#{$block}__comment {
          background: #daedf4;
        }
      }

      &:nth-child(odd) {
        .#{$block}__comment {
          background: site("bg-color");
        }
      }
    }
  }

  &__pagination {
    @extend %separator;
  }

  &__list-sort {
    margin-top: gr(-1);
    li {
      padding: gr(-1) 0;
      &:hover,
      &:active,
      &.active {
        a {
          border-bottom: 1px solid site("border-color");
        }
      }
    }
    &.hide {
      @include mq(tiny, max-width) {
        display: none !important;
      }
    }
    &.open {
      display: block;
      &-add {
        animation: fallDown  transition("duration") transition("easing");
      }
    }
  }

  &__list-icon {
    float: right;
    @include mq(small, min-width) {
      margin-right: gr(-1);
    }
  }

  &__avatar {
    @include mq(small, min-width) {
      @include position(absolute, 0, null, 0, gr(-1));
    }
    .user-avatar {
      width: 3em;
    }
  }
  &__item {
    @include mq(small, min-width) {
      padding-left: 4.5em;
    }
    &:not(:last-child) {
      margin-bottom: gr(0);
    }
  }
  &__to-object {
    @include position(absolute, 1em, 0, null, null);
    text-align: right;
    @extend %transition;
    @include mq(tiny, max-width) {
      a:hover,
      a:active {
        border: none;
        color: site("text-color");
      }
    }
  }
  &__comment {
    padding: 1em 2.5em 1em 1em;
    time {
      display: block;
      font-size: 0.8em;
    }
    .markdown__wrap + time,
    .#{$block}__groups + time {
      margin-top: gr(-1);
    }
    h4 {
      span {
        font-weight: 400;
      }
      line-height: 1.3;
    }
    .markdown__wrap {
      margin: gr(-2) 0 0;
    }
    &.has-border {
      border-left: .5em solid color($dokiv-orange);
      &.border-green {
        border-left: .5em solid color($dokiv-green);
      }
    }
    .short-text {
      height: 100px;
      overflow: hidden;
      transition: all 1s;  
    }
    .show-more {
      font-weight: 700;
      font-size: 0.9em;
    }

    .fadeout {
      position: relative; 
      bottom: 4em;
      height: 2em;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
      );
    } 
  }

  &__has-arrow {
    .#{$block}__section:nth-child(even) {
      .#{$block}__comment:after {
        border-right-color: #daedf4;
      }
    }
    .#{$block}__section:nth-child(odd) {
      .#{$block}__comment:after {
        border-right-color: site("bg-color");
      }
    }
    .#{$block}__comment {
      &:after,
      &:before {
        right: 100%;
        top: 22px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: transparent;
        border-width: 7px;
        margin-top: -7px;
      }
      &:before {
        border-color: transparent;
        border-width: 8px;
        margin-top: -8px;
      }
    }
  }

  &__search-form {
    @extend %flex;
    li {
      @extend %flex;
      &:first-child {
        flex: 1;
      }
      &:last-child {
        margin-left: gr(-1);
      }
    }
  }

  &__reset {
    cursor: pointer;
    padding: .5em gr(-1);
    @include position(absolute, 0, 2.5em, 0, null);
    opacity: .7;

    &:hover,
    &:active {
      opacity: 1;
    }
    i {
      margin-right: 0;
    }
  }

  &__filter {
    @include mq(small) {
      @include position(absolute, 0, 0, null, null);
    }

    @include mq(small, max-width) {
      margin-top: 1em;
    }

    button[type="submit"] {
      color: site("text-color-light");
      @include position(absolute, 0, 0, null, null);
      padding: 9px;
      border-radius: 0 site("br") site("br") 0;
      border: 1px solid site("border-color");
      border-bottom: 0;
      &:hover,
      &:active {
        background: color($grey, 200);
      }
    }

    input[type="text"] {
      padding-right: 4.5em;
      height: 34px;
    }

    .select__wrap {
      width: 13em;
      margin-left: 1em;
      @include mq(tiny, max-width) {
        width: 100%;
        margin-left: 0;
        margin-top: 1em;
      }
      input[type="text"] {
        padding-right: 0.45em;
      }
    }

    @include mq(tiny, max-width) {
      form {
        width: 100%;
      }
    }

  }

  &__label {
    background: site("color");
    padding: gr(-1);
    color: #FFF;
    margin-bottom: 1em;
    margin-top: 2em;
    &.label-orange {
      background: color($dokiv-orange);
    }
    &.label-green {
      background: color($dokiv-green);
    }
    &.notification-label{
      margin-top: 4.9em;
    }
    &.comments-label {
      margin-top: 2.9em;
    }
  }

  &__info {
    margin-bottom: gr(-1);
    span {
      margin-right: gr(-1);
      padding: 0 gr(-2);
      background: color($grey, 600);
      color: #FFF;
    }
    time {
      .svg-inline--fa {
        margin-right: 5px;
      }
      @include mq(medium) {
        @include position(absolute, 0, 0, null, null);
        &.button-behind {
          @include position(absolute, 2px, 2em, null, null);
        }
      }
      @include mq(medium, max-width) {
        display: block;
        margin-top: gr(-1);
      }
    }
  }

  &__form {
    margin-top: 3em;
    min-height: 25em;
    textarea {
      min-height: 12em;
    }
  }

  &__create-message {
    @include position(absolute, 0, 0, null, null);
  }

  &__groups {
    margin-top: gr(-1);
    li {
      padding: gr(-2);
      display: inline-block;
      margin: gr(-2) gr(-2) 0 0;
      background: #56D792;
      color: #FFF;
    }
  }

  &__number-icon {
    @include position(absolute, null, 10px, 10px, null);
    background: #f00;
    color: #fff;
    width: 27px;
    height: 27px;
    border-radius: 1em;
  }

  &__create-button {
    .button {
      padding: 0.6em 1em;
    }
    @include mq(tiny, max-width) {
      .line-component--padded + &.line-component--padded {
        margin-left: 0;
      }
      .button {
        margin-top: gr(-1);
      }
    }
  }
}