// =========
//  OPTIONS
// =========

/*
  [1] Layout is currently set in _tab-content.scss

  [2] Be below header until the header stops being fixed.

  [3] Override the extremely specific vertical button sibling
      logic.

  [4] Add huge bottom padding to small screens to improve
      scrolling and make last element dropdowns visible.
*/


// local variables:
// ----------------

  $block:         options;
  $offset-top:    2.35em;
  $aside-gutter:  gr(-2);
  $toggle-width:  4em;
  $toggle-height: $toggle-width * gr(1, null);
  $fixed-width:   21em;

// ----------------


.#{$block} { /* [1] */
  &__wrap {
    @extend %swift-in;
    @include mq(tiny, max-width) {
      top: 0;
      left: 0;
      right: 0;
      z-index: z("mid"); /* [2a] */
      width: auto;
    }
    @include mq(large, max-width) {
      box-shadow: site("box-shadow");

      width: $fixed-width;
      z-index: z("low"); /* [2b] */
      @include position(fixed, site("bar-height"), 0, 0, null);

      &:not(.open) {
        transform: translateX(100%);
        box-shadow: 0 0 0 transparent;
      }
    }
    &.#{$block}__order {
      @include mq(large, max-width) {
        box-shadow: site("box-shadow");       
      }
    }
  }

  &__publication {
    @include mq(small, max-width) {
      @include separator();
    }
    .button__bar {
      .button {
        display: block;
        width: 100%;
        margin: gr(-1) 0 0 0 !important; /* [3] */
      }
    }
  }

  &__scroll {
    @include mq(large, max-width) {
      background: #fff;
      padding: gr(1) site("default-margins") gr(6); /* [4] */
      @include position(absolute, 0);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      html.no-touch & {
        overflow-y: auto;
      }
    }

    @include mq(large) {
      overflow: visible;
    }
  }

  &__list {
    @include mq(tiny, max-width) {
      max-width: $fixed-width;
      margin: 0 auto;
    }
  }

  &__title {
    font-weight: header("bold");
    margin-bottom: gr(-2);
  }

  &__toggle {
    display: block;
    border-radius: 50%;
    background: #fff;
    box-shadow: site("box-shadow");

    width:  $toggle-width;
    height: $toggle-height;
    margin: (-#{$toggle-height / 2}) (-#{$toggle-width / 2}) 0 0;
    @include position(absolute, 50%, 100%, null, null);

    .fa {
      @extend %transition;
      left: -.9em;

      .#{$block}__wrap.open > & {
        transform: scale(-1, 1);
        left: -.7em;

        @include mq(tiny, max-width) {
          left: .9em;
        }
      }
    }

    .#{$block}__wrap.open > & {
      @include mq(tiny, max-width) {
        z-index: 1;
      }
    }

  }

  &__close {
    display: block;
    z-index: 1;
    padding: gr(-1);
    background: #fff;
    border-bottom-left-radius: site("br");
    @include position(absolute, 0, 0, null, null);
    @include border(1px solid site("border-color"), left bottom);
  }

  &__status {
    font-weight: header("regular");
    margin-bottom: gr(-1);

    .status--draft &,
    .status--archived & {
      > b {
        color: color($dokiv-red);
      }
    }
  }

  &__additional {
    &.ng-enter {
      animation: fallDown transition("duration") transition("swift-in");
    }

    &.ng-leave {
      animation: flyUp--leave transition("duration") transition("swift-in");
    }

    .mw-typeahead {
      &__input {
        border-radius: 0 0 site("br") site("br");
      }
    }
  }

  &__additional-toggle {
    padding: gr(-1);
    width: gr(2);
    text-align: center;
    @include position(absolute, 0, 0, null, null);
  }
  &__save-as-label {
    margin-bottom: gr(-1);
  }
  &__reference--buttons {
    @include position(absolute, 0, 0, null, null);
    a {
      transition: color transition("duration");
      &:not(:hover) {
        opacity: gr(-1, null);
      }
      transition: none;
    }
  }
  &__reference--text {
    @extend %skin;
    padding: 0 .45em;
    line-height: 2;
  }
}
