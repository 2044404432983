// ====================
//  PUBLICATION CREATE
// ====================

/*
  [1] Using checkbox:checked + label right now. Perhaps we should
      use javascript to toggle classes and states instead? I'm not
      sure. This is "native", but kind of hackish.

  [2] Since we're reusing the same module here from the regular
      publication view, we need to remove some styles.
*/


// local variables:
// ----------------

  $block:               publication-create;

  $label-color:         site("color");
  $label-color--hover:  color($grey, 50);
  $label-color--active: color($grey, 100);
  $label-border:        1px solid site("border-color");

// ----------------


.#{$block} {
  &__wrap {
    h2 {
      font-weight: header("regular");
    }
  }

  &__header {
    margin-bottom: site("default-margins");
  }

  &__main {
    @include mq(medium) {
                @include span(9 of 12);
      + aside { @include span(3 of 12 last); }
    }
  }

  &__column {
    @include mq(small) {
              @include span(4 of 12);
      & + & { @include span(8 of 12 last); }
    }
  }

  &__body {
    padding: gr(1);

    @include mq(small, max-width) {
      padding: 1em;
      @include border-radius(0, top-left, top-right);
    }
  }

  &__label { /* [1] */
    @extend %transition;

    margin: 0;
    cursor: pointer;
    background: #fff;
    font-weight: body("regular");
    border: $label-border;
    padding: 1em gr(-1);

    @include mq(small) { padding: 1em; }

    &:hover {
      background: $label-color--hover;
    }

    &:active {
      background: $label-color--active;
    }

    &.active {
      color: #fff;
      background:   $label-color;
      border-color: $label-color;
    }

    h3 {
      font-weight: header("regular");
      line-height: site("line-height");

      @include mq(small, max-width) {
        text-align: center;
        font-size: 1em;
      }

      @include mq(tiny, max-width) {
        font-size: .95em;
      }
    }

    p {
      font-size: .95em;
      margin: 0;

      @include mq(small, max-width) {
        display: none;
      }
    }
  }

  &__select {
    overflow: hidden;
    margin-bottom: site("default-margins");
    @include mq(small, max-width) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__type {
    @include mq(tiny, max-width) {
      display: flex;
      flex: 0 0 33.33%;
    }

    @include mq(tiny, to, small) {
      display: flex;
      flex: 0 0 25%;
    }

    &:first-child {
      border-radius: site("br") 0 0 site("br");

      @include mq(small) {
        border-radius: site("br") site("br") 0 0;
      }
    }

    & + & {
      @include mq(small) {
        border-top: 0;
      }
    }

    &:last-child {
      border-radius: 0 site("br") site("br") 0;

      @include mq(small) {
        border-radius: 0 0 site("br") site("br");
      }
    }

    & + & {
      @include mq(small, max-width) {
        border-left: 0;
      }
    }
  }
  &__description {
    margin-top: gr(-1);
    white-space: normal;
  }
  &__choose-object {
    @include mq(small) {
      margin: 1em 0 2em;
      font-size: 1.3em;
    }
  }
  &__create-link {
    &.card-list__link {
      min-height: 9em;
      .fa-investigation {
        width: 26px;
        & + .card-list__name span {
          top: -9px;
        }
        & + span {
          top: -10px;
        }
      }
    }
  }
}
