// ======
//  TEXT
// ======

/*
  [1] Since we globally set the cursor to "default", reapply the
      text cursor to text inputs.

  [2] It's probably more common for us to have inputs with 100%
      width than not.

  [3] This is to prevent zoom on focus on mobile devices. It's
      not reliable, but better than nothing.

  [4] List of input types that should be affected by the global
      styles for text inputs. Would've preferred to use a
      whitelist approach with :not() instead, but it makes the
      selectors just a tad too specific to be comfortable working
      with. It's either this or a global class name.

  [5] Let's assume that any input without a type should have
      type="text".
*/

%text-input {
  @extend %reset-appearance;
  @extend %skin;
  transition: border-color transition("duration") transition("easing");
  background: #fff;

  cursor:     text;   /* [1] */
  width:      100%;   /* [2] */
  padding:    0 .45em;
  box-sizing: inherit;
  text-align: inherit;
  line-height: 2;
  min-height: 2em;

  html.touch & {
    font-size:  16px; /* [3] */
  }

  &.ng-hide-add {
    transition: none;
  }

  &:focus {
    border-color: site("border-color-dark");
    background-color: rgba(#196c96, .15);
    box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,1);
  }

  @include placeholder-styles {
    font-weight: body("thin");
  }
}

%input-danger {
  border: 1px solid color($dokiv-red);
}

input {
  $input-types: text, password, search, email, tel, number, date, datetime, time; /* [4] */

  @each $input-type in $input-types {
    &[type="#{$input-type}"] {
      @extend %reset-input;
      @extend %text-input;
    }
  }

  &:not([type]) { /* [5] */
    @extend %reset-input;
    @extend %text-input;
  }
}

input {
  &.line-component {
    width: auto;
  }
  &.input-danger {
    @extend %input-danger;
  }
}

%textarea {
  @extend %reset-input;
  @extend %text-input;
  resize: vertical;
  min-height: 6em;
  padding: .45em;
  line-height: site("line-height");

  @include placeholder-styles {
    top: 0;
  }
}

textarea,
.textarea {
  @extend %textarea;
  &.input-danger {
    @extend %input-danger;
  }
}

//Custom css of React bootstrap typeahead
.rbt-input {
  @extend %text-input;
}