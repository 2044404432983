// =================
//  OFF-CANVAS MENU
// =================

/*
  [1] Native scroll i iOS.

  [2] Move the sidebar onto the canvas when body has class name
      "sidebar-open".

  [3] We currently need another menu button for small screens.
*/

.navdrawer {
  &__wrap {
    @extend %swift-in;
    @extend %momentum-scroll; /* [1] */

    @include position(fixed, 0, 100%, 0, null);
    z-index: z("high");
    width: site("sidebar-width");

    border-right: 1px solid darken(site("color"), 5%);
    background: site("color");
    color: #fff;

    &.sidebar-open { /* [2] */
      transform: translateX(site("sidebar-width"));
    }

    @include mq(tiny) {
      top: site("bar-height");
    }
  }

  &__menu-left {
    @include position(fixed, 51px, null, 0, 0);
    z-index: z("high");
    width: site("sidebar-width");
    background: #ffffff;
    box-shadow: 1px 4px 9px color($grey, 500);
    color: color($grey, 800);

    &.sidebar-enter {
      animation: flyRight transition("duration") transition("swift-in") forwards;
      left: -19em;
    }

    &.sidebar-exit {
      animation: flyLeft transition("duration") transition("swift-in") forwards;
      left: -19em;
    }

    ul {
      margin-top: 3.5em;
    }
  }

  &__item-left {
    font-size: 1rem;
    padding: 1em 1em;
    border-bottom: 1px solid site("border-color");
  }
}
