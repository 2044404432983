// ==============
//  AUTOCOMPLETE
// ==============

/*
  [1] We should probably redesign this module, as it's kind of
      messy right now. It'd be better if inputs with autocomplete
      where siblings to the autocomplete element, for styling and
      alignment. Also is there currently more than one autocomplete
      solution in place?
*/

auto-complete { /* [1] */
  display: block;
}

.autocomplete {
  margin: -1.15em 1px 0;
  padding: gr(-1, 1em, 2) 0;

  background: #fff;
  border: 1px solid site("border-color-dark");
  @include border-radius(site("br"), bottom-left, bottom-right);

  z-index: 1;
  @include position(absolute, 100%, site("default-margins"), null, site("default-margins"));
}

.suggestion-item {
  @extend %transition;

  cursor: pointer;
  padding: gr(-1, 1em, 2) gr(-1);

  &:hover {
    background: site("bg-color");
  }
}
