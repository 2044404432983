// =====
//  404
// =====

.error {
  &__body {
    background: site("bg-color");

    @extend %flex;
    @extend %flex-direction-column;

    > [ui-view] {
      @extend %flex;
      @extend %align-items-center;
      @extend %flex-grow-1;
    }
  }

  &__wrap {
    margin: 0 auto;
    padding: gr(1);
    max-width: site("width--slim");
    text-align: center;
  }

  &__header {
    margin-top: gr(-1);
  }

  &__content {
    width: 32em;
    margin: 1em auto;
    max-width: 100%;
    padding: 1em;
    background: site("bg-color");
  }
}
