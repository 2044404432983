// ==========
//  QUESTION
// ==========

/*
  [*] Styles for the user generated form questioms on the form
      publication type.

  [1] Since we ng-switch the actual inputs sometimes, remove
      whatever transition they may have while changing type.

  [2] Since we want to use flex for question columns (to prevent
      columns of different heights from screwing up the layout), we
      must excplicitly say that the regular rows should be 100% wide.

  [3] Allow remove__question to espace into the paddings of the
      question__item.

  [4] Yikes, this one is messy. Basically we want different than
      default spacing on regular radio buttons and checkboxes,
      but not on stylized radio buttons such as the polar type.

  [5] Form preview inputs must be [disabled] for performance
      reasons, so we override the regular disabled styles to make it
      feel more like a preview.
  
  [6] Use padding here for vertical space to allow for a larger
      area to trigger hover for question__edit. The question__divider
      is too small (1px) to trigger hover by itself.
*/

// local variables:
// ----------------

$block: question;

$input-add--width: 3em;
$select-arrow--padding: 1.5em;
$select-arrow--position: 1em;
$preview-bg-color: #fff;
$preview-border: 1px dotted color($grey, 500);

@mixin resetColumnSeparator {
  display: block;

  .#{$block}__header {
    max-width: none;
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
}

// ----------------

.#{$block} {
  &__wrap {
    @extend %flex;
    @extend %flex-wrap;
    // Removed (caused element to extend outside parent)
    margin-right: -1.618rem;

    label {
      font-weight: body("regular");
    }

    .ng-leave,
    .ng-enter {
      /* [1] */
      transition: none;
    }
  }

  &__item {
    width: 100%; /* [2] */
    padding: 1em gr(1) 1em 0;
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__list-body {
    @extend %vertical-margins;

    li {
      width: 100;
      @extend %vertical-margins;

      &:empty {
        margin: 0;
      }

      p {
        padding-left: gr(-2);
        padding-right: $input-add--width + gr(-2);
      }

      a {
        text-align: center;
        width: $input-add--width;
        border-radius: 0 site("br") site("br") 0;
        @include position(absolute, 0, 0, 0, null);
        /* Extend height of button to fill the whole row to make it easier to click */
        position: absolute;
        top: -0.5em; /* Center */
        right: 0;
        padding: 0.5em 0;

        i {
          font-size: 1.2em;
        }
      }
    }
  }

  &__list-header {
    input,
    select {
      background: #fff;
      width: 100%;
      padding-right: $input-add--width + gr(-2);
    }

    .button {
      &:not(.has-text) {
        padding: 0;
        width: $input-add--width;
      }
      &.has-text {
        .fa {
          @include mq(small, max-width) {
            margin-right: 0;
          }
        }
      }
      border-radius: 0 site("br") site("br") 0;
      @include position(absolute, 0, 0, 0, null);
    }
  }

  &__select {
    margin: 0;
    padding: 0;

    // &:after {
    //   font-family: FontAwesome;
    //   content: "\f0d7";
    //   position: absolute;
    //   right: #{$select-arrow--position};
    //   top: 0.7em;
    // }

    select {
      background: #fff;
      width: 100%;
      padding: 0 gr(-2);

      /* Remove styling, we use our own arrow: */
      -webkit-appearance: none;
      -moz-appearance: none;

      /* Line left of arrow */
      background-image: linear-gradient(to right, #ccc, #ccc);
      background-position: calc(
          100% - #{$select-arrow--position + $select-arrow--padding}
        )
        0.5em;
      background-size: 1px 1.5em;
      background-size: 1px 1.5em;
      background-repeat: no-repeat;
    }

    /* Necessary to prevent iOS from zooming (font-size less than 16px makes it zoom): */
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      select {
        font-size: 16px;
      }
    }

    /* Styles for list with an add-button to the right of the select-box */
    &--add {
      &:after {
        right: #{$input-add--width + $select-arrow--position};
      }

      select {
        background-position: calc(
            100% - #{$input-add--width + $select-arrow--position +
              $select-arrow--padding}
          )
          0.5em;
      }
    }
  }

  &__column {
    margin-top: 0;

    @include mq(tiny) {
      float: left;
    }

    &--half {
      @include mq(tiny) {
        width: 50%;
      }
    }

    &--third {
      @include mq(tiny) {
        width: 33.333%;
      }
    }

    &--fourth {
      float: left;
      width: 50%;

      @include mq(small) {
        width: 25%;
      }
    }

    + .#{$block}__item:not(.#{$block}__column) {
      clear: left;
    }
  }

  &__heading {
    margin-bottom: 0;
    .#{$block}__title {
      font-size: gr(1);
      margin-bottom: 0;
      &.small {
        font-size: gr(0);
      }
      &.medium {
        font-size: 1.286em;
      }
    }
  }

  &__title {
    font-size: 1em;
    font-weight: header("bold");
    margin-bottom: gr(-3);
    word-wrap: break-word;
    &.help {
      @include mq(small, max-width) {
        padding-right: 2.5rem;
        .button--help {
          right: 0;
        }
      }
    }
    .button--help {
      @include position(absolute, -6px, null, null, null);
      padding: 3px 6px;
      color: #fff;
      background: #00c724;
      font-size: 0.8em;
      border-color: #00c724;
      margin-left: 2em;

      &:hover {
        background: lighten(#00c724, $button-color--saturation);
        border-color: lighten(#00c724, $button-color--saturation);
      }

      &:active {
        color: rgba(#fff, 0.5);
        background: darken(#00c724, $button-color--saturation);
        border-color: darken(#00c724, $button-color--saturation);
      }
    }
  }

  &__divider {
    width: 100%;
    border-bottom: 1px dashed site("border-color");
    height: 2px;
  }

  &__preview {
    margin-top: site("default-margins") * 3 !important;
    padding: 1em;
    border-radius: 0 site("br") site("br") site("br");
    background: $preview-bg-color;
    border: $preview-border;

    &::before {
      display: block;
      content: attr(data-before);
      font-size: 0.8em;

      padding: gr(-2) gr(-1);
      background: $preview-bg-color;
      border-radius: site("br") site("br") 0 0;
      box-shadow: 0 1px 0 #fff;
      @include border($preview-border, top left right);

      @include position(absolute, null, null, 100%, -1px);
    }

    .#{$block}__remove {
      display: none;
    }
  }

  &__header {
    margin-bottom: gr(-2);
  }

  &__description {
    font-size: 0.9em;
    font-style: italic;
    line-height: 1.3;
    margin: 0;
  }

  &__layout {
    position: static; /* [3] */
    &.column {
      .#{$block}__flexbox {
        @extend %flex;
        @extend %flex-wrap;
        width: 100%;
      }
    }

    &.vertical {
      .question__options {
        .checkbox,
        .radio {
          &__wrap {
            /* [4] */
            @include vertical-margins(1em);
          }

          &__label::before {
            margin-right: gr(-1);
          }
        }
      }

      .#{$block}__answer {
        margin-top: gr(-2);
      }
    }

    &.horizontal {
      @extend %clearfix;

      .#{$block}__options {
        li {
          float: left;
          margin: 0 1em 1em 0;
        }
      }
    }

    &.column {
      @include mq(tiny) {
        @include extend("flex");

        .#{$block}__header {
          max-width: 50%;
          @include separator(gr(1), right);
          @include extend("flex-shrink-0");
        }

        .#{$block}__answer {
          @include extend("flex-grow-1");
        }
      }

      .#{$block}__column--half & {
        @include mq(tiny, to, small) {
          @include resetColumnSeparator;
        }
      }

      .#{$block}__column--third &,
      .#{$block}__column--fourth & {
        @include mq(tiny) {
          @include resetColumnSeparator;
        }
      }
    }
  }

  &__form {
    &--preview {
      .checkbox__label,
      .radio__label {
        /* [5] */
        opacity: 1 !important;
      }
    }
  }

  &__edit {
    @extend %transition;
    @include position(absolute, -1px, -1px, null, null);
    padding: gr(-1);
    background: #fff;
    border-radius: 0 site("br") 0 site("br");
    border: $preview-border;
    z-index: 1;

    .#{$block}__item:not(:hover) & {
      opacity: 0;
    }

    button + button {
      margin-left: gr(-1);
    }

    .fa {
      font-size: 1.2em;
    }
  }

  &__thank-you {
    @extend %swift-in;
    transition-timing-function: transition("swift-in2");
    background: site("color");
    color: #fff;
    border-radius: site("br");
    text-align: center;
    padding: 1em;

    &.ng-hide {
      transform: scale(0);
      opacity: 0;
    }

    p {
      margin: 0;
    }
  }

  &__file-remove {
    padding: 0.25em 0.5em;
  }

  &__file-link {
    color: color($dokiv-blue);
  }

  &__upload-restriction {
    font-size: 0.9em;
    font-style: italic;
    line-height: 1.3;
    margin: 0;
  }

  &__timepickr--close {
    width: 100%;
    border-radius: 0;
    background: #fff;
    border-top: 1px solid site("border-color");
    padding: 10px;
    text-align: center;
    margin-top: 0.5em;
  }

  &__scroll-to-left {
    display: none;
  }

  &__item {
    .scrollbar {
      overflow-y: auto;

      & ~ .#{$block}__scroll-to-left {
        @include position(absolute, 0, -0.9em, 0, null);
        padding: 1em 0;
        width: 1.1em;
        display: flex;
        align-items: center;
        background: #d8d8d8;
        justify-content: center;
        .fa {
          color: #fff;
          font-size: 1.5em;
        }
      }
    }
    .#{$block}__grid {
      &.report,
      &.single-submission {
        margin-bottom: 3em;
        overflow-x: auto;
      }
      .question__answer {
        &.edit-question {
          overflow-x: auto;
        }
      }
      &--description {
        font-style: italic;
        font-size: 0.9em;
        font-weight: 400;
      }
      table {
        &.form-renderer {
          //background: #fff;
        }
        thead {
          tr {
            th {
              white-space: unset;
              word-break: break-word;
              background: transparent;
              border: none;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(even) td {
              background: site("bg-color");
            }
            td {
              padding: 0.5rem;
              overflow: hidden;
              min-width: 7rem;
              word-break: break-word;
              @include mq(small, max-width) {
                min-width: 6rem;
              }
              &:first-child {
                border: none;
                background: transparent;
                padding-right: 1em;
                min-width: 7rem;
                padding-left: 0;
                @include mq(small, max-width) {
                  min-width: 6rem;
                }
              }
              .grid-checkbox {
                font-size: 1.8rem;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background: transparent;
                @include position(absolute, 0, 0, 0, 0);
                span {
                  line-height: 1.5rem;
                  height: 1.8rem;
                }
              }
              input {
                text-align: center;
                background: transparent;
                @include position(absolute, 0, 0, 0, 0);
                border-radius: 0;
                border: none;
                min-height: initial !important;
                padding: 0.2rem;
              }
            }
          }
        }
      }
    }
  }
  &__sub--wrap {
    &:empty {
      display: none;
    }

    .#{$block}__item {
      padding-right: 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
  &__sub-question {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid site("border-color");
    border-radius: site("br");
    &:empty {
      display: none;
    }
  }

  &__search-placeholder .css-11q7ts8-control {
    display: none;
  }

  &__tags-wrapper {
    button {
      font-size: 1.1em;
      background: #d8d8d8;
      border-color: #d8d8d8;
      &:hover {
        background: lighten(#d8d8d8, $button-color--saturation);
        border-color: lighten(#d8d8d8, $button-color--saturation);
      }

      &:active,
      &.active {
        color: #fff;
        background: darken(#d8d8d8, 15);
        border-color: darken(#d8d8d8, 15);
      }
    }
  }

  &__calc {
    display: flex;
    justify-content: space-between;
    @include mq(small, max-width) {
      flex-direction: column;
      > div + div {
        margin-top: 1em;
      }
    }
    > div {
      &:first-child {
        @include mq(small) {
          width: 40%;
        }

        table {
          width: 100%;
        }
      }
      &:last-child {
        .skin {
          background-color: #fff;
          height: 100%;
          p {
            font-size: 1.5rem;
          }
          h3 {
            font-weight: normal;
          }
        }
      }
      @include mq(small) {
        width: 50%;
      }
      width: 100%;
    }
  }
}

/* Form steps */
.steps {
  list-style-type: none;
  padding: 0;
}
.steps li {
  display: inline-block;
  margin-bottom: 3px;
}
.steps li a,
.steps li p {
  background: #e5f4fd;
  padding: 8px 20px;
  color: #0077bf;
  display: block;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-indent: 12px;
}
.steps li a:hover,
.steps li p:hover {
  text-decoration: none;
}
.steps li a:before,
.steps li p:before {
  border-bottom: 18px solid transparent;
  border-left: 12px solid #fff;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  margin-top: -18px;
}
.steps li a:after,
.steps li p:after {
  border-bottom: 18px solid transparent;
  border-left: 12px solid #e5f4fd;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  /*right: -12px;*/
  left: 100%;
  top: 50%;
  width: 0;
  margin-top: -18px;
  z-index: 1;
}
.steps li.active a,
.steps li.active p {
  background: #0077bf;
  color: #fff;
}
.steps li.active a:after,
.steps li.active p:after {
  border-left: 12px solid #0077bf;
}
.steps li.undone a,
.steps li.undone p {
  background: #eee;
  color: #333;
}
.steps li.undone a:after,
.steps li.undone p:after {
  border-left: 12px solid #eee;
}
.steps li.undone p {
  color: #aaa;
}
