// ===========
//  USER LIST
// ===========


// local variables:
// ----------------

  $block: user-list;

  $user-color:  color($dokiv-green);
  $guest-color: color($dokiv-orange);

// ----------------

.user-list {
  &__column {
    @include mq(small) {
      &     { @include span(6 of 12); }
      &:last-child { @include span(4 of 12 last); }
    }

    @include mq(small, max-width) {
      & + & { margin-top: site("default-margins"); }
    }
  }

  &__type {
    display: inline-block;
    padding: gr(-1);
    color: #fff;
    border-radius: site("br");
    vertical-align: top;

    &--default { background: $user-color;  }
    &--guest   { background: $guest-color; }
    &--pending { 
      background: #f5f5f5 !important;
      color: #9e9e9e !important;
      border: 1px solid #e0e0e0 !important;
      &:before {
        color: #9e9e9e !important;
      }
    }

    &__upgrade {
      background: $guest-color;

      &:hover {
        background: $user-color;
      }

      &.ng-leave {
        transition: none;
      }
    }

    .select__item & {
      float: right;
      pointer-events: none;
      padding: gr(-2) gr(-1);

      &::before {
        color: #fff;
        font-size: .8em;
      }

      &--default::before { content: "S"; }
      &--guest::before   { content: "G"; }
    }
  }

  &__sub-header {
    font-size: 1.15em;
    margin-bottom: gr(-3);
  }

  &__filter,
  &__statistics {
    width: 100%;
    max-width: 25em;
    .mass-edit {
      @include position(absolute, null, -10em, 0, null);
    }
  }
  &__filter-form {
    @extend %flex;
    li:first-child {
      @extend %flex-grow-1;
      .filterText {
        font-size: 14px;
      }
    }
  }

  &__statistics-column {
    @include mq(small) {
      &     { @include span(5 of 12); }
      & + & { @include span(7 of 12 last); }
    }

    @include mq(small, max-width) {
      & + & { margin-top: site("default-margins"); }
    }

    h4 {
      margin-bottom: 0.3em;
      font-size: 1.2em;
    }
  }

  &__remove {
    @extend %transition;
    @include position(absolute, 0, null, null, 100%);
    padding: 8px;
    &:hover {
      color: color($dokiv-red, 500);
    }
  }

  &__input-wrap {
    margin-right: 1.5em;
  }
}
