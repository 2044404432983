// =========
//  LOADING
// =========

/*
  [1] We need a static width in order to know what offset to
      use to center the spinner. Can't use translate because
      it takes it off the pixel grid and makes it blurry.
*/

.throbber {
  $loader-length: 200px;

  @include position(fixed, 50%, null, null, 50%);
  width:   $loader-length; /* [1] */
  height:  170px;
  margin: #{-$loader-length / 2} 0 0 #{-$loader-length / 2};

  z-index: 1000;
  padding: gr(1) gr(2);
  font-size: gr(1);

  color: site("color");
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1em;
  box-shadow: site("box-shadow"), inset -1px -1px 0 rgba(#000, .2);

  &.ng-hide-add    { animation: fadeOut .2s; }
  &.ng-hide-remove { animation: fadeIn  .2s; }

  .fa {
    display: block;
    font-size: 4em;
    margin-bottom: 10px;
  }
}

.loading-bar {
  background: color($dokiv-orange);
  position: fixed;
  z-index: 999999;
  height: 3px;
  top: 0;
}
