
$toggle-width:  4em;
$toggle-height: $toggle-width * gr(1, null);

.reminder-detail {
  &__column {
    @include mq(tiny) {
      &     { @include span(8 of 12 0.5); }
      & + & { @include span(4 of 12 0.5 last); }
    }
    & .options-toggle, & .options-close {
      display: none;
    }
    @include mq(tiny, max-width) {
      & + & {
        z-index: 9999; /* [2a] */
        box-shadow: site("box-shadow");
        width: 100%;
        background: #fff;
        padding: 1em;
        @include position(fixed, 0, null, null, null);
        &.open {
          left: 0;
          height: 100%;
          animation: flyLeft transition("duration") transition("swift-in") forwards;
        }
        &:not(.open) {
          transform: translateX(100%);
          box-shadow: 0 0 0 transparent;
          right: 15px;
          width: 21em;
          animation: flyRight transition("duration") transition("swift-in") forwards;
        }
      }
      .options-toggle {
        display: block;
        border-radius: 50%;
        background: #fff;
        box-shadow: site("box-shadow");
        z-index: 100;
        width:  $toggle-width;
        height: $toggle-height;
        margin: (-#{$toggle-height / 2}) (-#{$toggle-width / 2}) 0 0;
        @include position(absolute, 50%, 100%, null, null);

        .fa {
          -webkit-transition: all .25s ease;
          -o-transition: all .25s ease;
          transition: all .25s ease;
          left: -.9em;
        }
      }
      &.open .options-toggle .fa {
        transform: scale(-1, 1);
        left: -.7em;

        @include mq(tiny, max-width) {
          left: .9em;
        }
      }

      .options-close {
        display: block;
        z-index: 1;
        padding: gr(-1);
        background: #fff;
        border-bottom-left-radius: site("br");
        @include position(absolute, 0, 0, null, null);
        @include border(1px solid site("border-color"), left bottom);
      }
    }

    .popover__wrap {
      left: initial !important;
      right: initial !important;
      bottom: initial !important;
    }
    .reminder__new-checklist {
      > button {
        width: 100%;
      }
    }
  }

  &__contact--column {
    @include mq(tiny) {
      &     { @include span(5 of 12 0.5); }
      & + & { @include span(7 of 12 0.5 last); }
    }
  }
  &__separator-vertical {
    border-left: 1px solid color($grey, 400);
    padding-left: 1.3em;
  }
  &__wrapper {
    @include mq(small) {
      .modal-dialog {
        width: 50em;
      }
    }
    &.modal-dialog {
      width: 65em;
    }
    &.select-chart .modal-content{
      background-color: #fff
    }
  }
  &__split--column {
      @include columns(2);
  }
  &__content {
    display: none;
    @include mq(tiny) {
      padding-right: 2em;
      border-right: 1px solid #cdcdcd;
    }
    &.active {
      display: block;
    }
  }
  &__content--wrapper {
    label {
      font-weight: bold;
    }
    & + & {
      margin-top: gr(0);
    }
  }
  &__description {
    border-radius: site("br");
    background: #FFF;
    padding: gr(-1);
    white-space: normal;
    margin-top: gr(-1);
    border: 1px solid site("border-color");
  }

  &__extended-description {
    min-height: 8em;
  }

  &__header {
    h2 {
      text-transform: uppercase;
    }
    @include mq(tiny) {
      padding-bottom: 2em;
    }
    .publication-edit__wrap {
      @include mq(small, max-width) {
        margin-top: gr(-1);
      }
    }
  }

  &__completed-box {
    width: 15px;
    height: 100%;
    @include position(absolute, 0, null, null, 0);
    background: color($dokiv-green);
    border-top-left-radius: site("br");
    border-bottom-left-radius: site("br");
  }

  &__body {
    padding-left: 2em;
  }

  &__save {
    margin-bottom: gr(1);
    @include mq(small) {
      z-index: 1;
      @include position(absolute, 0, 0, null, null);
    }
  }


  &__btn--row {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  &__btn--col {
    @include mq(tiny) {
      &     { @include span(4 of 12 0.5); }
      &:last-child { @include span(4 of 12 0.5 last); }
    }
    @include mq(tiny, max-width) {
      margin-top: gr(1);
    }

    &:only-child {
      float: left !important;
    }
    .button {
      width: 100%;
    }
    .notice-active {
      background-color: color($grey, 300);
    }
  }
  &__btn--fullwidth {
    @include mq(tiny, max-width) {
      float: none;
      width: 100%;
    }
  }

  &__close {
    span.text-close {
      font-size: 3em;
      opacity: 0.7;
      cursor: pointer;
      &:hover,
      &:active {
        opacity: 1;
      }
    }
    text-align: right;
    @include position(absolute, 0, 0, null, null);
    padding: gr(0);
    padding-top: 0;
    z-index: 1000;
    .button {
      margin-right: 2em;
      top: -7px;
    }
    .btn-complete {
      margin-right: 4em;
    }
  }
  &__add--label {
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  &__completed--info {
    @include position(absolute, null, null, 0.5em, 0);
  }

  &__btn--complete {
    color: #fff;
    background:   color($dokiv-blue);
    border-color: color($dokiv-blue);

    &:hover {
      background:   lighten(color($dokiv-blue), $button-color--saturation);
      border-color: lighten(color($dokiv-blue), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(color($dokiv-blue), $button-color--saturation);
      border-color: darken(color($dokiv-blue), $button-color--saturation);
    }
  }

  &__btn--negative {
    color: #fff;
    background:   color($dokiv-orange);
    border-color: color($dokiv-orange);

    &:hover {
      background:   lighten(color($dokiv-orange), $button-color--saturation);
      border-color: lighten(color($dokiv-orange), $button-color--saturation);
    }

    &:active {
      color: rgba(#fff, .5);
      background:   darken(color($dokiv-orange), $button-color--saturation);
      border-color: darken(color($dokiv-orange), $button-color--saturation);
    }
  }

  &__connection {
    .select-publication {
      width: 100%;
    }
    .mw-typeahead__input {
      border-radius: 0 0 site('br') site('br');
    }
  }
  
  &__notice {
    margin: 0.4em 0;
    .set-parent__body{
      padding: .138198em 0 .138198em 2.61792em;
    }
  }

  &__checkbox {
    .checkbox__wrap {
      display: inline-block;
      margin: 0;
      & + .checkbox__wrap {
        margin-left: 2em;
      }
    }
  }

  &__due-date {
    background: #e0e0e0;
    padding: gr(-1);
    border-radius: site("br");
    margin-right: 2em;
    float: left;
    @include mq(tiny, max-width) {
      margin-bottom: gr(0);
    }
  }
  &__bottom {
    .fa-check {
      color: color($dokiv-green);
    }
  }

  &__sub {
    @include position(absolute, 0, null, null, 0);
    width: 4em;
  }

  &__has-sub {
    margin-left: 2.2em;
  }

  &__confirm-change {
    @include position(absolute, 100%, null, null, null);
    border: 1px solid site('border-color');
    background: #FFF;
    padding: gr(0);
    margin-top: gr(-1);
    border-radius: site("br");
    z-index: z("low");
    p {
      font-size: 1.2em;
    }
  }

  &__menu {
    button {
      width: 100%;
    }
    li {
      margin-bottom: gr(-1);
    }
  }

  &__remove-manager {
    @include position(absolute, 0.4em, 0.7em, 0, null);
    z-index: z("low");
  }

  &__task-number {
    font-size: 0.8em;
    @include mq(small, max-width) {
      display: block
    }
    span {
      padding-left: 1em;
      text-transform: none;
    }
  }

  &__buttons {
    .button {
      margin: 0;
      padding-left: gr(-2);
      padding-right: gr(-2);
      font-size: 0.9em;
    }
  }

  &__select-chart {
    padding: 0 2em;
    .button {
      display: block;
      width: 100%;
      margin-bottom: 0.5em;
      margin-left: 0;
    }
  }

  &__box-select-chart {
    background: #fff;
  }

  &__template-icon {
    width: 209.55px;
    height: 209.55px;
    border: 1px solid #d2d2d2;
    background: color($grey, 100);
    border-radius: 3px;
    .card__column:nth-child(4) &, .card__column:nth-child(5) &, .card__column:nth-child(6) & {
      margin-top: 3em;
    }
    .fa {
      top: 20%;
      font-size: 8em;
      color: #6c6969;
      &.fa-gdpr {
        top: 35%;
        font-size: 4em;
      }
    }
  }

  &__preview-file {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__reset {
    cursor: pointer;
    padding: .5em gr(-1);
    @include position(absolute, 0, 0, 0, null);
    z-index: 1;

    &:hover {
      color: site("text-color-dark");
    }
  }

  &__edit-btn {
    @include mq(tiny, max-width) {
      display: flex;
      justify-content: space-between;
      .button {
        width: 50%;
        text-align: center;
      }
    }
  }

  &__reminder-dates {
    @include mq(small, min-width) {
      display: flex;
    }
    justify-content: space-between;
    input {
      width: 65%;
      background: #d8d8d8;
      border-radius: 0;
    }
    button { 
      border-radius : 0; 
      .fa {
        margin-right: 0;
      } 
    }
    > div {
      width: 42.5%;
      &:last-child {
        width: 10%;
      }
      @include mq(small, max-width) {
        @include span(5 of 12 .25);
        &:last-child {
          @include span(2 of 12 .25 last);
        }
        button {
          .fa {
            margin-right: 0;
          }
        }
      }
      input, button {
        width: 100%;
      }
    }
    
  }

  &__reminder-dates-list {
    font-size: 1.1em;
    ul li span {
      position: absolute;
      right: 0;
      top: 0;

    }
  }

  &__comments-wrap {
    margin-top: 0.5em;
    .comment__list {
      margin-top: 0.7em;
    }
  }

  &__comment {
    border: 0;
    box-shadow: none;
    &.comment__wrap {
      padding: 0 0 0.7em 2.6em;
      min-height: inherit;
    }
    &.comment__wrap, & .comment__meta {
      overflow: inherit;
    }
    .comment__time {
      font-size: .8em;
      color: #9e9e9e;
      @include position(absolute, 0.8em, 1em, null, null);
    }
    .comment__meta {
      padding: 0;
      @include position(inherit, null, null, null, null);
      width: inherit;
      margin-right: 2em;
      p {
        margin-bottom: 0.3em;
      }
      .user {
        font-size: 12px;
        color: color($grey, 600);
      }
      margin-bottom: 0.3em;
      @include mq(small, max-width) {
        margin-bottom: 0.3em;
      }
    }
    .comment__body {
      .comment {
        color: #000;
        font-size: 14px;
        line-height: 1.45;
      }
    }
    .comment__avatar {
      @include position(absolute, 0, null, null, 0);
      .user-avatar {
        margin-top: gr(-1);
      }
    }
  }

  &__comment-wrap-body {
    float: left;
    background: #eee;
    padding: gr(-1);
    border-radius: 6px;
    width: 100%;
  }

  &__activity {
    background-color: #fff;
    padding: 1em;
    margin: -1em;
    margin-top: 0;
  }

  &__print-hide {
    .checklist__delete {
      display: none;
    }
  }

  &__comment-box {
    border: 1px solid color($grey, 300);
    border-radius: site("br");
    background: #fff;
    padding: 1em;
  }

  &__add-comment-child {
    margin-left: 2.6em;
    padding: 0;
    padding-left: 0.5em;
    border-left: 2px solid color($grey, 200);
    &:not(.comment) {
      margin-bottom: 2.5em;
      .comment__wrap.reminder-detail__comment {
        padding-bottom: 0;
      }
    }
  }
}
