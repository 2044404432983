// ==============
//  VERIFICATION
// ==============

/*
  [*] These are styles for the verification section on forms
*/


// local variables:
// ----------------

  $block: verification;

// ----------------

.#{$block} {
  &__column {
    margin-top: site("default-margins");

    @include mq(tiny) {
      @include columns(2, .4);
    }
  }

  textarea {
    padding-top:    .275em;
    padding-bottom: .275em;
    min-height: 0;
  }
}
