// ================
//  TABLE POPOVERS
// ================

.table-popover {
  &__wrap {
    background: #fff;
    padding: 1em;
    border-radius: site("br");
    box-shadow: site("box-shadow");
    text-align: left;
    font-size: site("font-size");
    width: 25em;
    p {
      margin: 0;
      white-space: normal;
    }
  }

  &__header {
    font-size: 1.2em;
  }

  &__label {
    font-weight: body("bold");
    line-height: site("line-height");
  }

  &__list {
    margin: site("default-margins") 0;

    li + li {
      margin-top: site("default-margins");
    }
  }

  &__table {
    line-height: site("line-height");
    border: none !important;
    td, th {
      vertical-align: top;
      padding: 0;
      border: none;
    }

    td {
      width: 100%;
      padding-left: gr(-1);
    }
  }

  &__table-publication {
    td, th {
      width: inherit;
    }
  }
}
