// =================
//  BROWSER-WARNING
// =================

/*
  [*] Styles for the warning banner displayed to users with old
      browsers.
*/


// local variables:
// ----------------

  $block: browser-warning;

  $bar-padding: gr(-1);
  $bar-color  : color($dokiv-green);

// ----------------


.#{$block} {
  color: #fff;
  background: $bar-color;
  padding: $bar-padding gr(2);
  text-align: center;
  z-index: z("high");
  @include position(fixed, null, 0, 0);

  @include mq(tiny, max-width) {
    font-size: .8em;
  }

  p {
    margin: 0 auto;
    max-width: 50em;
  }

  a {
    border-color: #fff;
    opacity: gr(-1, null);

    &:hover {
      border-color: #fff;
      opacity: 1;
    }
  }

  &__close {
    padding: $bar-padding 1em;
    @include position(absolute, 0, 0, 0, null);

    &:hover {
      background: lighten($bar-color, 5%);
    }

    &:active {
      background: darken($bar-color, 5%);
    }

    .fa {
      vertical-align: top;
    }
  }
}
