$block: user-role;
.#{$block} {
  &__edit {
    .dropdown__toggle {
      padding: gr(-1);
      &:hover,
      &:active {
        background: site("color");
        .fa {
          color: #FFF;
        }
      }
    }
  }
  &__tab {
    @include mq(small, max-width) {
      .button {
        width: 46%;
        margin: 1%;
      }
    }
    
  }
  &__wrap {
    @extend %flex;
    flex-flow: row wrap;
    margin-left: -(gr(-1));
    margin-right: -(gr(-1));
    margin-top: 0;
    table {
      .select__item {
        padding: 0;
        border: none;
        .user-list__type {
          float: left;
        }
      }
    }
    & > li {
      list-style: none;
    }
  }
  &__column {
    padding: gr(-1);
    padding-top: 0;
    min-height: 30px;
    @include mq(small, min-width) {
      .templates-four__column-right &, .templates-three__column & {
        flex: 0 0 66.666%;
        max-width: 666.666%;
        &.not-full-box {
          flex: 0 0 33.333%;
          max-width: 33.333%;
        }
      }
      .have-message &, & {
        flex: 0 0 100%;
        max-width: 100%;
        &.not-full-box {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    @include mq(small, to, medium) {
      &.not-full-box {
        .templates-four__column-right.have-message &, .templates-three__column.have-message & {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    flex: 0 0 100%;
    max-width: 100%;
  }

  &__column-small {
    @include mq(small, max-width) {
      & + & {
        @include separator();
      }
    }

    @include mq(small) {
      @include columns(2, .6);
    }
    &.check-column {
      padding-top: 0.6em;
    }
    .select__wrap + .select__wrap {
      margin-top: 10px;
    }
  }

  &__content {
    padding: 1em;
    @extend %skin;
    height: 100%;
    textarea {
      height: 12em;
    }
    &:before {
      content: '';
      width: 100%;
      border-top: 1px solid color($grey, 300);
      @include position(absolute, 2.8em, null, null, 0);
    }
    & > ul > li > h3 {
      margin-bottom: 1.5em;
    }
  }

  &__link {
    margin-bottom: 1em;
    &--notice {
      font-size: 0.95em;
      margin-bottom: gr(-2);
    }
  }

  &__bulk-remove {
    @include position(absolute, 0, 0, null, null);
    padding: gr(-1);
    opacity: 0;
    z-index: z("mid");

    .#{$block}__link:hover &, .role-content & {
      opacity: 1;
    }
  }

  &__group-edit {
    background: #f00000;
    color: #fff;
    padding: 0.2em 0.3em;
    border-radius: 3px;
    .role-content & {
      @include position(absolute, 4px, 10px, null, null);
      font-size: 1.6em;
    }
    .role-content & {
      opacity: 1;
    }
  }

  &__edit-btn {
    float: right;
    @include mq(medium) {
      &.edit-break {
        .templates-four__column-right.have-message .not-full-box &, .templates-three__column .not-full-box & {
          margin-top: 0.8em;
        }
      }
    }
  }

  &__box-info {
    border-radius: site("br");
    max-width: 100%;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 60em;
    word-break: break-word;
    padding: 2em 0.5em;
    white-space: pre-wrap;
  }

  &__rm-img {
    @include mq(small, max-width) {
      .fa {
        margin: 0;
      }
    }
  }
  &__table {
    @include mq(small) {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                > span {
                  background: site("border-color");
                  height: 100%;
                  width: 1px;
                  @include position(absolute, 0, null, 0, 2em);
                }
              }
            }
            &:not(:first-child) {
              td {
                &:first-child {
                  padding-left: 4em;
                  &:after {
                    background: site("border-color");
                    content: "";
                    height: 1px;
                    width: 20px;
                    @include position(absolute, 50%, null, null, 2em);
                  }
                }
              }
            }
            &:first-child {
              td {
                &:first-child {
                  padding: 2em 1em;
                  overflow: hidden;
                  > span {
                    top: 70%;
                  }
                }
              }
            }
            &:last-child {
              td {
                > span {
                  top: -50%;
                }
              }
            }
          }
        }
      }
    }
  }
}