// =======
//  PRINT
// =======

/*
  [*] In this document we collect all print related styles.

  [1] To improve readability, cap the row length.

  [2] In Chrome, the left side of the checkbox is cut off
*/


// local variables:
// ----------------

  $print-font-size: 12px;

// ----------------

@media print {
  body {
    //max-width: 15cm; /* [1] */
    padding: 1cm;
    font-size: $print-font-size;
  }

  .radio__label,
  .checkbox__label {
    &::before,
    &::after {
      display: none !important;
      content: "";
    }
  }

  .radio__wrap,
  .checkbox__wrap {
    overflow: visible;
  }

  .radio__input,
  .checkbox__input {
    position: static;
    margin: 0 0 0 2px; /* [2] */
  }

  .ng-hide,
  .print-hide {
    display: none !important;
  }

  .answer__signature,
  .question__item {
    border: 0;
    margin: 1em 0;
    padding-left: 1em;
    padding-top: 0;
    padding-bottom: 0;
    border-left: gr(-3) solid site("color");
  }

  .question {
    &__wrap {
      border-top: 1px solid site("border-color");
      padding-top: gr(1);
    }
    &__column {

      &--half {
        width: 50%;
      }

      &--third {
        width: 33.333%;
      }

      &--fourth {
        width: 25%;
      }

      + .question__item:not(.question__column) {
        clear: left;
      }
    }
  }

  .answer__heading {
    font-size: 2em;
  }

  .checklist {
    border: 0;
    padding: 0;
    box-shadow: none;

    .checkbox__input {
      float: left;
      margin-right: 1em;

      &:checked {
        background: color($grey, 500);
      }
    }

    .checkbox__label {
      max-width: 90%;
      overflow: hidden;
    }
  }

  th,
  td {
    font-size: $print-font-size;
    padding: gr(-3) gr(-1) gr(-3) 0;
  }

  .markdown {
    &__wrap {
      margin-top: gr(2);

      blockquote {
        background: site("bg-color");
      }
    }
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}
