$block: company-order;

%icon-hover {
  &:hover,
  &:active {
    .fa {
      opacity: 1;
    }
  }
  .fa {
    opacity: .5;
  }
}
.#{$block} {
  &__wrapper {
    .mw-typeahead {
      width: 100%;
    }
  }

  &__col {
    @include mq(small) {
      & {
        @include span(9.7 of 12 .25);
      }
      & + & {
        @include span(2.3 of 12 .25 last);
      }
    }
  }

  &__column {
    @include mq(small, max-width) {
      & + & {
        @include separator();
      }
    }

    @include mq(small) {
      &:nth-child(1) {
        @include span(3 of 12 .25);
      }
      &:nth-child(2) {
        @include span(6 of 12 .25);
      }
      &:nth-child(3) {
        @include span(3 of 12 .25 last);
      }
    }
  }

  &__subcription-column {
    @include mq(tiny, max-width) {
      & + & {
        @include separator();
      }
    }
    @include mq(small) {
      & {
        @include span(6 of 12 .25);
      }
      & + & {
        @include span(6 of 12 .25 last);
      }
    }
  }

  &__submit-column {
    @include mq(small, max-width) {
      & + & {
        margin-top: 1em;
      }
    }
    @include mq(small) {
      & {
        @include span(4 of 12 .25);
      }
      & + & {
        @include span(8 of 12 .25 last);
      }
    }
  }

  &__info--column {
    @include mq(small, max-width) {
      & + & {
        margin-top: 1em;
      }
    }
    @include mq(small) {
      & {
        @include span(6 of 12 .25);
      }
      & + & {
        @include span(6 of 12 .25 last);
      }
    }

    @media print {
      & {
        @include span(6 of 12 .25);
      }
      & + & {
        @include span(6 of 12 .25 last);
      }
      & + & {
        margin-top: 0;
      }
    }
  }

  &__object-column {
    @include columns(3);
  }
  &__cancelled {
    color: #fff;
    background: color($dokiv-red);
    border-color: color($dokiv-red);
    padding: 10px;
    border-radius: site("br");
    text-align: center;
  }

  &__header {
    margin-bottom: gr(-2);
    button {
      @extend %icon-hover;
    }
  }
  &__product-filter {
    margin-bottom: gr(0);
    @include mq(tiny) {
      h4 {
        float: left;
        margin-top: 1.2em;
      }
      .select__wrap {
        float: right;
        max-width: 25em;
      }
    }
    .select__wrap {
      width: 100%;
    }
  }

  &__customer-info {
    @extend %skin;
    padding: 1em;
    background: #fff;
    button {
      padding: gr(-1);
      @include position(absolute, 0, 0, null, null);
      @extend %icon-hover;
    }
    .goto-customer-icon {
      margin-left: 1em;
    }
  }

  &__amount {
    .amount-input {
      margin-right: 8em;
    }
    .buttons {
      @include position(absolute, 0, 0, null, null);
      .fa {
        margin: 0;
      }
    }
  }

  &__modal {
    &.modal-dialog {
      @include mq(tiny) {
        width: 30em;
      }
      .modal-content {
        background: #fff;
      }

      .separator {
        border: none;
      }
    }
  }

  &__info {
    background: #fff;
    &.has-selectbox {
      padding-top: 5px;
      padding-bottom: 5px;
      min-height: 70px;
    }
    .line-component--omega {
      span + span {
        margin-left: 2em;
      }
    }
    @include mq(tiny, max-width) {
      > div {
        float: none;
      }
    }
  }
  &__edit-submitted-date {
    @include position(absolute, 1em, gr(-1), null, null);
    button {
      opacity: 0.7;
      &:hover,
      &:active {
        opacity: 1;
      }
    }
  }

  &__date-picker {
    margin-top: gr(-1);
  }

  &__submit {
    span {
      margin-right: 2em;
    }
  }
  &__product-img {
    width: 100px;
    @include mq(small) {
      width: 4em;
    }
  }

  &__confirm-modal {
    h2 {
      margin-top: 2em;
      margin-bottom: 2em;
      text-align: center;
    }
    .buttons {
      margin-bottom: 2em;
      button {
        span {
          font-size: 1.5em;
          text-transform: uppercase;
        }
      }
    }
  }

  &__next-step {
    @include position(absolute, 0, 0, 0, 0);
    width: calc(100% - 2.1em);
    border-radius: site("br") 0 0 site("br");
    .button + & {
      margin: 0;
      word-break: break-word;
    }
  }

  &__addresses {
    li {
      span {
        padding-right: 1.5em;
        display: inline-block;
      }
      > button {
        opacity: 0.6;
        @include position(absolute, 0, 0, null, null);
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__new-address {
    @include position(absolute, 0, 0, null, null);
    margin-top: 0;
  }

  &__dropdown {
    white-space: nowrap;
    margin-right: 3.2em;
  }

  &__map {
    @include position(absolute, 0, null, 0, 3px);
    img {
      width: 25px;
      margin-top: 3px;
      opacity: 0.7;
    }
  }

  &__list-orders {
    font-size: .85em;
    &.order-rows {
      td {
        padding: .3em .7em;
      }
      .actions__button {
        padding: .5em .8em;
      }
    }
  }

  &__main {
    @include mq(large) {
      @include span(9.6);

      + aside {
        /* [3] */
        @include span(2.4 last);
      }
    }
  }

  &__submit-buttons {
    @include mq(tiny) {
      display: flex;
      align-items: center;
    }

    i.fa {
      margin: 0 10px;
      color: #e0e0e0;
    }
  }
}
#PlacesAutocomplete__root {
  .form-control {
    padding-left: 30px;
  }
}
#PlacesAutocomplete__autocomplete-container {
  z-index: z("high");
}
