// ===========
//  COPYRIGHT
// ===========

.copyright {
  &__wrap {
    text-align: center;
    z-index: -1;
    @include position(absolute, null, 0, 0, 0);

    a {
      display: block;
      padding: gr(1);
    }

    img {
      max-width: 100px;
    }
  }

  &__text {
    margin-top: site("default-margins");

    p {
      font-size: .9em;
      color: site("text-color-dark");
    }
  }
}
