// ===================
//  PUBLICATION ALERT
// ===================


// local variables:
// ----------------

  $block: alert-bar;

// ----------------


.#{$block} {
  &__wrap {
    @extend %transition--paint;
    padding: 1em;
    border-radius: site("br");
    background: #fff;
    box-shadow: 0 1px 2px rgba(#000, .3);
    overflow: hidden;

    &.ng-hide {
      transition: none;
    }

    @include mq(small, max-width) {
      font-size: .8em;
    }

    .fa {
      margin: gr(-4) gr(-1) 0 0;
      float: left;

      .touch & {
        margin-top: gr(-3);
      }
    }

    span {
      display: block;
      overflow: hidden;
      line-height: 1.2;
    }
  }

  &--negative {
    background: color($dokiv-red);
    color: #fff;
  }

  &--system {
    background: site("color");
    color: #fff;
  }

  &--positive {
    background: color($dokiv-green);
    color: #fff;
  }
}
