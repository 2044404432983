// ===============
//  IMAGE GALLERY
// ===============

/*
  [*] This document contains reusable styles for image galleries.
      Styles specific per type of image gallery can be found in
      other documents, such as versions.scss or albums.scss.
*/


// local variables:
// ----------------

  $block:           image-gallery;
  $gallery-columns: 3;

// ----------------


.#{$block} {
  &__wrap {
    @extend %vertical-margins;
  }

  &__holder {
    text-align: center;
    overflow: hidden;
    background: #fff;
    margin: 0;

    @include mq(tiny, max-width) {
      ~ .#{$block}__holder {
        margin-top: site("default-margins");
      }
    }

    @include mq(tiny, to, medium) {
      @include columns($gallery-columns - 1, .25);

      &:nth-child(#{$gallery-columns - 1}) {
        ~ .#{$block}__holder {
          margin-top: site("default-margins");
        }
      }
    }

    @include mq(medium) {
      @include columns($gallery-columns, .25);

      &:nth-child(#{$gallery-columns}) {
        ~ .#{$block}__holder {
          margin-top: site("default-margins");
        }
      }
    }

    a {
      display: block;
    }
  }

  &__thumbnail {
    @extend %transition--paint;

    .#{$block}__holder--hidden & {
      opacity: .333;
    }

    img {
      height: 15em;
      object-fit: contain;
      width: 100%;
    }
  }

  &__info {
    text-align: center;
    padding: site("default-margins");
    background: rgba(#fff, gr(-1, null));

    p {
      @extend %ellipsis;
      margin: 0;
    }
  }

  &__file-name {
    @extend %ellipsis;
    margin: 0;
    line-height: site("line-height");
    font-weight: header("bold");
  }

  &__overlay {
    @include position(absolute, null, 0, 0);

    &-item {
      @extend %swift-in;

      color: #fff;
      background: rgba(#000, .75);
      border-radius: site("br") site("br") 0 0;
      border: 1px solid rgba(#000, .75);
      padding: gr(-1);

      margin: 0 auto;

      &:hover {
        background: rgba(#000, 1)
      }

      .#{$block}__holder:not(:hover) & {
        opacity: 0;
        transform: translateY(100%);
      }

      .fa {
        font-size: 1.1em;
        vertical-align: top;
        top: .25em;
      }
    }

    &-filename {
      color: #fff;
      background: rgba(#000, .75);
      height: 3em;
      padding-top: 1em;
    }

    .show-details & {
      display: none;
    }
  }

  &__toggle {
    z-index: 1;
    margin-bottom: gr(-1);
    float: right;
  }

  &__placeholder {
    @include position(absolute, 0);
    background: color($grey, 50);
    background: color($dokiv-green);
    color: #fff;
    height: 100%;
    border-radius: site("br");

    p {
      @include position(absolute, 50%, 0, null);
      transform: translateY(-50%);
      font-size: 1.2em;
      font-weight: body("bold");
      padding: gr(-1);
    }
  }

  &__buttons-overlay {
    @include position(absolute, gr(-1), gr(-1), null, null);
    button {
      box-shadow: 0 1px 1px rgba(#000, .4);
      border-top: 1px solid rgba(#fff, .2);
    }
  }

  &__download-item {
    padding: gr(-1);
    border: 0;
    box-shadow: 0 1px 2px rgba(#000, .3);
    @include position(absolute, gr(-1), null, null, gr(-1));
  }

  &__icon-fa-do {
    .fa {
      transform: scale(-1, 1);
    }
  }
}

.mime-type {
  font-size: .8em;
  text-align: right;
}
