// ======================
//  FORM ANSWERS DETAILS
// ======================


// local variables:
// ----------------

  $block: answer-details;

// ----------------


.#{$block} {
  &__wrap {
    padding: gr(1);
    max-width: 100%;
    width: 50em;
    background: color($grey, 200);
  }

  &__label {
    margin: 0;
    font-weight: body("thin");
  }

  &__header {
    font-size: 1.4em;

    @include mq(tiny) {
      font-size: gr(1);
    }
  }

  &__load-more {
    list-style: none;
    text-align: center;
  }

  &__list {
    padding: 1em;
    background: #fff;
    box-shadow: 0 1px 2px rgba(#000, .3);
    border-radius: site("br");
    max-height: 400px;
    overflow-y: auto;

    @include mq(tiny, max-width) {
      @include stretch(1em);
    }
  }
}
