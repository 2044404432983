// =============
//  ACTIVE TAGS
// =============


// local variables:
// ----------------

  $tag-gutter:         gr(-2);
  $tag-padding:        gr(-1);
  $tag-padding--small: gr(-2);
  $tag-color:          site("color");
  $tag-max-width:      23em;
  $tag-scew:           .05em;
  $publication-filter--width: 12em;

// ----------------


.active-tags {
  &__wrap {
    margin-top: site("default-margins");
    padding-bottom: 1em;

    @include mq(small) {
      margin: -#{$tag-gutter} 0 -1em;
      padding-right: $publication-filter--width;
      &.active-tags__wrap--nopadded {
        padding-right: 0;
      }
    }
  }

  &__tag {
    display: block;
    float: right;
    border-radius: site("br");
    background: site("color");
    border: 1px solid transparent;
    color: #fff;
    max-width: $tag-max-width;
    margin: $tag-gutter $tag-gutter 0 0;

    @include mq(small, max-width) {
      float: left;
      font-size: .9em;
    }

    &:active {
      user-select: none;
    }

    button {
      padding: $tag-padding--small;
      @include position(absolute, 0, 0, 0, null);

      @include mq(small) {
        padding: $tag-padding;
      }
    }

    span {
      @extend %ellipsis;
      padding: ($tag-padding--small - $tag-scew) gr(1) ($tag-padding--small + $tag-scew) $tag-padding--small;

      @include mq(small) {
        padding: ($tag-padding - $tag-scew) gr(1) ($tag-padding + $tag-scew) $tag-padding;
      }
    }
  }

  &__category {
    background: color($grey, 50);
    color: inherit;
    border-color: site("border-color");
  }

  &__type {
    background: color($dokiv-green);
  }

  &__group {
    background: color($dokiv-orange);
  }

  &__priority {
    background: color($dokiv-blue);
  }

  &__date {
    background: color($dokiv-red);
  }
}
