// ==========
//  HEADINGS
// ==========

%heading {
  font: header("regular") 1em/1 header("family"), header("fallback");
  margin: 0;
}

@include headings(6) {
  @extend %heading;
}

h1 { font-size: 2em;   font-weight: header("thin"); }
h2 { font-size: 2em;   font-weight: header("thin"); }
h3 { font-size: 1.3em; font-weight: header("bold"); }
h4 { font-weight: header("bold"); }
h5 { font-weight: header("bold"); line-height: site("line-height"); }

.heading {
  &__label {
    display: block;
    margin-bottom: gr(-2);
  }
}
