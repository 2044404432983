// =======
//  THEME
// =======

%skin {
  border-radius: site("br");
  background:    site("bg-color");
  border:        1px solid site("border-color");
  // box-shadow:    0 1px 0 rgba(site("border-color"), .5);
}

.skin {
  @extend %skin;
  padding: site("default-margins");
}
