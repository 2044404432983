// =======
//  EMBED
// =======

.embed {
  &__wrap {
    h4 {
      line-height: site("line-height");
    }

    h5 {
      font-weight: header("regular");
      margin-bottom: gr(-4);
    }

    .radio__item {
      float: left;
      margin: 0 gr(-1) gr(-1) 0;
    }

    input {
      background: color($grey, 50);
      width: auto;
      margin-right: gr(-3);
    }
  }

  &__block {
    background: #fff;
    padding: 1em;
    border-radius: site("br");
    border: 1px solid site("border-color");
  }

  &__code {
    @extend %momentum-scroll;
    display: block;
    white-space: pre;
    line-height: 1.2;
  }

  &__column {
    @include mq(tiny) {
      & { @include span(5 of 12 1); }
      & + & { @include span(7 of 12 1 last); }
    }
    @include mq(tiny, max-width) {
      & + & { margin-top: gr(1) };
    }
  }
}
