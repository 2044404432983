/*
  [*] These are styles for the submitter-copy input box for the form.
*/

// local variables:
// ----------------

  $block: submitter-copy;

// ----------------

.#{$block} {
  margin: 0 0 1em 0;
  
  &__wrap {
    overflow: hidden;
  }
  
  &__checkbox {
    float:       left;
    padding-top: gr(-1);
  }
  
  input[type="text"] {
    float:       left;
    margin-left: 1em;
    max-width:   20em;
  }
}
