// ======
//  BODY
// ======

%text-block {
  @extend %vertical-margins;
  line-height: site("line-height");
  word-wrap: break-word;
}

%body-anchor {
  a {
    border-bottom: 1px solid lighten(site("text-color"), 50%);

    &:focus,
    &:hover {
      border-color: site("color");
    }
  }
}

p {
  @extend %text-block;
  @extend %body-anchor;
}

.text-block {
  @extend %text-block;
}

.list {
  line-height: site("line-height");

  &__square {
    list-style: inside square;
  }
}
