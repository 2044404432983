// ===============
//  CUSTOM SELECT
// ===============

/*
  [*] Styles for mimicing a regular select box.

  [1] Some browsers mess up the positioning of the right floated
      element when a non-floating sibling changes, even if they
      have a display value of inline.

  [2] When the dropdowns are absolutely positioned, make sure
      they lie above other elements with a local z-index (it's
      usually z-index: 1, but I think it makes sense to use the
      lowest system z-index).
*/


// local variables:
// ----------------

  $block: select;

  $item-padding: .8em;
  $item-height:  $item-padding * 2 + 1em;
  $item-visible: 8;

  $item-color--hover:  color($grey, 200);
  $item-color--active: site("color");

  $item-image--width:   25px;
  $item-image--padding: $item-padding * gr(-1, null);

// ----------------


.#{$block} {
  &__button {
    display: block;
    width: 100%;
    min-width: 12em;
    padding: gr(-1);
    text-align: left;
    overflow: hidden;

    .skin & {
      background: #fff;
    }

    > span {
      float: left; /* [1] */
    }

    > .fa {
      float: right;
      margin: 0 0 0 gr(-2);
    }

    .open > & {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      border-color: site("border-color-dark");
      background: color($grey, 200);
    }
  }

  &__list {
    @extend %momentum-scroll;
    max-height: $item-visible * $item-height;

    border-bottom: 1px solid site("border-color-dark");
    border-radius: 0 0 site("br") site("br");

    z-index: z("low"); /* [2] */

    @include shadow-covers(#fff, .2, .75em);

    html.no-touch & {
      box-shadow: 0 2px 12px rgba(#000, .5);
      @include position(absolute, 100%, 0, null, 0);
    }

    &.dashboard-settings {
      padding: gr(1);
      color: color($grey, 800);
      li:hover {
        text-decoration: underline;
      }
    }

    .user-avatar {
      width: 25px;
      float: left;

      + .#{$block}__text {
        top: .3em;
      }
    }
  }

  &__item {
    @extend %transition--paint;

    display: block;
    padding: $item-padding;
    cursor: pointer;
    overflow: hidden;
    margin: 0;
    @include border(1px solid site("border-color-dark"), left right);

    &.ng-enter { display: block; }
    &.ng-leave { display: none; }

    &:hover {
      background: $item-color--hover;
    }

    &:focus,
    &.active {
      outline: 0;
      background:   $item-color--active;
      border-color: $item-color--active;
      color: #fff;
    }

    &--image {
      padding: $item-image--padding;

      img {
        max-width: $item-image--width;
        float: left;
      }

      .#{$block}__text {
        float: left;
        padding: .1em $item-image--padding;
      }
    }
  }

  &__placeholder {
    font-style: italic;
  }
}
